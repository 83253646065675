import React, { FC } from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import './assets/styles/AntOverride.less';
import './assets/styles/App.less';
import './assets/styles/Typography.less';
import './assets/styles/Form.less';

import { getUser } from './store/reducers/auth';
import { MainReducerState } from './store/reducers';
import { UserDetails } from './store/api/apiTypes';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import NotFound from './pages/notFound';
import Unauthorized from './pages/unauthorized';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Users from './pages/users/Users';
import Customers from './pages/customers/Customers';
import { Sectors, Markets, SubSectors, Solutions, GammesAndServices } from './pages/sectors';
import Products from './pages/products/Products';
import Arguments from './pages/arguments/Arguments';
import { Properties } from './pages/properties';
import { Countries } from './pages/countries';
import { AssociatedProductGroups } from './pages/associatedProductGroups';
import Admins from './pages/admins/Admins';
import { getRawRoute, RoutePathName } from './routes';
import { isUserAllowed } from './utils';
import { Themes } from './pages/themes';
import { Chapters, ChapterDetails } from './pages/chapters';
import { News } from './pages/news';
import { Terms } from './pages/terms';
import ForgottenPassword from './pages/forgottenPassword';
import { NaceDigitalBook } from './pages/naceDigitalBook';

interface AppProps {
    currentUser?: UserDetails;
}

const App: FC<AppProps> = ({ currentUser }) => (
    <Switch>
        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
        <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
            <MainLayout>
                <Switch>
                    <Route
                        exact
                        path={getRawRoute(RoutePathName.unauthorized)}
                    >
                        <Unauthorized />
                    </Route>

                    <Route
                        exact
                        path={getRawRoute(RoutePathName.notFound)}
                    >
                        <NotFound />
                    </Route>

                    <Route
                        exact
                        path={getRawRoute(RoutePathName.dashboard)}
                    >
                        <Dashboard />
                    </Route>

                    {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.admins)}
                            component={Admins}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'users' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.users)}
                            component={Users}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'customers' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.customers)}
                            component={Customers}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.markets)}
                            component={Markets}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.sectors)}
                            component={Sectors}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.subSectors)}
                            component={SubSectors}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'sectors' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.solutions)}
                            component={Solutions}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, permission: 'sectors' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.gammesAndServices)}
                            component={GammesAndServices}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, permission: 'sectors' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.arguments)}
                            component={Arguments}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, permission: 'sectors' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.properties)}
                            component={Properties}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'products' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.products)}
                            component={Products}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, permission: 'products' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.associatedProductGroups)}
                            component={AssociatedProductGroups}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'presentations' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.themes)}
                            component={Themes}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, permission: 'presentations' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.chapter)}
                            component={ChapterDetails}
                        />
                    )}
                    {isUserAllowed({ user: currentUser, permission: 'presentations' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.chapters)}
                            component={Chapters}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.countries)}
                            component={Countries}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'news' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.news)}
                            component={News}
                        />
                    )}

                    {isUserAllowed({ user: currentUser, permission: 'terms' }) && (
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.terms)}
                            component={Terms}
                        />
                    )}

                    <Route
                        exact
                        path={getRawRoute(RoutePathName.naceDigitalBook)}
                        component={NaceDigitalBook}
                    />

                    <Route>
                        <Redirect to={getRawRoute(RoutePathName.notFound)} />
                    </Route>
                </Switch>
            </MainLayout>
        </ProtectedRoute>
    </Switch>
);

const mapStateToProps = (state: MainReducerState) => ({
    currentUser: getUser(state),
});

export default connect(
    mapStateToProps,
)(App);
