import * as reduxTypes from '../types/dashboard';
import { DashboardStats } from '../api/apiTypes';
import { MainReducerState, RequestState } from '.';
import { requestReducer } from './_generics';

export type DashboardState = RequestState<DashboardStats>;

const initialState: DashboardStats = {
    userCount: 0,
    customerCount: 0,
    productCount: 0,
};

export default requestReducer<DashboardStats>({
    START: reduxTypes.FETCH,
    SUCCESS: reduxTypes.FETCH_SUCCESS,
    FAILED: reduxTypes.FETCH_FAILED,
}, initialState);

export const getStatsState = (state: MainReducerState) => state.dashboard.data;
