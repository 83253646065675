import { call, delay, takeLatest, put } from 'redux-saga/effects';
import { Action } from 'redux';

import * as actions from '../actions/customers';
import * as reduxTypes from '../types/customers';
import * as api from '../api/customers';
import { SearchPaginationQuery } from '../api';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export default function* customersSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
