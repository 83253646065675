export const LIST = 'countries/LIST';
export const LIST_SUCCESS = 'countries/LIST_SUCCESS';
export const LIST_FAILED = 'countries/LIST_FAILED';

export const LIST_SELECT = 'countries/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'countries/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAILED = 'countries/LIST_SELECT_FAILED';

export const DETAILS = 'countries/DETAILS';
export const DETAILS_SUCCESS = 'countries/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'countries/DETAILS_FAILED';

export const UPDATE = 'countries/UPDATE';
export const UPDATE_SUCCESS = 'countries/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'countries/UPDATE_FAILED';
