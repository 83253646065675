import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';

import * as CountriesActions from '../../store/actions/countries';
import * as SectorsActions from '../../store/actions/sectors';
import {
    SectorsState, getSectorDetailsState, getUpdateSectorState, getCreateSectorState, getMarketsState,
} from '../../store/reducers/sectors';
import { RequestState, MainReducerState } from '../../store/reducers';
import { Sector, SectorListItem, FileSubType } from '../../store/api/apiTypes';
import { getCountriesSelectState, CountriesState } from '../../store/reducers/countries';

import { t } from '../../utils';
import FileUpload from '../../components/FileUpload';
import { sectorIconsMap } from '.';
import MultiLangInput from '../../components/MultiLangInput';

interface SectorFormDrawerProps extends FormComponentProps {
    countriesSelectState: CountriesState['listSelect'];
    create: typeof SectorsActions.create;
    creates: RequestState;
    details: SectorsState['details'];
    getDetails: typeof SectorsActions.details;
    id?: Sector['id'];
    isVisible: boolean;
    listSelectCountries: typeof CountriesActions.listSelect;
    listMarkets: typeof SectorsActions.listMarkets;
    onClose: () => void;
    onUpdateSuccess: () => void;
    marketsSelectState: SectorsState['listMarkets'];
    update: typeof SectorsActions.update;
    updates: RequestState;
}

const SectorForm: FC<SectorFormDrawerProps> = ({
    countriesSelectState, create, creates, details, getDetails, form, id, isVisible,
    listSelectCountries, listMarkets, onClose, onUpdateSuccess, marketsSelectState, update,
    updates,

}) => {
    const isEditing = id !== undefined;

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }
            listMarkets({ sectorType: 'market', limit: 20 });
            listSelectCountries({ limit: 20 });

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listMarkets({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: 'market',
        });
    };

    const onCountriesSearch: SelectProps['onSearch'] = (value) => {
        listSelectCountries({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onChangeFile = (field: string, file: File | Blob | Array<File | Blob | undefined> | undefined) => {
        form.setFieldsValue({ [field]: file });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                    icon: val.icon || null,
                });
            } else {
                create({
                    ...val,
                    icon: val.icon || null,
                    type: 'sector',
                });
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'un secteur'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom">
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom du marché, secteur ou besoin" />,
                                    )}
                                </MultiLangInput>
                                <Form.Item label="Parents">
                                    {getFieldDecorator('sectorParents', {
                                        initialValue: isEditing && details.data ?
                                            details.data.sectorParents.map((s: Sector) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={marketsSelectState.loading}
                                            onSearch={onSectorSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs marchés"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.sectorParents.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...marketsSelectState.data]) :
                                                marketsSelectState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Exclusions">
                                    {getFieldDecorator('excludedSectors', {
                                        initialValue: isEditing && details.data ?
                                            details.data.excludedSectors.map((s: Sector) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={marketsSelectState.loading}
                                            onSearch={onSectorSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs marchés"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.excludedSectors.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...marketsSelectState.data]) :
                                                marketsSelectState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Pays rattachés">
                                    {getFieldDecorator('countries', {
                                        initialValue: isEditing && details.data && details.data.countries ?
                                            details.data.countries.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            filterOption={false}
                                            loading={countriesSelectState.loading}
                                            onSearch={onCountriesSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs pays"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.countries.reduce((acc, country) => {
                                                    if (!acc.find((s) => s.id === country.id)) {
                                                        acc.unshift(country);
                                                    }
                                                    return acc;
                                                }, [...countriesSelectState.data]) :
                                                countriesSelectState.data
                                            ).map((country) => (
                                                <Select.Option
                                                    key={country.id}
                                                    value={country.id}
                                                >
                                                    {t(country.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Référence">
                                            {getFieldDecorator('reference', {
                                                initialValue: isEditing && details.data ?
                                                    details.data.reference :
                                                    null,
                                            })(
                                                <Input placeholder="Code référence" />,
                                            )}
                                </Form.Item>
                                <Form.Item label="Icône" extra="Icône de fond pour les cartes de secteurs d'activités">
                                    {getFieldDecorator('icon', {
                                        validateTrigger: 'onBlur',
                                        initialValue: isEditing && details.data ?
                                            details.data.icon :
                                            null,
                                    })((
                                        <Select
                                            allowClear={true}
                                            className="icon-select"
                                            dropdownClassName="icon-select-dropdown"
                                            filterOption={false}
                                            placeholder="choisir une icône"
                                        >
                                            {Object.keys(sectorIconsMap).map((icon) => {
                                                const Component = sectorIconsMap[icon];

                                                return (
                                                    <Select.Option
                                                        key={icon}
                                                        value={icon}
                                                    >
                                                        <Component />
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Secteur sans sous-secteurs" extra="Pour un secteur d'activité qui n'aura aucun sous-secteur. Permet de passer directement de l'étape secteurs à l'étape besoins.">
                                    {getFieldDecorator('skipSubLevel', {
                                        valuePropName: 'checked',
                                        initialValue: isEditing && details.data ?
                                            details.data.skipSubLevel :
                                            false,
                                    })((
                                        <Switch />
                                    ))}
                                </Form.Item>
                                <Form.Item label="Bannière secteur">
                                    {getFieldDecorator('image', {
                                        initialValue: isEditing && details.data ?
                                            details.data.image :
                                            false,
                                    })((
                                        <FileUpload
                                            fileType="image/png"
                                            fileSize={500}
                                            hint="fichier png de 500ko maximum"
                                            initialValue={isEditing && details.data && details.data.image ?
                                                [{
                                                    uid: '1',
                                                    size: 0,
                                                    name: details.data.image.filename,
                                                    status: 'done',
                                                    type: 'image/png',
                                                    response: details.data.image,
                                                    url: details.data.image.url,
                                                }] : undefined}
                                            isEditing={isEditing}
                                            onChange={onChangeFile.bind(null, 'image')}
                                            onDeleteSuccess={onUpdateSuccess}
                                            onUploadSuccess={onUpdateSuccess}
                                            uploadPayload={{
                                                sectorId: details.data ? details.data.id : -1,
                                            }}
                                        />
                                    ))}
                                </Form.Item>
                                <Form.Item label="PDF Informations secteur">
                                    {getFieldDecorator('sectorInformation', {
                                        initialValue: isEditing && details.data ?
                                            details.data.sectorInformation :
                                            false,
                                    })((
                                        <FileUpload
                                            fileType="application/pdf"
                                            hint="fichier pdf"
                                            initialValue={isEditing && details.data && details.data.sectorInformation ?
                                                [{
                                                    uid: '1',
                                                    size: 0,
                                                    name: details.data.sectorInformation.filename,
                                                    status: 'done',
                                                    type: 'application/pdf',
                                                    response: details.data.sectorInformation,
                                                    url: details.data.sectorInformation.url,
                                                }] : undefined}
                                            isEditing={isEditing}
                                            onChange={onChangeFile.bind(null, 'sectorInformation')}
                                            onDeleteSuccess={onUpdateSuccess}
                                            onUploadSuccess={onUpdateSuccess}
                                            uploadPayload={{
                                                subType: FileSubType.sectorInformation,
                                                sectorId: details.data ? details.data.id : -1,
                                            }}
                                        />
                                    ))}
                                </Form.Item>
                                <Form.Item label="PDF Informations secteur (sans logos)">
                                    {getFieldDecorator('sectorInformationWithoutLogos', {
                                        initialValue: isEditing && details.data ?
                                            details.data.sectorInformationWithoutLogos :
                                            false,
                                    })((
                                        <FileUpload
                                            fileType="application/pdf"
                                            hint="fichier pdf"
                                            initialValue={
                                                isEditing && details.data.sectorInformationWithoutLogos ?
                                                    [{
                                                        uid: '1',
                                                        size: 0,
                                                        name: '',
                                                        status: 'done',
                                                        type: 'application/pdf',
                                                        response: details.data.sectorInformationWithoutLogos,
                                                        url: details.data.sectorInformationWithoutLogos.url,
                                                    }] :
                                                    undefined
                                            }
                                            isEditing={isEditing}
                                            onChange={onChangeFile.bind(null, 'sectorInformationWithoutLogos')}
                                            onDeleteSuccess={onUpdateSuccess}
                                            onUploadSuccess={onUpdateSuccess}
                                            uploadPayload={{
                                                subType: FileSubType.sectorInformationWithoutLogos,
                                                sectorId: details.data ? details.data.id : -1,
                                            }}
                                        />
                                    ))}
                                </Form.Item>
                                <Form.Item label="PDF Sales academy">
                                    {getFieldDecorator('salesAcademy', {
                                        initialValue: isEditing && details.data ?
                                            details.data.salesAcademy :
                                            false,
                                    })((
                                        <FileUpload
                                            fileType="application/pdf"
                                            hint="fichier pdf"
                                            initialValue={
                                                isEditing && details.data && details.data.salesAcademy ?
                                                    [{
                                                        uid: '1',
                                                        size: 0,
                                                        name: '',
                                                        status: 'done',
                                                        type: 'application/pdf',
                                                        response: details.data.salesAcademy,
                                                        url: details.data.salesAcademy.url,
                                                    }] :
                                                    undefined
                                            }
                                            isEditing={isEditing}
                                            onChange={onChangeFile.bind(null, 'salesAcademy')}
                                            onDeleteSuccess={onUpdateSuccess}
                                            onUploadSuccess={onUpdateSuccess}
                                            uploadPayload={{
                                                subType: FileSubType.salesAcademy,
                                                sectorId: details.data ? details.data.id : -1,
                                            }}
                                        />
                                    ))}
                                </Form.Item>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    countriesSelectState: getCountriesSelectState(state),
    creates: getCreateSectorState(state),
    details: getSectorDetailsState(state),
    marketsSelectState: getMarketsState(state),
    updates: getUpdateSectorState(state),
});

const SectorFormDrawer = Form.create<SectorFormDrawerProps>()(SectorForm);

export default connect(
    mapStateToProps,
    {
        create: SectorsActions.create,
        listSelectCountries: CountriesActions.listSelect,
        listMarkets: SectorsActions.listMarkets,
        getDetails: SectorsActions.details,
        update: SectorsActions.update,
    },
)(SectorFormDrawer);
