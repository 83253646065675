import { ActionCreator } from 'redux';

import { DataAction } from '../actions';
import * as reduxTypes from '../types/digitalBook';

export const update: ActionCreator<DataAction<File>> = (data: File) => ({
    type: reduxTypes.UPDATE,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});
