import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import * as CountriesActions from '../../store/actions/countries';
import * as SectorsActions from '../../store/actions/sectors';
import {
    CountriesState, getCountryDetailsState, getUpdateCountryState,
} from '../../store/reducers/countries';
import {  MainReducerState } from '../../store/reducers';
import { Country, SectorListItem } from '../../store/api/apiTypes';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import { getSectorsSelectState, SectorsState } from '../../store/reducers/sectors';
import { SelectProps } from 'antd/lib/select';

interface CountryFormDrawerProps extends FormComponentProps {
    details: CountriesState['details'];
    getDetails: typeof CountriesActions.details;
    id?: Country['id'];
    isVisible: boolean;
    listSelectSectors: typeof SectorsActions.listSelect;
    onClose: () => void;
    onUpdateSuccess: () => void;
    sectorsSelectState: SectorsState['listSelect'];
    updates: CountriesState['update'];
    update: typeof CountriesActions.update;
}

const CountryFormDrawer: FC<CountryFormDrawerProps> = ({
    details, getDetails, form, id, isVisible, listSelectSectors, onClose, onUpdateSuccess,
    sectorsSelectState, update, updates,
}) => {
    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }

            getDetails(id);
            listSelectSectors({ limit: 20 });
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            update(id, val);
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Drawer
            title={`Edition de ${details.data ? t(details.data.name) : ''}`}
            width={580}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={details.loading} tip="Chargement...">
                {(!details.loading && details.data) && (
                    <Form onSubmit={onSubmit} layout="vertical">
                        <MultiLangInput label="Nom du pays" required>
                            {(lang) => getFieldDecorator(`name[${lang}]`, {
                                rules: [{
                                    required: lang === 'fr',
                                    message: 'champ requis',
                                }],
                                initialValue: details.data && details.data.name ?
                                    details.data.name[lang] :
                                    undefined,
                            })(
                                <Input placeholder="Nom du pays" />,
                            )}
                        </MultiLangInput>
                        <Form.Item label="Secteurs associés">
                            {getFieldDecorator('sectors', {
                                initialValue: details.data && details.data.sectors ?
                                    details.data.sectors.map((s) => s.id) :
                                    [],
                            })((
                                <Select
                                    allowClear={true}
                                    filterOption={false}
                                    loading={sectorsSelectState.loading}
                                    onSearch={onSectorSearch}
                                    mode="multiple"
                                    placeholder="Rechercher et choisir un ou plusieurs secteurs"
                                    showSearch
                                >
                                    {(details.data && details.data.sectors ?
                                        details.data.sectors.reduce((acc, sector) => {
                                            if (!acc.find((s) => s.id === sector.id)) {
                                                acc.unshift(sector as SectorListItem);
                                            }
                                            return acc;
                                        }, [...sectorsSelectState.data]) :
                                        sectorsSelectState.data
                                    ).map((sector) => (
                                        <Select.Option
                                            key={sector.id}
                                            value={sector.id}
                                        >
                                            {t(sector.name)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ))}
                        </Form.Item>
                        <div className="form-actions">
                            <Button
                                htmlType="submit"
                                type="primary"
                                onClick={onSubmit}
                                loading={updates.loading}
                            >
                                {'Mettre à jour'}
                            </Button>
                            <Button onClick={onDrawerClose} type="ghost">
                                Annuler
                            </Button>
                        </div>
                    </Form>
                )}
            </Spin>
        </Drawer>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    details: getCountryDetailsState(state),
    sectorsSelectState: getSectorsSelectState(state),
    updates: getUpdateCountryState(state),
});

const ProductFormDrawer = Form.create<CountryFormDrawerProps>()(CountryFormDrawer);

export default connect(
    mapStateToProps,
    {
        getDetails: CountriesActions.details,
        listSelectSectors: SectorsActions.listSelect,
        update: CountriesActions.update,
    },
)(ProductFormDrawer);
