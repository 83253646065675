import { combineReducers } from 'redux';
import args, { ArgumentsState } from './arguments';
import associatedProductGroups, { AssociatedProductGroupsState } from './associatedProductGroups';
import auth, { AuthState } from './auth';
import chapters, { ChaptersState } from './chapters';
import countries, { CountriesState } from './countries';
import customers, { CustomersState } from './customers';
import dashboard, { DashboardState } from './dashboard';
import digitalBook, { DigitalBookState } from './digitalBook';
import languages, { LanguagesState } from './languages';
import nace, { NaceState } from './nace';
import news, { NewsState } from './news';
import permissions, { PermissionsState } from './permissions';
import products, { ProductsState } from './products';
import profiles, { ProfilesState } from './profiles';
import properties, { PropertiesState } from './properties';
import sectors, { SectorsState } from './sectors';
import terms, { TermsState } from './terms';
import themes, { ThemesState } from './themes';
import users, { UsersState } from './users';

export interface RequestState<T = any> {
    readonly data: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}

export interface MainReducerState {
    readonly args: ArgumentsState;
    readonly associatedProductGroups: AssociatedProductGroupsState;
    readonly auth: AuthState;
    readonly chapters: ChaptersState;
    readonly countries: CountriesState;
    readonly customers: CustomersState;
    readonly dashboard: DashboardState;
    readonly digitalBook: DigitalBookState;
    readonly languages: LanguagesState;
    readonly nace: NaceState;
    readonly news: NewsState;
    readonly permissions: PermissionsState;
    readonly products: ProductsState;
    readonly profiles: ProfilesState;
    readonly properties: PropertiesState;
    readonly sectors: SectorsState;
    readonly terms: TermsState;
    readonly themes: ThemesState;
    readonly users: UsersState;
}

const mainReducer = combineReducers<MainReducerState>({
    args,
    associatedProductGroups,
    auth,
    chapters,
    countries,
    customers,
    dashboard,
    digitalBook,
    languages,
    nace,
    news,
    permissions,
    products,
    profiles,
    properties,
    sectors,
    terms,
    themes,
    users,
});

export default mainReducer;
