import { Action, ActionCreator } from 'redux';

import { DataAction, IdAction } from '../actions';
import * as reduxTypes from '../types/properties';
import { Property, PropertyDetails, PropertyListItem, PropertyGroup, PropertyGroupDetails } from '../api/apiTypes';
import { SearchPaginationQuery } from '../api';

export const list: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST,
    ...queryParams,
});

export const listSuccess: ActionCreator<DataAction<PropertyListItem[]>> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const listSelect: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SELECT,
    ...queryParams,
});

export const listSelectSuccess: ActionCreator<DataAction<PropertyListItem[]>> = (data) => ({
    type: reduxTypes.LIST_SELECT_SUCCESS,
    data,
});

export const listSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_FAILED,
    data,
});

export const details: ActionCreator<IdAction<Property['id']>> = (id: Property['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

export const create: ActionCreator<DataAction<Partial<PropertyDetails>>> = (data) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

export const update: ActionCreator<DataAction<Partial<PropertyDetails>> & IdAction<Property['id']>> = (
    id: Property['id'],
    data: Partial<PropertyDetails>,
) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});

export type ChangeOrderAction = DataAction<{
    newOrder: number;
    previousOrder: number;
    propertyId: Property['id'];
    groupId: PropertyGroup['id'];
    type: 'property' | 'group';
}>;

export const changeOrder: ActionCreator<ChangeOrderAction> = (
    data: {
        newOrder: number;
        previousOrder: number;
        propertyId: Property['id'];
        groupId: PropertyGroup['id'];
        type: 'property' | 'group';
    },
) => ({
    type: reduxTypes.CHANGE_ORDER,
    data,
});

export const changeOrderSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CHANGE_ORDER_SUCCESS,
    data,
});

export const changeOrderFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CHANGE_ORDER_FAILED,
    data,
});

export const del: ActionCreator<IdAction<Property>> = (id) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const delFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
});

export const listGroup: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.GROUP_LIST,
    ...queryParams,
});

export const listGroupSuccess: ActionCreator<DataAction<PropertyGroup[]>> = (data) => ({
    type: reduxTypes.GROUP_LIST_SUCCESS,
    data,
});

export const listGroupFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_LIST_FAILED,
    data,
});

export const listGroupSelect: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.GROUP_LIST_SELECT,
    ...queryParams,
});

export const listGroupSelectSuccess: ActionCreator<DataAction<PropertyGroup[]>> = (data) => ({
    type: reduxTypes.GROUP_LIST_SELECT_SUCCESS,
    data,
});

export const listGroupSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_LIST_SELECT_FAILED,
    data,
});

export const detailsGroup: ActionCreator<IdAction<PropertyGroup['id']>> = (id: PropertyGroup['id']) => ({
    type: reduxTypes.GROUP_DETAILS,
    id,
});

export const detailsGroupSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_DETAILS_SUCCESS,
    data,
});

export const detailsGroupFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_DETAILS_FAILED,
    data,
});

export const createGroup: ActionCreator<DataAction<Partial<PropertyGroupDetails>>> = (data) => ({
    type: reduxTypes.GROUP_CREATE,
    data,
});

export const createGroupSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_CREATE_SUCCESS,
    data,
});

export const createGroupFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_CREATE_FAILED,
    data,
});

export const updateGroup: ActionCreator<
    DataAction<Partial<PropertyGroupDetails>> &
    IdAction<PropertyGroupDetails['id']>
> = (
    id: PropertyGroupDetails['id'],
    data: Partial<PropertyGroupDetails>,
) => ({
    type: reduxTypes.GROUP_UPDATE,
    id,
    data,
});

export const updateGroupSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_UPDATE_SUCCESS,
    data,
});

export const updateGroupFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_UPDATE_FAILED,
    data,
});

export const delGroup: ActionCreator<IdAction<Property>> = (id) => ({
    type: reduxTypes.GROUP_DELETE,
    id,
});

export const delGroupSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_DELETE_SUCCESS,
    data,
});

export const delGroupFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GROUP_DELETE_FAILED,
    data,
});
