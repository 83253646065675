import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Button, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import { ProductGroup } from '../../store/api/apiTypes';
import * as AssociatedProductGroupsActions from '../../store/actions/associatedProductGroups';
import { MainReducerState } from '../../store/reducers';
import { getAssociatedProductGroupsState, AssociatedProductGroupsState, getDeleteAssociatedProductGroupState } from '../../store/reducers/associatedProductGroups';
import { constants, t, scrollToElement } from '../../utils';
import { AssociatedProductGroupsFormDrawer } from '.';
import { IconTrash } from '../../components/icons';
import { usePrevious } from '../../hooks';
import ListTitle from '../../components/ListTitle';

export interface AssociatedProductGroupsProps {
    associatedProductGroupsState: AssociatedProductGroupsState['list'];
    deletes: AssociatedProductGroupsState['delete'];
    list: typeof AssociatedProductGroupsActions.list;
    deleteGroup: typeof AssociatedProductGroupsActions.del;
}

const rowKey = (item: ProductGroup) => `${item.id}`;

const AssociatedProductGroups: FC<AssociatedProductGroupsProps> = ({
    associatedProductGroupsState, deleteGroup, deletes, list,
}) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const prevDeletes = usePrevious<Partial<AssociatedProductGroupsProps>>({ deletes });
    const [selectedId, setSelectedId] = useState();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;

    useEffect(() => {
        list({
            page: currentPage,
            limit: constants.PAGE_SIZE,
        });
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            page: currentPage,
            throttling: 300,
            limit: constants.PAGE_SIZE,
        });
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevDeletes && prevDeletes.deletes && prevDeletes.deletes.loading && !deletes.loading && deletes.success) {
            list({
                page: currentPage,
                limit: constants.PAGE_SIZE,
            });
        }
    }, [deletes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: ProductGroup['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onClickDelete = (id: ProductGroup['id']) => {
        deleteGroup(id);
    };

    const onTableChange: TableProps<ProductGroup>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list({
            page: currentPage,
            limit: constants.PAGE_SIZE,
        });
    };

    const columns: Array<ColumnProps<ProductGroup>> = [
        {
            dataIndex: 'name',
            title: 'Nom',
            render: t,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    <Popconfirm
                        cancelText="non"
                        okText="oui"
                        onConfirm={onClickDelete.bind(null, record.id)}
                        title="Voulez-vous vraiment supprimer ce groupe ?"
                    >
                        <Button
                            loading={deletes.loading}
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        >
                            <IconTrash />
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <ListTitle total={associatedProductGroupsState.total}>
                    Liste des groupes de produits associés
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer un groupe
                </Button>
            </div>
            <Table<ProductGroup>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={associatedProductGroupsState.data}
                loading={associatedProductGroupsState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: associatedProductGroupsState.total,
                }}
            />
            <AssociatedProductGroupsFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    associatedProductGroupsState: getAssociatedProductGroupsState(state),
    deletes: getDeleteAssociatedProductGroupState(state),
});

export default connect(
    mapStateToProps,
    {
        list: AssociatedProductGroupsActions.list,
        deleteGroup: AssociatedProductGroupsActions.del,
    },
)(AssociatedProductGroups);
