import { call, delay, takeLatest, put } from 'redux-saga/effects';
import { Action } from 'redux';

import * as actions from '../actions/users';
import * as reduxTypes from '../types/users';
import * as api from '../api/users';
import { SearchPaginationQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { User } from '../api/apiTypes';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export function* listAdminsSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listAdminsSuccess(response));
    } catch (error) {
        return yield put(actions.listAdminsFailed(error));
    }
}

export function* detailsSaga(action: IdAction<User['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(actions.detailsSuccess(response));
    } catch (error) {
        return yield put(actions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<User['id']> & DataAction<Partial<User>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export function* createSaga(action: DataAction<Partial<User>>) {
    try {
        const response = yield call(api.create, action.data);

        return yield put(actions.createSuccess(response));
    } catch (error) {
        return yield put(actions.createFailed(error));
    }
}

export function* deleteSaga(action: IdAction<User['id']>) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(actions.delSuccess(response));
    } catch (error) {
        return yield put(actions.delFailed(error));
    }
}

export default function* usersSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_ADMINS, listAdminsSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
}
