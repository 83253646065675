import * as React from 'react';

import ButtonLink from '../../components/ButtonLink';
import EmptyResult from '../../components/EmptyResult';
import { getRoute, RoutePathName } from '../../routes';

const NotFound: React.FC = () => (
    <div className="container text-center" style={{ paddingTop: 64 }}>
        <h1>404 Page non trouvée</h1>
        <EmptyResult
            text="La page demandée n'a pas été trouvée."
        />
        <ButtonLink to={getRoute(RoutePathName.dashboard)} type="primary">
            {'Retourner à l\'accueil'}
        </ButtonLink>
    </div>
);

export default NotFound;
