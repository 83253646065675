import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Layout, Form, Icon, Input, Button,
} from 'antd';

import '../../assets/styles/Login.less';

import * as AuthActions from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { AuthState, getAuthState } from '../../store/reducers/auth';
import { IconLogo, IconLogoText } from '../../components/icons';
import { getRoute, RoutePathName } from '../../routes';

interface LoginProps extends RouteComponentProps, FormComponentProps {
    onLogin: typeof AuthActions.login;
    authState: AuthState;
}

const Login: FC<LoginProps> = ({ authState, form, onLogin }) => {
    const { getFieldDecorator } = form;
    const onFormSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            onLogin(values);
        });
    }, [form, onLogin]);

    if (authState.isConnected) {
        return <Redirect to="/" />;
    }

    let error = authState.error ?
        'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur' :
        null;

    if (authState.error && authState.error.status && authState.error.status === 401) {
        error = 'Informations de connexion invalides.';
    }

    return (
        <Layout id="login-layout">
            <Layout.Content>
                <header>
                    <IconLogo />
                    <IconLogoText />
                </header>
                <Form onSubmit={onFormSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Please input your username!' }],
                        })((
                            <Input
                                prefix={<Icon type="user" />}
                                placeholder="Username"
                            />
                        ))}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }],
                        })((
                            <Input.Password
                                prefix={<Icon type="lock" />}
                                placeholder="Password"
                            />
                        ))}
                    </Form.Item>
                    <Link to={getRoute(RoutePathName.forgottenPassword)}>
                        Mot de passe oublié ?
                    </Link>
                    {error ? (
                        <div className="login-error has-error">
                            <span className="ant-form-explain">{error}</span>
                        </div>
                    ) : null}
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={authState.loading}
                            block
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Layout.Content>
        </Layout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

const LoginForm = Form.create()(Login);

export default connect(
    mapStateToProps,
    {
        onLogin: AuthActions.login,
    },
)(LoginForm);
