import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="360" height="345" viewBox="0 0 360 345">
        <path fill="currentColor" d="M224.212 32.41a8.478 8.478 0 0 0-4.16 1.085c-26.36 14.723-47.71 17.81-60.98 17.81-8.465 0-13.27-1.28-13.295-1.288a8.66 8.66 0 0 0-2.36-.33c-3.773 0-7.15 2.523-8.206 6.132a8.487 8.487 0 0 0 .69 6.505 8.504 8.504 0 0 0 5.094 4.113c.714.21 7.305 2.052 18.295 2.052 15.13 0 39.4-3.48 69.098-20.063a8.53 8.53 0 0 0 4.06-5.138 8.494 8.494 0 0 0-.764-6.5 8.565 8.565 0 0 0-7.472-4.38zM37.62 57.64a8.486 8.486 0 0 0 4.577 1.338 8.527 8.527 0 0 0 7.215-3.95c.517-.804 12.947-19.742 42.68-19.742 8.09 0 16.827 1.423 25.962 4.236 10.104 3.112 20.782 4.69 31.74 4.686 45.84 0 83.81-27.604 85.407-28.784 3.788-2.8 4.592-8.16 1.792-11.948A8.57 8.57 0 0 0 230.115 0a8.505 8.505 0 0 0-5.07 1.667c-.346.252-35.126 25.36-75.682 25.36-9.142 0-17.986-1.296-26.283-3.848-10.62-3.27-20.927-4.93-30.625-4.93-39.453 0-57.298 27.334-57.486 27.63-2.498 3.97-1.308 9.246 2.65 11.76zm281.705 208.397v-44.973c0-4.715-3.838-8.55-8.55-8.55-4.713 0-8.547 3.835-8.547 8.55v44.973c0 4.715 3.834 8.55 8.548 8.55 4.71 0 8.55-3.835 8.55-8.55zm-80.657 0v-44.973c0-4.715-3.84-8.55-8.55-8.55-4.714 0-8.548 3.835-8.548 8.55v44.973c0 4.715 3.834 8.55 8.55 8.55 4.71 0 8.548-3.835 8.548-8.55zm-91.25 0v-44.973c0-4.715-3.833-8.55-8.548-8.55s-8.55 3.835-8.55 8.55v44.973c0 4.715 3.835 8.55 8.55 8.55 4.715 0 8.55-3.835 8.55-8.55zM360 108.31v227.9c0 4.848-3.94 8.79-8.784 8.79-.675 0-1.33-.09-1.957-.24H0L18.03 62.61h49.56l26.745 247.036a8.496 8.496 0 0 1-1.838 6.276 8.51 8.51 0 0 1-5.74 3.142l-.885.042c-4.398 0-8.06-3.274-8.536-7.626L52.25 79.71H34.067l-15.84 247.95h324.197V147.698l-84.333 75.688v-79.468l-88.556 79.464V147.66l-66.68 55.66a8.813 8.813 0 0 1-5.625 2.04 8.754 8.754 0 0 1-6.75-3.16 8.732 8.732 0 0 1-2.005-6.42 8.726 8.726 0 0 1 3.125-5.96l95.506-79.724v73.9l88.556-79.458v79.455L360 108.31z" fillRule="evenodd" />
    </svg>
);

const IconLock: React.FC<IconProps> = (props) => (
    <Icon
        component={svg}
        {...props}
        className={`anticon icon-industry${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconLock;
