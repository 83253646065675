import { RequestState } from '.';
import { Reducer } from 'redux';

export interface RequestReducerTypes {
    readonly START: string;
    readonly SUCCESS: string;
    readonly FAILED: string;
    readonly RESET?: string;
}

export type RequestReducer = <T>(params: RequestReducerTypes, dataInitialState?: T) =>
    Reducer<RequestState<T>>;

export const requestReducer: RequestReducer = (reduxTypes, dataInitialState) => {
    const requestReducerInitialState: RequestState = {
        data: dataInitialState,
        total: 0,
        loading: false,
    };
    return (state = requestReducerInitialState, { data, type }) => {
        switch (type) {
            case reduxTypes.START:
                return {
                    ...state,
                    loading: true,
                    error: undefined,
                    success: undefined,
                };

            case reduxTypes.SUCCESS:
                if (data && data.results !== undefined) {
                    return {
                        ...state,
                        data: data.results,
                        total: data.total,
                        loading: false,
                        success: true,
                    };
                }
                return {
                    ...state,
                    data,
                    loading: false,
                    success: true,
                };

            case reduxTypes.FAILED:
                return {
                    ...state,
                    loading: false,
                    error: data || true,
                    response: data,
                    success: false,
                };
            case reduxTypes.RESET:
                return requestReducerInitialState;

            default:
                return state;
        }
    };
};
