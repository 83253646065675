import { all, call, delay, takeLatest, put, CallEffect } from 'redux-saga/effects';
import { Action } from 'redux';

import * as actions from '../actions/chapters';
import * as reduxTypes from '../types/chapters';
import * as api from '../api/chapters';
import { create as createFile, update as updateFile } from '../api/files';
import { SearchPaginationQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Chapter, File } from '../api/apiTypes';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSelectSuccess(response));
    } catch (error) {
        return yield put(actions.listSelectFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Chapter['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(actions.detailsSuccess(response));
    } catch (error) {
        return yield put(actions.detailsFailed(error));
    }
}

function* uploadFilesAndEnrichPayload(data: Partial<Chapter>) {
    const payload = { ...data };

    if (data.chapterFiles) {
        const filesQueries: CallEffect[] = [];
        const chapterFilesToUploadIndices: number[] = [];
        data.chapterFiles.forEach((chapterFile, index) => {
            if (chapterFile.pdfToUpload) {
                chapterFilesToUploadIndices.push(index);
                const { file, ...body } = chapterFile.pdfToUpload;
                filesQueries.push(call(createFile, file, body));
            }
        });

        const fileQueriesResponses: File[] = yield all(filesQueries);

        fileQueriesResponses.forEach((res, index) => {
            payload.chapterFiles![chapterFilesToUploadIndices[index]].fileId = res.id;
            delete payload.chapterFiles![chapterFilesToUploadIndices[index]].pdfToUpload;
        });
    }

    return payload;
}

function* updateFilesWithChapterFileId(data: Chapter) {
    const filesQueries: CallEffect[] = [];

    data.chapterFiles.forEach((chapterFile) => {
        filesQueries.push(call(updateFile, chapterFile.pdf.id, {
            chapterFileId: chapterFile.id,
        }));
    });

    yield all(filesQueries);
}

export function* updateSaga(action: IdAction<Chapter['id']> & DataAction<Partial<Chapter>>) {
    try {
        const payload = yield uploadFilesAndEnrichPayload(action.data);
        const response = yield call(api.update, action.id, payload);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export function* createSaga(action: DataAction<Partial<Chapter>>) {
    try {
        const payload = yield uploadFilesAndEnrichPayload(action.data);
        const response = yield call(api.create, payload);

        // when we upload the file we don't know the chapterFileId since it doesn't exist yet
        // so we need to upload the file without it, create the chapter, and then update the files
        // with the chapterFileId from the chapter we just created
        yield updateFilesWithChapterFileId(response);

        return yield put(actions.createSuccess(response));
    } catch (error) {
        return yield put(actions.createFailed(error));
    }
}

export function* deleteSaga(action: IdAction<Chapter['id']>) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(actions.delSuccess(response));
    } catch (error) {
        return yield put(actions.delFailed(error));
    }
}

export default function* chaptersSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
}
