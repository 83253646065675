import api from './_client';

export interface LoginPayload {
    email: string;
    password: string;
}

export interface ForgottenPasswordPayload {
    email: string;
}

export const login = (payload: LoginPayload) => api.post('/api/login', payload);

export const logout = () => api.post('/api/logout');

export const getUserDetails = () => api.get('/api/me');

export const forgottenPassword = (payload: ForgottenPasswordPayload) =>
    api.post('/api/recovery', payload);
