import { Action, ActionCreator } from 'redux';
import { DataAction } from '.';
import * as reduxTypes from '../types/dashboard';

export const fetch: ActionCreator<Action> = () => ({
    type: reduxTypes.FETCH,
});

export const fetchSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_SUCCESS,
    data,
});

export const fetchFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_FAILED,
    data,
});
