import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { InputProps, SearchProps } from 'antd/lib/input';

import { Theme } from '../../store/api/apiTypes';
import * as ThemesActions from '../../store/actions/themes';
import { MainReducerState } from '../../store/reducers';
import { getThemesState, ThemesState, getDeleteThemeState } from '../../store/reducers/themes';

import { constants, t, scrollToElement } from '../../utils';
import ListTitle from '../../components/ListTitle';
import { ThemeFormDrawer } from '.';
import { IconTrash } from '../../components/icons';

export interface ThemesProps {
    deletes: ThemesState['delete'];
    deleteTheme: typeof ThemesActions.del;
    list: typeof ThemesActions.list;
    themesState: ThemesState['list'];
}

const rowKey = (item: Theme) => `${item.id}`;

const Themes: FC<ThemesProps> = ({ deleteTheme, deletes, themesState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const searchParam = urlParams.get('search');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;

    const columns: Array<ColumnProps<Theme>> = [
        {
            dataIndex: 'name',
            title: 'Nom',
            render: t,
        },
        {
            dataIndex: 'chapters',
            title: () => <>N<sup>bre</sup> chapitres associés</>,
            render: (val) => new Intl.NumberFormat().format(val.length),
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    <Popconfirm
                        cancelText="non"
                        okText="oui"
                        onConfirm={onClickDelete.bind(null, record.id)}
                        title="Voulez-vous vraiment supprimer ce thème ?"
                    >
                        <Button
                            loading={deletes.loading}
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        >
                            <IconTrash />
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    useEffect(() => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            page: currentPage,
            search,
            throttling: 300,
            limit: constants.PAGE_SIZE,
        });
    }, [location.pathname, searchParam]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: Theme['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onSearchChange: InputProps['onChange'] = (e) => {
        urlParams.set('page', '0');
        urlParams.set('search', e.target.value);
        setSearch(e.target.value);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        urlParams.set('page', '0');
        urlParams.set('search', value);
        setSearch(value);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onTableChange: TableProps<Theme>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    };

    const onClickDelete = (id: Theme['id']) => {
        deleteTheme(id);
    };

    return (
        <>
            <div className="list-header">
                <ListTitle
                    total={themesState.total}
                >
                    Liste des thèmes de présentations et propositions
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer un thème
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<Theme>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={themesState.data}
                loading={themesState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: themesState.total,
                }}
            />
            <ThemeFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    deletes: getDeleteThemeState(state),
    themesState: getThemesState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteTheme: ThemesActions.del,
        list: ThemesActions.list,
    },
)(Themes);
