import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { Property, PropertyDetails, PropertyListItem, PropertyGroup, PropertyGroupDetails } from './apiTypes';

export const list = (payload: SearchPaginationQuery) =>
    api.get<PropertyListItem[]>(urlWithQuery('/api/products/properties', payload));

export const details = (id: Property['id']) =>
    api.get<PropertyDetails>(`/api/products/properties/${id}`);

export const update = (id: Property['id'], body: Partial<PropertyDetails>) =>
    api.put<Property>(`/api/products/properties/${id}`, body);

export interface ChangeOrderPayload {
    id: Property['id'];
    newOrder: number;
    previousOrder: number;
}

export const changeOrder = (data: {
    newOrder: number;
    previousOrder: number;
    propertyId: Property['id'];
    groupId: PropertyGroup['id'];
    type: 'property' | 'group';
}) =>
    api.post('/api/products/properties/orderChange', data);

export const create = (body: Partial<Property>) =>
    api.post<Property>('/api/products/properties', body);

export const del = (id: Property['id']) =>
    api.delete(`/api/products/properties/${id}`);

export const listGroup = (payload: SearchPaginationQuery) =>
    api.get<PropertyGroup[]>(urlWithQuery('/api/products/properties/groups', payload));

export const detailsGroup = (id: PropertyGroupDetails['id']) =>
    api.get<PropertyGroupDetails>(`/api/products/properties/groups/${id}`);

export const updateGroup = (id: PropertyGroup['id'], body: Partial<PropertyGroupDetails>) =>
    api.put<PropertyGroupDetails>(`/api/products/properties/groups/${id}`, body);

export const createGroup = (body: Partial<PropertyGroupDetails>) =>
    api.post<PropertyGroupDetails>('/api/products/properties/groups', body);

export const delGroup = (id: PropertyGroup['id']) =>
    api.delete(`/api/products/properties/groups/${id}`);
