import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, Row, Col, Icon, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';
// tslint:disable-next-line: no-submodule-imports
import cloneDeep from 'lodash/cloneDeep';

import * as PropertiesActions from '../../store/actions/properties';
import * as SectorsActions from '../../store/actions/sectors';
import { getSectorsSelectState, SectorsState } from '../../store/reducers/sectors';
import {
    PropertiesState, getPropertyDetailsState, getUpdatePropertyState, getCreatePropertyState,
} from '../../store/reducers/properties';
import { RequestState, MainReducerState } from '../../store/reducers';
import { SectorListItem, PropertyListItem, PropertyValue } from '../../store/api/apiTypes';

import { t } from '../../utils';
import PieChart from '../../components/PieChart';
import MultiLangInput from '../../components/MultiLangInput';

const colorRegex = new RegExp(/^(#(?:[0-9a-f]{3}){1,2}-)*(#(?:[0-9a-f]{3}){1,2})$/i);
let colorValidationTimeout: number;

interface PropertyFormDrawerProps extends FormComponentProps {
    create: typeof PropertiesActions.create;
    creates: RequestState;
    details: PropertiesState['details'];
    getDetails: typeof PropertiesActions.details;
    id?: PropertyListItem['id'];
    isVisible: boolean;
    listSelectSectors: typeof SectorsActions.listSelect;
    onClose: () => void;
    onUpdateSuccess: () => void;
    sectorsSelectState: SectorsState['listSelect'];
    update: typeof PropertiesActions.update;
    updates: RequestState;
}

const PropertyFormDrawer: FC<PropertyFormDrawerProps> = ({
    create, creates, details, getDetails, form, id, isVisible, listSelectSectors, onClose,
    onUpdateSuccess, sectorsSelectState, update, updates,
}) => {
    const { getFieldDecorator, getFieldValue } = form;
    const [values, setValues] = useState<Array<Partial<PropertyValue>>>(
        details.data ?
            cloneDeep(details.data.values) :
            [],
    );
    const isEditing = id !== undefined;

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            setValues([]);
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            }
            listSelectSectors();

            if (isEditing) {
                getDetails(id);
            }

            if (details.data) {
                setValues(cloneDeep(details.data.values));
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const effectDep = details.data && details.data.id;
    useEffect(() => {
        if (details.data && !details.loading) {
            setValues(cloneDeep(details.data.values));
        }
    }, [effectDep, setValues, details.loading]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
        setValues([]);
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                    values,
                });
            } else {
                create({
                    ...val,
                    values,
                });
            }
        });
    };

    const onChangeName = (index: number, lang: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const vals = [...values];
        vals[index].name = {
            ...vals[index].name,
            [lang]: e.target.value,
        };
        setValues(vals);
    };

    const onChangeColor = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const vals = [...values];
        vals[index].color = e.target.value;
        setValues(vals);

        if (colorValidationTimeout) {
            clearTimeout(colorValidationTimeout);
        }

        colorValidationTimeout = window.setTimeout(() => {
            if (!colorRegex.test(vals[index].color || '')) {
                // tslint:disable-next-line: max-line-length
                message.error('La ou les couleur(s) doivent être au format hexadécimal, séparés d\'un tiret. ex: #000000 ou #000000-#ffffff');
            }
        }, 1000);
    };

    const addValue = () => {
        setValues([...cloneDeep(values), {}]);
    };

    const removeValue = (valueIndex: number) => {
        if (values.length < 2) {
            return;
        }

        const vals = cloneDeep(values);
        vals.splice(valueIndex, 1);

        setValues(vals);
    };

    const isTypeColor = getFieldValue('type') === 'color';

    let error = creates.error || updates.error ?
        'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur' :
        null;

    if (
        (creates.error && creates.error.status && creates.error.status > 399 && creates.error.status < 500) ||
        (updates.error && updates.error.status && updates.error.status > 399 && updates.error.status < 500)
    ) {
        error = 'Veuillez vérifier les informations du formulaire.';
    }

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'une propriété'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom de la propriété">
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom de la propriété" />,
                                    )}
                                </MultiLangInput>
                                <Form.Item label="Secteurs associés">
                                    {getFieldDecorator('propertySectors', {
                                        initialValue: isEditing && details.data ?
                                            details.data.propertySectors.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={sectorsSelectState.loading}
                                            onSearch={onSectorSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs secteurs"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.propertySectors.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.unshift(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...sectorsSelectState.data]) :
                                                sectorsSelectState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Type">
                                    {getFieldDecorator('type', {
                                        initialValue: isEditing && details.data && details.data.type !== null ?
                                            details.data.type :
                                            undefined,
                                    })((
                                        <Select
                                            filterOption={false}
                                            placeholder="Choisir un type"
                                        >
                                            <Select.Option
                                                value={undefined}
                                            >
                                                Par défaut
                                            </Select.Option>
                                            <Select.Option
                                                value="color"
                                            >
                                                Couleur
                                            </Select.Option>
                                        </Select>
                                    ))}
                                </Form.Item>
                                <div className="ant-col ant-form-item-label">
                                    <label title="Valeurs">Valeurs</label>
                                </div>
                                {values.map((value: Partial<PropertyValue>, index: number) => (
                                    <div className="dynamic-item-wrapper" key={index}>
                                        <Row
                                            gutter={32}
                                            type="flex"
                                            align="bottom"
                                            style={{ position: 'relative' }}
                                        >
                                            <Col xs={isTypeColor ? 12 : 24}>
                                                <MultiLangInput>
                                                    {(lang) => (
                                                        <Input
                                                            defaultValue={
                                                                isEditing && value && value.name && value.name[lang] ?
                                                                    value.name[lang] :
                                                                    undefined
                                                            }
                                                            value={value.name && value.name[lang] ?
                                                                value.name[lang] :
                                                                undefined
                                                            }
                                                            onChange={onChangeName.bind(null, index, lang)}
                                                            placeholder="Nom de la valeur"
                                                        />
                                                    )}
                                                </MultiLangInput>
                                            </Col>
                                            {isTypeColor && (
                                                <Col xs={12}>
                                                    <Form.Item>
                                                        <Input
                                                            onChange={onChangeColor.bind(null, index)}
                                                            placeholder="Code hexadécimal"
                                                            className="color-suffix"
                                                            defaultValue={isEditing && value.color ?
                                                                value.color :
                                                                undefined
                                                            }
                                                            value={value.color || undefined}
                                                            suffix={(
                                                                <PieChart
                                                                    data={
                                                                        value.color ?
                                                                            value.color.split('-') :
                                                                            []
                                                                    }
                                                                    size={16}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            <Icon
                                                style={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 16,
                                                    color: '#e20714',
                                                }}
                                                type="minus-circle-o"
                                                onClick={removeValue.bind(null, index)}
                                            />
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item style={{ marginTop: 24 }}>
                                    <Button type="dashed" size="small" onClick={addValue}>
                                        <Icon type="plus" /> Ajouter une valeur
                                    </Button>
                                </Form.Item>
                                {error ? (
                                    <div className="login-error has-error">
                                        <span className="ant-form-explain">{error}</span>
                                    </div>
                                ) : null}
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreatePropertyState(state),
    details: getPropertyDetailsState(state),
    sectorsSelectState: getSectorsSelectState(state),
    updates: getUpdatePropertyState(state),
});

const ProductFormDrawer = Form.create<PropertyFormDrawerProps>()(PropertyFormDrawer);

export default connect(
    mapStateToProps,
    {
        create: PropertiesActions.create,
        listSelectSectors: SectorsActions.listSelect,
        getDetails: PropertiesActions.details,
        update: PropertiesActions.update,
    },
)(ProductFormDrawer);
