import { call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '../actions/languages';
import * as reduxTypes from '../types/languages';
import * as api from '../api/languages';

export function* listSaga() {
    try {
        const response = yield call(api.list);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export default function* languagesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
