import { combineReducers } from 'redux';

import * as reduxTypes from '../types/nace';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from './';

export interface NaceState {
    readonly update: RequestState<string>;
}

export default combineReducers<NaceState>({
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
});

export const getUpdateNaceState = (state: MainReducerState) => state.nace.update;
