import { call, delay, takeLatest, put } from 'redux-saga/effects';
import { Action } from 'redux';

import * as actions from '../actions/properties';
import * as reduxTypes from '../types/properties';
import * as api from '../api/properties';
import { SearchPaginationQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Property } from '../api/apiTypes';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSelectSuccess(response));
    } catch (error) {
        return yield put(actions.listSelectFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Property['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(actions.detailsSuccess(response));
    } catch (error) {
        return yield put(actions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Property['id']> & DataAction<Partial<Property>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export function* changeOrderSaga(action: actions.ChangeOrderAction) {
    try {
        const response = yield call(api.changeOrder, action.data);
        return yield put(actions.changeOrderSuccess(response));
    } catch (error) {
        return yield put(actions.changeOrderFailed(error));
    }
}

export function* createSaga(action: DataAction<Partial<Property>>) {
    try {
        const response = yield call(api.create, action.data);

        return yield put(actions.createSuccess(response));
    } catch (error) {
        return yield put(actions.createFailed(error));
    }
}

export function* deleteSaga(action: IdAction<Property['id']>) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(actions.delSuccess(response));
    } catch (error) {
        return yield put(actions.delFailed(error));
    }
}

export function* listGroupSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.listGroup, action);

        return yield put(actions.listGroupSuccess(response));
    } catch (error) {
        return yield put(actions.listGroupFailed(error));
    }
}

export function* listGroupSelectSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.listGroup, action);

        return yield put(actions.listGroupSelectSuccess(response));
    } catch (error) {
        return yield put(actions.listGroupSelectFailed(error));
    }
}

export function* detailsGroupSaga(action: IdAction<Property['id']>) {
    try {
        const response = yield call(api.detailsGroup, action.id);
        return yield put(actions.detailsGroupSuccess(response));
    } catch (error) {
        return yield put(actions.detailsGroupFailed(error));
    }
}

export function* updateGroupSaga(action: IdAction<Property['id']> & DataAction<Partial<Property>>) {
    try {
        const response = yield call(api.updateGroup, action.id, action.data);
        return yield put(actions.updateGroupSuccess(response));
    } catch (error) {
        return yield put(actions.updateGroupFailed(error));
    }
}

export function* createGroupSaga(action: DataAction<Partial<Property>>) {
    try {
        const response = yield call(api.createGroup, action.data);

        return yield put(actions.createGroupSuccess(response));
    } catch (error) {
        return yield put(actions.createGroupFailed(error));
    }
}

export function* deleteGroupSaga(action: IdAction<Property['id']>) {
    try {
        const response = yield call(api.delGroup, action.id);
        return yield put(actions.delGroupSuccess(response));
    } catch (error) {
        return yield put(actions.delGroupFailed(error));
    }
}

export default function* propertiesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.CHANGE_ORDER, changeOrderSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
    yield takeLatest(reduxTypes.GROUP_LIST, listGroupSaga);
    yield takeLatest(reduxTypes.GROUP_LIST_SELECT, listGroupSelectSaga);
    yield takeLatest(reduxTypes.GROUP_DETAILS, detailsGroupSaga);
    yield takeLatest(reduxTypes.GROUP_UPDATE, updateGroupSaga);
    yield takeLatest(reduxTypes.GROUP_CREATE, createGroupSaga);
    yield takeLatest(reduxTypes.GROUP_DELETE, deleteGroupSaga);
}
