import React, { FC } from 'react';
import { Icon, Button, Select } from 'antd';
import Form from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import { ButtonProps } from 'antd/lib/button';

import { SectorListItem, Product, Sector, CrossSelling } from '../../store/api/apiTypes';
import { ProductsState } from '../../store/reducers/products';
import { SectorsState } from '../../store/reducers/sectors';

import { t } from '../../utils';

interface CrossSellingFieldsProps {
    isEditing: boolean;
    sectorsSelectState: SectorsState['listSelect'];
    onChangeSectorId: (index: number, sectorId: Sector['id']) => void;
    onChangeProducts: (index: number, productsRefs: Array<Product['id']>) => void;
    onSectorSearch: SelectProps['onSearch'];
    productsSelectState: ProductsState['listSelect'];
    onProductSearch: SelectProps['onSearch'];
    removeValue: (valueIndex: number) => void;
    addValue: ButtonProps['onClick'];
    values: Array<Partial<CrossSelling>>;
}

const CrossSellingFields: FC<CrossSellingFieldsProps> = ({
    isEditing, sectorsSelectState, onSectorSearch, removeValue, addValue, productsSelectState,
    onProductSearch, onChangeSectorId, onChangeProducts, values,
}) => (
    <>
        <div className="ant-col ant-form-item-label">
            <label title="Cross selling">Cross selling (bloc vous aimerez aussi)</label>
        </div>
        {values.map((value: Partial<CrossSelling>, index: number) => (
            <div className="dynamic-item-wrapper" key={index}>
                <Form.Item label="Secteur" required>
                    <Select
                        allowClear={true}
                        filterOption={false}
                        defaultValue={isEditing && value && value.sector ?
                            value.sector.id :
                            undefined
                        }
                        value={value.sector ? value.sector.id : undefined}
                        loading={sectorsSelectState.loading}
                        onSearch={onSectorSearch}
                        onChange={onChangeSectorId.bind(null, index)}
                        placeholder="Rechercher et choisir un secteur"
                        showSearch
                    >
                        {(value && value.sector ?
                            [value.sector].reduce((acc, sector) => {
                                if (!acc.find((s) => s.id === sector.id)) {
                                    acc.unshift(sector as SectorListItem);
                                }
                                return acc;
                            }, [
                                ...sectorsSelectState.data.filter((s) => s.type !== 'sector' && s.type !== 'subSector'),
                            ]) :
                            sectorsSelectState.data.filter((s) => s.type !== 'sector' && s.type !== 'subSector')
                        ).map((sector) => (
                            <Select.Option
                                key={sector.id}
                                value={sector.id}
                            >
                                {t(sector.name)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Produits" required>
                    <Select
                        allowClear={true}
                        filterOption={false}
                        defaultValue={isEditing && value.products ?
                            value.products.map((p) => p.id) :
                            []
                        }
                        value={
                            value.products ?
                                value.products.filter(Boolean).map((product) => product.id) :
                                undefined
                        }
                        loading={productsSelectState.loading}
                        onSearch={onProductSearch}
                        onChange={onChangeProducts.bind(null, index)}
                        mode="multiple"
                        placeholder="Rechercher et choisir un ou plusieurs produits"
                        showSearch
                    >
                        {(value.products ?
                            value.products.reduce<Product[]>((acc, product) => {
                                if (product && !acc.find((p) => p.id === product.id)) {
                                    acc.unshift(product);
                                }
                                return acc;
                            }, [...productsSelectState.data]) :
                            productsSelectState.data
                        ).map((product) => (
                            <Select.Option
                                key={product.id}
                                value={product.id}
                            >
                                {t(product.label)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button
                    style={{
                        color: '#e20714',
                    }}
                    icon="minus-circle-o"
                    type="link"
                    onClick={removeValue.bind(null, index)}
                >
                    Supprimer
                </Button>
            </div>
        ))}
        <Form.Item style={{ marginTop: 24 }}>
            <Button type="dashed" size="small" onClick={addValue}>
                <Icon type="plus" /> Ajouter une valeur
            </Button>
        </Form.Item>
    </>
);

export default CrossSellingFields;
