import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button, Select, Icon, Tooltip } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { InputProps, SearchProps } from 'antd/lib/input';

import '../../assets/styles/Sectors.less';

import { Sector, SectorType } from '../../store/api/apiTypes';
import * as SectorsActions from '../../store/actions/sectors';
import { MainReducerState } from '../../store/reducers';
import { SectorsState, getDeleteSectorState, getGammesAndServicesState } from '../../store/reducers/sectors';

import { constants, t, scrollToElement } from '../../utils';
import { GammeAndServiceFormDrawer } from '.';
import ListTitle from '../../components/ListTitle';
import { SelectProps } from 'antd/lib/select';

export interface GammesAndServicesProps {
    deleteSector: typeof SectorsActions.del;
    deletes: SectorsState['delete'];
    sectorsState: SectorsState['listGammesAndServices'];
    list: typeof SectorsActions.listGammesAndServices;
}

const rowKey = (item: Sector) => `${item.id}`;
const filters = {
    all: {
        label: 'Tous',
        value: ['gamme', 'subGamme', 'service', 'subService'],
    },
    gamme: {
        label: 'Gammes',
        value: ['gamme'],
    },
    subGamme: {
        label: 'Sous-gammes',
        value: ['subGamme'],
    },
    service: {
        label: 'Services',
        value: ['service'],
    },
    subService: {
        label: 'Sous-services',
        value: ['subService'],
    },
};

const GammesAndServices: FC<GammesAndServicesProps> = ({ deletes, deleteSector, sectorsState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [selectedType, setSelectedType] = useState<SectorType>(SectorType.Gamme);
    const [search, setSearch] = useState<string>();
    const [filter, setFilter] = useState<keyof typeof filters>('all');
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;
    const payload = {
        page: currentPage,
        search,
        limit: constants.PAGE_SIZE,
        sectorType: filters[filter].value,
    };

    useEffect(() => {
        list(payload);
    }, [list, pageParam, deletes.success, filter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list(payload);
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            ...payload,
            page: search ? undefined : currentPage,
            throttling: 300,
        });
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: Sector['id'] | undefined, type: Sector['type']) => {
        setSelectedId(id);
        setSelectedType(type);
        setIsFormVisible(true);
    };

    // const onConfirmDelete = (id: Sector['id']) => {
    //     deleteSector(id);
    // };

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onChangeFilter: SelectProps['onChange'] = (value) => {
        setFilter(value as keyof typeof filters);
    };

    const onTableChange: TableProps<Sector>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list({
            ...payload,
            throttling: 300,
        });
    };

    const columns: Array<ColumnProps<Sector>> = [
        {
            dataIndex: 'reference',
            title: 'Réference',
            width: 100,
        },
        {
            dataIndex: 'name',
            title: 'Nom',
            render: t,
        },
        {
            dataIndex: 'type',
            title: 'Type',
            render: (val) => {
                switch (val) {
                    case 'gamme': return 'Gamme';
                    case 'subGamme': return 'Sous-gamme';
                    case 'service': return 'Service';
                    case 'subService': return 'Sous-service';
                }

                return '';
            },
        },
        {
            dataIndex: 'image',
            title: 'Bannière',
            render: (val, record) => val ? <img src={val.url} alt={t(record.name)} /> : null,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id, record.type)}
                        size="small"
                        type="link"
                    />
                    {/* <Popconfirm
                        title="Êtes-vous sûr ?"
                        onConfirm={onConfirmDelete.bind(null, record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            loading={deletes.loading}
                            icon="delete"
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        />
                    </Popconfirm> */}
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <ListTitle total={sectorsState.total}>
                    Liste des gammes, sous-gammes, services & sous-services
                </ListTitle>
                <div>
                    <Button.Group style={{ marginRight: 16 }}>
                        <Button
                            onClick={setEditMode.bind(null, undefined, SectorType.Gamme)}
                            type="primary"
                        >
                            Créer une gamme
                        </Button>
                        <Button
                            onClick={setEditMode.bind(null, undefined, SectorType.Service)}
                            type="primary"
                        >
                            Créer un service
                        </Button>
                    </Button.Group>
                    <Tooltip overlay="Gamme : exemples : Essentials / Fusion / Tapis Classique / Café (Boisson) / Essuyage (PP) / Déchets perforants (DASRI) Services : sanitiare uniquement : exemples : Essuyage des mains" placement="bottomLeft">
                        <Icon type="info-circle" style={{ fontSize: 20 }} />
                    </Tooltip>
                </div>
            </div>
            <div className="list-search-wrapper">
                <Input.Search
                    className="table-search"
                    placeholder="Rechercher"
                    onChange={onSearchChange}
                    onSearch={onSearch}
                    size="small"
                />
                <Select
                    filterOption={false}
                    onChange={onChangeFilter}
                    style={{ minWidth: 170 }}
                    value={filter}
                >
                    {Object.keys(filters).map((filterKey) => (
                        <Select.Option
                            key={filterKey}
                            value={filterKey}
                        >
                            {filters[filterKey as keyof typeof filters].label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <Table<Sector>
                bordered={false}
                className="sectors-list"
                rowKey={rowKey}
                columns={columns}
                dataSource={sectorsState.data}
                loading={sectorsState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: sectorsState.total,
                }}
            />
            <GammeAndServiceFormDrawer
                id={selectedId}
                type={selectedType}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    deletes: getDeleteSectorState(state),
    sectorsState: getGammesAndServicesState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteSector: SectorsActions.del,
        list: SectorsActions.listGammesAndServices,
    },
)(GammesAndServices);
