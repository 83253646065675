import api from './_client';
import { File as APIFile } from './apiTypes';

export const create = (file: File, payload: Partial<APIFile>) => {
    const data = new FormData();

    data.append('file', file);

    if (payload) {
        Object.keys(payload).forEach((payloadKey) => {
            if (payload[payloadKey] !== undefined) {
                data.append(payloadKey, payload[payloadKey]);
            }
        });
    }

    return api.post('/api/files', data);
};

export const update = (fileId: APIFile['id'], payload: Partial<APIFile>) =>
    api.put(`/api/files/${fileId}`, payload);
