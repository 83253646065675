import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, message } from 'antd';
import SortableTree, { TreeItem, ReactSortableTreeProps } from 'react-sortable-tree';

import 'react-sortable-tree/style.css';

import { PropertyListItem } from '../../store/api/apiTypes';
import * as PropertiesActions from '../../store/actions/properties';
import { MainReducerState } from '../../store/reducers';
import {
    getPropertiesState, PropertiesState, getPropertyGroupsState, getChangePropertyOrderState,
    getUpdatePropertyState, getDeletePropertyState, getDeletePropertyGroupState,
} from '../../store/reducers/properties';
import { t } from '../../utils';
import ListTitle from '../../components/ListTitle';
import { usePrevious } from '../../hooks';
import { IconTrash } from '../../components/icons';
import { PropertyFormDrawer, PropertyGroupFormDrawer } from '.';

const formatSubTitle = (property: PropertyListItem) => (
    `${property.source ? `${property.source} (Fichier import) - ` : ''}${property.propertySectors.length} secteur(s) associé(s) - ${property.values.length} valeur(s)`
);

const getOrderPayload: ReactSortableTreeProps['onMoveNode'] = (data) => ({
    newOrder: data.node.isGroup && (data.nextTreeIndex > data.prevTreeIndex) ?
        data.nextTreeIndex + (data.node.children ? data.node.children.length : 0) :
        data.nextTreeIndex,
    previousOrder: data.prevTreeIndex,
    propertyId: data.node.isGroup ?
        (data.node.children as TreeItem[] || []).map((child) => child.data.id) :
        data.node.data.id,
    groupId: data.node.isGroup ? data.node.data.id : null,
    type: data.node.isGroup ? 'group' : 'property',
});

export interface PropertiesProps {
    changeOrder: typeof PropertiesActions.changeOrder;
    changeOrderState: PropertiesState['changeOrder'];
    deletesState: PropertiesState['delete'];
    deletesGroupState: PropertiesState['deleteGroup'];
    deleteProperty: typeof PropertiesActions.del;
    deleteGroup: typeof PropertiesActions.delGroup;
    propertiesState: PropertiesState['list'];
    groupsState: PropertiesState['listGroup'];
    list: typeof PropertiesActions.list;
    listGroups: typeof PropertiesActions.listGroup;
    update: typeof PropertiesActions.update;
    updateState: PropertiesState['update'];
}

const Properties: FC<PropertiesProps> = ({
    changeOrder, changeOrderState, deleteProperty, deleteGroup, deletesState, deletesGroupState,
    propertiesState, groupsState, list, listGroups, update, updateState,
}) => {
    const [tree, setTree] = useState<TreeItem[]>([]);
    const [intermediateTree, setIntermediateTree] = useState<TreeItem[]>([]);
    const previous = usePrevious({
        propertiesState, groupsState, updateState, changeOrderState, deletesState, deletesGroupState,
    });
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [isGroupFormVisible, setIsGroupFormVisible] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState();
    const [orderAfterUpdate, setOrderAfterUpdate] = useState();
    const setEditMode = (id: string | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };
    const setEditGroupMode = (id: string | undefined) => {
        setSelectedGroupId(id ? parseInt(id.substr(2), 10) : undefined);
        setIsGroupFormVisible(true);
    };
    const onDelete = (id: string | undefined) => {
        deleteProperty(id);
    };
    const onDeleteGroup = (id: string | undefined) => {
        deleteGroup(parseInt((id || '').substr(2), 10));
    };
    const onDrawerClose = () => {
        setIsFormVisible(false);
    };
    const onGroupDrawerClose = () => {
        setIsGroupFormVisible(false);
    };
    const onUpdateSuccess = () => {
        listGroups({ limit: 1337 });
    };
    const onDropNode: ReactSortableTreeProps['onMoveNode'] = (data) => {
        // if parent has changed
        if (
            (data.nextParentNode && !data.node.data.group) ||
            (data.nextParentNode && data.node.data.group && data.nextParentNode.data.id !== data.node.data.group.id)
        ) {
            setOrderAfterUpdate(getOrderPayload(data));
            update(data.node.id, {
                ...data.node.data,
                groupId: data.nextParentNode.data.id,
                type: data.node.data.type || undefined,
            });
        } else if (!data.nextParentNode && data.node.data.group) {
            setOrderAfterUpdate(getOrderPayload(data));
            update(data.node.id, {
                ...data.node.data,
                groupId: null,
                type: data.node.data.type || undefined,
            });
        } else {
            changeOrder(getOrderPayload(data));
        }
    };

    useEffect(() => {
        listGroups({ limit: 1337 });
    }, [listGroups]);

    useEffect(() => {
        if (
            previous && previous.propertiesState && previous.propertiesState.loading &&
            !propertiesState.loading && propertiesState.data
        ) {
            setTree(() => {
                const result = propertiesState.data.reduce((acc, property) => {
                    if (property.group) {
                        const match = acc.find((item) => item.id === `g-${property.group!.id}`);

                        if (match) {
                            if (!match.children || !Array.isArray(match.children)) {
                                match.children = [];
                            }

                            match.children.push({
                                id: property.id,
                                data: property,
                                isGroup: false,
                                title: t(property.name),
                                subtitle: formatSubTitle(property),
                            });

                            match.children.sort((a, b) => a.data.order - b.data.order);
                        } else {
                            // if property group isn't already present (via list group),
                            // ignore group and add property to list
                            acc.push({
                                id: property.id,
                                data: property,
                                isGroup: false,
                                title: t(property.name),
                                subtitle: formatSubTitle(property),
                            });
                        }
                    } else {
                        acc.push({
                            id: property.id,
                            data: property,
                            isGroup: false,
                            title: t(property.name),
                            subtitle: formatSubTitle(property),
                        });
                    }
                    return acc;
                }, [...intermediateTree]);

                return result.sort((a, b) => a.data.order - b.data.order);
            });
        }
    }, [previous, propertiesState.data, propertiesState.loading, intermediateTree]);

    useEffect(() => {
        if (
            previous && previous.groupsState && previous.groupsState.loading &&
            !groupsState.loading && groupsState.data
        ) {
            setIntermediateTree([...groupsState.data].sort((a, b) => a.order - b.order).map((group) => ({
                id: `g-${group.id}`,
                children: [],
                data: group,
                isGroup: true,
                title: t(group.name),
                expanded: true,
            })));
            list({ limit: 1337 });
        }
    }, [previous, groupsState.data, groupsState.loading, list]);

    useEffect(() => {
        if (
            previous && previous.updateState && previous.updateState.loading &&
            !updateState.loading && updateState.data
        ) {
            changeOrder(orderAfterUpdate);
        }
    }, [previous, updateState.data, updateState.loading, changeOrder, orderAfterUpdate]);

    useEffect(() => {
        if (
            previous && previous.changeOrderState && previous.changeOrderState.loading &&
            !changeOrderState.loading && changeOrderState.data
        ) {
            listGroups({ limit: 1337 });
        }
    }, [previous, changeOrderState.data, changeOrderState.loading, listGroups]);

    useEffect(() => {
        if (
            previous && previous.deletesState && previous.deletesState.loading &&
            !deletesState.loading
        ) {
            if (deletesState.error) {
                message.error('Une erreur est survenue, veuillez réessayer plus tard ou contacter le support.');
            } else {
                listGroups({ limit: 1337 });
            }
        }
    }, [previous, deletesState.data, deletesState.loading, deletesState.error, listGroups]);

    useEffect(() => {
        if (
            previous && previous.deletesGroupState && previous.deletesGroupState.loading &&
            !deletesGroupState.loading
        ) {
            if (deletesGroupState.error) {
                message.error('Une erreur est survenue, veuillez réessayer plus tard ou contacter le support.');
            } else {
                listGroups({ limit: 1337 });
            }
        }
    }, [previous, deletesGroupState.data, deletesGroupState.loading, deletesGroupState.error, listGroups]);

    return (
        <>
            <div className="list-header">
                <ListTitle total={propertiesState.total}>
                    Liste des propriétés et groupes (vos besoins)
                </ListTitle>
                <Button.Group style={{ marginRight: 16 }}>
                    <Button
                        onClick={setEditGroupMode.bind(null, undefined)}
                        type="primary"
                    >
                        Créer un groupe
                    </Button>
                    <Button
                        onClick={setEditMode.bind(null, undefined)}
                        type="primary"
                    >
                        Créer une propriété
                    </Button>
                </Button.Group>
            </div>
            <SortableTree
                style={{ height: 'calc(100% - 72px)' }}
                treeData={tree}
                onChange={setTree}
                // tslint:disable: jsx-no-lambda
                canNodeHaveChildren={(node: any) => node.isGroup}
                canDrag={({ node }) => !(node.isGroup && (!node.children || !node.children.length))}
                getNodeKey={({ node: { id } }) => id}
                generateNodeProps={({ node, path }) => ({
                    buttons: [
                        (
                            <Button
                                icon="edit"
                                className="list-action-button-edit"
                                onClick={node.isGroup ?
                                    setEditGroupMode.bind(null, node.id) :
                                    setEditMode.bind(null, node.id)
                                }
                                size="small"
                                type="link"
                            />
                        ),
                        (
                            <Popconfirm
                                cancelText="non"
                                okText="oui"
                                onConfirm={node.isGroup ?
                                    onDeleteGroup.bind(null, node.id) :
                                    onDelete.bind(null, node.id)
                                }
                                title={`Voulez-vous vraiment supprimer ${node.isGroup ? 'ce groupe' : 'cette propriété'} ?`}
                            >
                                <Button
                                    loading={
                                        node.isGroup ?
                                            deletesGroupState.loading :
                                            deletesState.loading
                                    }
                                    size="small"
                                    style={{ color: '#e20714' }}
                                    type="link"
                                >
                                    <IconTrash />
                                </Button>
                            </Popconfirm>
                        ),
                    ],
                })}
                onMoveNode={onDropNode}
            />
            <PropertyFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
            <PropertyGroupFormDrawer
                id={selectedGroupId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isGroupFormVisible}
                onClose={onGroupDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    changeOrderState: getChangePropertyOrderState(state),
    propertiesState: getPropertiesState(state),
    groupsState: getPropertyGroupsState(state),
    updateState: getUpdatePropertyState(state),
    deletesState: getDeletePropertyState(state),
    deletesGroupState: getDeletePropertyGroupState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteProperty: PropertiesActions.del,
        deleteGroup: PropertiesActions.delGroup,
        list: PropertiesActions.list,
        listGroups: PropertiesActions.listGroup,
        changeOrder: PropertiesActions.changeOrder,
        update: PropertiesActions.update,
    },
)(Properties);
