import { call, delay, takeLatest, put } from 'redux-saga/effects';
import * as actions from '../actions/countries';
import * as reduxTypes from '../types/countries';
import * as api from '../api/countries';
import { Action } from 'redux';
import { SearchPaginationQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Country } from '../api/apiTypes';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSelectSuccess(response));
    } catch (error) {
        return yield put(actions.listSelectFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Country['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(actions.detailsSuccess(response));
    } catch (error) {
        return yield put(actions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Country['id']> & DataAction<Partial<Country>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export default function* countriesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
