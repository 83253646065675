import React, { FC, useState, forwardRef } from 'react';
import { Icon, Upload, message } from 'antd';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

import api from '../store/api/_client';
import { File as APIFile } from '../store/api/apiTypes';

const customDeleteFileRequest = async (id: any) =>
    api.delete(`/api/files/${id}`).then(
        () => true,
        () => false,
    );

export interface FileUploadProps {
    fileSize?: number;
    fileType?: string;
    hint?: string;
    initialValue?: Array<UploadFile<APIFile>>;
    isEditing: boolean;
    listType?: UploadProps['listType'];
    multiple?: boolean;
    onChange?: (file: File | Blob | undefined | Array<File | Blob | undefined>, removed?: boolean) => void;
    onUploadSuccess?: (response: object, file: File) => void;
    onDeleteSuccess?: () => void;
    uploadPayload?: Partial<APIFile>;
}

const FileUpload: FC<FileUploadProps> = ({
    fileSize, fileType, hint, initialValue, isEditing, listType = 'picture', multiple = false,
    onChange, onDeleteSuccess, onUploadSuccess, uploadPayload,
}) => {
    const [fileList, setFileList] = useState<Array<UploadFile<APIFile>>>(initialValue || []);
    const [error, setError] = useState<string>();

    const customUploadRequest = (options: any) => {
        const { onError, onProgress, onSuccess, file } = options;

        if (isEditing) {
            const formData = new FormData();

            formData.append('file', file);

            if (uploadPayload) {
                Object.keys(uploadPayload).forEach((payloadKey) => {
                    formData.append(payloadKey, uploadPayload[payloadKey]);
                });
            }

            api.post('/api/files', formData, {
                onUploadProgress: ({ total, loaded }) => {
                    onProgress({ percent: Math.round(loaded / total * 100) }, file);
                },
            }).then(
                (response: any) => {
                    onSuccess(response, {
                        ...file,
                        url: response.url,
                        thumbUrl: response.thumbnail ? response.thumbnail.url : undefined,
                    });
                    if (typeof onUploadSuccess === 'function') {
                        onUploadSuccess(response, file);
                    }
                },
            ).catch(onError);
        } else {
            window.setTimeout(() => {
                onProgress({ percent: 100 }, file);
                onSuccess({}, file);
            }, 100);
        }

        return {
            abort() {
                console.log('upload progress is aborted.');
            },
        };
    };

    const onFileChange: UploadProps['onChange'] = ({ file, fileList: fList }) => {
        if (file.status === 'done') {
            fList = fList.map((item) => {
                item.url = item.response ? item.response.url : undefined;
                return item;
            });
        }

        if (file.status === 'done' || file.status === 'removed') {
            if (typeof onChange === 'function') {
                if (multiple) {
                    onChange(fList.map((f) => f.originFileObj), file.status === 'removed');
                } else {
                    onChange(file.originFileObj, file.status === 'removed');
                }
            }
        }

        if (file.status) {
            setFileList(file.status ? [...fList] : fList);
        }
    };

    const onRemoveFile: UploadProps['onRemove'] = async (file) => {
        if (isEditing) {
            if (file.response) {
                const response = await customDeleteFileRequest(file.response.id);
                if (response) {
                    setFileList([...fileList].filter((f) => f.uid !== file.uid));
                    if (typeof onDeleteSuccess === 'function') {
                        onDeleteSuccess();
                    }
                } else {
                    message.error('Une erreur est survenue lors de la suppression du fichier');
                }
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const beforeUpload: UploadProps['beforeUpload'] = (file) => {
        setError(undefined);

        if (fileType && file.type !== fileType) {
            setError(`Le fichier n'a pas le bon type (${fileType})`);

            return false;
        }

        if (fileSize !== undefined && file.size / 1024 / 1024 > fileSize / 100) {
            setError(`Le fichier est trop lourd (max ${fileSize}ko)`);

            return false;
        }

        if (multiple) {
            setFileList([...fileList, file]);
        } else {
            setFileList([file]);
        }

        return true;
    };

    return (
        <div className={`file-upload-wrapper${!multiple && fileList.length ? ' hide-area' : ''}`}>
            <Upload.Dragger
                beforeUpload={beforeUpload}
                customRequest={customUploadRequest}
                defaultFileList={initialValue}
                fileList={fileList}
                listType={listType}
                onChange={onFileChange}
                onRemove={onRemoveFile}
            >
                {((!multiple && !fileList.length) || multiple) && (
                    <>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">
                            Cliquez ici ou déposez un fichier
                        </p>
                        {!!hint && (
                            <p className="ant-upload-hint">{hint}</p>
                        )}
                    </>
                )}
                {error && (
                    <div className="has-error">
                        <p className="ant-form-explain">{error}</p>
                    </div>
                )}
            </Upload.Dragger>
        </div>
    );
};

export default forwardRef(FileUpload);
