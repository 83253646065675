import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';

import * as CountriesActions from '../../store/actions/countries';
import * as SectorsActions from '../../store/actions/sectors';
import {
    SectorsState, getSectorDetailsState, getUpdateSectorState, getCreateSectorState, getSectorsState,
    getSectorsSelectState,
} from '../../store/reducers/sectors';
import { RequestState, MainReducerState } from '../../store/reducers';
import { Sector, SectorListItem } from '../../store/api/apiTypes';
import { getCountriesSelectState, CountriesState } from '../../store/reducers/countries';

import { t } from '../../utils';
import { solutionIconsMap } from '.';
import PieChart from '../../components/PieChart';
import MultiLangInput from '../../components/MultiLangInput';

interface SolutionFormDrawerProps extends FormComponentProps {
    countriesSelectState: CountriesState['listSelect'];
    create: typeof SectorsActions.create;
    creates: RequestState;
    details: SectorsState['details'];
    getDetails: typeof SectorsActions.details;
    id?: Sector['id'];
    isVisible: boolean;
    listSelectCountries: typeof CountriesActions.listSelect;
    listSelectSectors: typeof SectorsActions.listSelect;
    listAncestorSectors: typeof SectorsActions.list;
    onClose: () => void;
    onUpdateSuccess: () => void;
    sectorsSelectState: SectorsState['listSelect'];
    ancestorSectors: SectorsState['list'];
    update: typeof SectorsActions.update;
    updates: RequestState;
}

const Solution: FC<SolutionFormDrawerProps> = ({
    ancestorSectors, countriesSelectState, create, creates, details, getDetails, form, id, isVisible,
    listAncestorSectors, listSelectCountries, listSelectSectors, onClose, onUpdateSuccess,
    sectorsSelectState, update, updates,

}) => {
    const isEditing = id !== undefined;

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }
            listAncestorSectors({ sectorType: ['market', 'sector', 'subSector'], limit: 20 });
            listSelectSectors({ sectorType: ['subSector', 'sector'], limit: 20 });
            listSelectCountries({ limit: 20 });

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: ['subSector', 'sector'],
        });
    };

    const onAncestorSectorSearch: SelectProps['onSearch'] = (value) => {
        listAncestorSectors({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: ['market', 'sector', 'subSector'],
        });
    };

    const onCountriesSearch: SelectProps['onSearch'] = (value) => {
        listSelectCountries({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                    icon: val.icon || null,
                });
            } else {
                create({
                    ...val,
                    icon: val.icon || null,
                    type: 'solution',
                });
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'un besoin & solution'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom">
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom du marché, secteur ou besoin" />,
                                    )}
                                </MultiLangInput>
                                <Form.Item label="Parents">
                                    {getFieldDecorator('sectorParents', {
                                        initialValue: isEditing && details.data ?
                                            details.data.sectorParents.map((s: Sector) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={sectorsSelectState.loading}
                                            onSearch={onSectorSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs secteurs"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.sectorParents.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...sectorsSelectState.data]) :
                                                sectorsSelectState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Exclusions">
                                    {getFieldDecorator('excludedSectors', {
                                        initialValue: isEditing && details.data ?
                                            details.data.excludedSectors.map((s: Sector) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={ancestorSectors.loading}
                                            onSearch={onAncestorSectorSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs marchés et/ou secteurs"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.excludedSectors.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...ancestorSectors.data]) :
                                                ancestorSectors.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Pays rattachés">
                                    {getFieldDecorator('countries', {
                                        initialValue: isEditing && details.data && details.data.countries ?
                                            details.data.countries.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            filterOption={false}
                                            loading={countriesSelectState.loading}
                                            onSearch={onCountriesSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs pays"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.countries.reduce((acc, country) => {
                                                    if (!acc.find((s) => s.id === country.id)) {
                                                        acc.unshift(country);
                                                    }
                                                    return acc;
                                                }, [...countriesSelectState.data]) :
                                                countriesSelectState.data
                                            ).map((country) => (
                                                <Select.Option
                                                    key={country.id}
                                                    value={country.id}
                                                >
                                                    {t(country.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Couleur" extra="Couleur de thème (ex: Pest control)">
                                    {getFieldDecorator('color', {
                                        rules: [{
                                            pattern: new RegExp(/^#(?:[0-9a-f]{3}){1,2}$/i),
                                            message: 'La couleur doit être au format hexadécimal. ex: #000000',
                                        }],
                                        validateTrigger: 'onBlur',
                                        initialValue: isEditing && details.data ?
                                            details.data.color :
                                            null,
                                    })((
                                        <Input
                                            placeholder="Code hexadécimal"
                                            suffix={(
                                                <PieChart
                                                    data={[form.getFieldValue('color')]}
                                                    size={16}
                                                />
                                            )}
                                        />
                                    ))}
                                </Form.Item>
                                <Form.Item label="Icône">
                                    {getFieldDecorator('icon', {
                                        validateTrigger: 'onBlur',
                                        initialValue: isEditing && details.data ?
                                            details.data.icon :
                                            null,
                                    })((
                                        <Select
                                            allowClear={true}
                                            className="icon-select"
                                            dropdownClassName="icon-select-dropdown"
                                            filterOption={false}
                                            placeholder="choisir une icône"
                                        >
                                            {Object.keys(solutionIconsMap).map((icon) => {
                                                const Component = solutionIconsMap[icon];

                                                return (
                                                    <Select.Option
                                                        key={icon}
                                                        value={icon}
                                                    >
                                                        <Component />
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    ancestorSectors: getSectorsState(state),
    countriesSelectState: getCountriesSelectState(state),
    creates: getCreateSectorState(state),
    details: getSectorDetailsState(state),
    sectorsSelectState: getSectorsSelectState(state),
    updates: getUpdateSectorState(state),
});

const SolutionFormDrawer = Form.create<SolutionFormDrawerProps>()(Solution);

export default connect(
    mapStateToProps,
    {
        create: SectorsActions.create,
        listSelectCountries: CountriesActions.listSelect,
        listSelectSectors: SectorsActions.listSelect,
        listAncestorSectors: SectorsActions.list,
        getDetails: SectorsActions.details,
        update: SectorsActions.update,
    },
)(SolutionFormDrawer);
