import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Avatar, Dropdown, Menu, Button } from 'antd';

import * as AuthActions from '../store/actions/auth';

import { getRoute, RoutePathName } from '../routes';
import { IconLogo } from './icons';
import { MainReducerState } from '../store/reducers';
import { getUser } from '../store/reducers/auth';
import { UserDetails } from '../store/api/apiTypes';
import { getFullName } from '../utils';
import EditProfileDrawer from '../pages/EditProfileDrawer';

interface HeaderProps {
    logout: typeof AuthActions.logout;
    currentUser?: UserDetails;
}

const Header: FC<HeaderProps> = ({ currentUser, logout }) => {
    const [isEditProfileDrawerVisible, setIsEditProfileDrawerVisible] = useState(false);
    const onCloseEditProfileDrawer = () => { setIsEditProfileDrawerVisible(false); };
    const onClickEditProfile = () => { setIsEditProfileDrawerVisible(true); };

    return (
        <Layout.Header id="main-header">
            <div id="logo-nav-wrapper">
                <Link to={getRoute(RoutePathName.dashboard)}>
                    <IconLogo />
                </Link>
            </div>
            {currentUser && (
                <div>
                    <Dropdown
                        overlay={(
                            <Menu>
                                <Menu.Item key="0" onClick={onClickEditProfile}>
                                    Modifier mon profil
                                </Menu.Item>
                                <Menu.Item key="1" onClick={logout}>
                                    Déconnexion
                                </Menu.Item>
                            </Menu>
                        )}
                        trigger={['click']}
                    >
                        <Button type="link">
                            <Avatar className="user-avatar">
                                {currentUser.firstName[0].toUpperCase()}
                            </Avatar>
                            {getFullName(currentUser.firstName, currentUser.lastName)}
                        </Button>
                    </Dropdown>
                </div>
            )}
            <EditProfileDrawer
                onClose={onCloseEditProfileDrawer}
                visible={isEditProfileDrawerVisible}
            />
        </Layout.Header>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentUser: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        logout: AuthActions.logout,
    },
)(Header);
