import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, Switch, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';
// tslint:disable-next-line: no-submodule-imports
import cloneDeep from 'lodash/cloneDeep';

import * as CountriesActions from '../../store/actions/countries';
import * as ProductsActions from '../../store/actions/products';
import * as SectorsActions from '../../store/actions/sectors';
import {
    getSectorsSelectState, SectorsState, getSectorAssociatedProductsGroupsState,
    getSectorCrossSellingState, getSectorDetailsWithFullPropertiesState,
} from '../../store/reducers/sectors';
import {
    ProductsState, getProductDetailsState, getUpdateProductState, getCreateProductState,
    getProductsSelectState,
} from '../../store/reducers/products';
import { MainReducerState } from '../../store/reducers';
import {
    Product, SectorListItem, CrossSelling, Sector, ProductArgumentValue, ProductGroup, ProductPropertyValue, PropertyValue, Property,
} from '../../store/api/apiTypes';
import { CountriesState, getCountriesSelectState } from '../../store/reducers/countries';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import { CrossSellingFields, FilterFields } from './';
import FileUpload from '../../components/FileUpload';
import AssociatedProductsFields from './AssociatedProductsFields';
import { usePrevious } from '../../hooks';

export interface PropertyValuesByPropertyId {
    [id: number]: Array<Partial<ProductPropertyValue>>;
}

export interface AssociatedProductGroupField {
    id?: number;
    associatedProductGroup: Partial<ProductGroup>;
    associatedProducts: Array<Partial<Product>>;
}

interface ProductDrawerProps extends FormComponentProps {
    countriesSelectState: CountriesState['listSelect'];
    create: typeof ProductsActions.create;
    detailsReset: typeof ProductsActions.detailsReset;
    creates: ProductsState['create'];
    details: ProductsState['details'];
    getDetails: typeof ProductsActions.details;
    getSectorDetailsWithProperties: typeof SectorsActions.detailsWithFullProperties;
    id?: Product['id'];
    isVisible: boolean;
    listSectorAssociatedProductsGroups: typeof SectorsActions.listAssociatedProductsGroups;
    listSectorCrossSelling: typeof SectorsActions.listCrossSelling;
    listSelectCountries: typeof CountriesActions.listSelect;
    listSelectProducts: typeof ProductsActions.listSelect;
    listSelectSectors: typeof SectorsActions.listSelect;
    onClose: () => void;
    onUpdateSuccess: () => void;
    productsSelectState: ProductsState['listSelect'];
    sectorAssociatedProductsGroups: SectorsState['listAssociatedProductsGroups'];
    sectorCrossSelling: SectorsState['listCrossSelling'];
    sectorDetailsWithFullProperties: SectorsState['detailsWithFullProperties'];
    sectorDetailsWithFullPropertiesReset: typeof SectorsActions.detailsWithFullPropertiesReset;
    sectorsSelectState: SectorsState['listSelect'];
    update: typeof ProductsActions.update;
    updates: ProductsState['update'];
}

const ProductDrawer: FC<ProductDrawerProps> = ({
    create, creates, countriesSelectState, details, detailsReset, getDetails,
    getSectorDetailsWithProperties, form, id, isVisible, listSectorAssociatedProductsGroups,
    listSectorCrossSelling, listSelectCountries, listSelectSectors, listSelectProducts, onClose,
    onUpdateSuccess, productsSelectState, sectorAssociatedProductsGroups, sectorCrossSelling,
    sectorDetailsWithFullProperties, sectorDetailsWithFullPropertiesReset, sectorsSelectState,
    update, updates,
}) => {
    const { getFieldDecorator } = form;
    const isEditing = id !== undefined;
    const previous = usePrevious({ sectorDetailsWithFullProperties });
    const [tab, setTab] = useState('infos');
    const [crossSelling, setCrossSelling] = useState<Array<Partial<CrossSelling>>>(
        isEditing && details.data ?
            cloneDeep(details.data.crossSelling) :
            [],
    );
    const [associatedProductGroups, setAssociatedProductGroups] = useState<AssociatedProductGroupField[]>(
        isEditing && details.data ?
            cloneDeep(details.data.associatedProductGroups) :
            [],
    );
    const [associatedProductsFromSector, setAssociatedProductsFromSector] = useState<Array<Partial<Product>>>();
    const [argumentValues, setArgumentValues] = useState<Array<Partial<ProductArgumentValue>>>([]);
    const [propertyValuesByPropertyId, setPropertyValuesByPropertyId] = useState<PropertyValuesByPropertyId>(
        isEditing && details.data ?
            details.data.properties.reduce<PropertyValuesByPropertyId>((acc, propertyValue) => {
                if (acc[propertyValue.property.id]) {
                    acc[propertyValue.property.id].push(propertyValue);
                } else {
                    acc[propertyValue.property.id] = [propertyValue];
                }
                return acc;
            }, {}) :
            {},
    );
    const sectorsSelectData = sectorsSelectState.data.filter((s) => s.type !== 'sector' && s.type !== 'subSector');
    const sectorsSelectDataBestSeller = sectorsSelectState.data.filter((s) => s.type === 'sector' || s.type === 'subSector');

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            setCrossSelling([]);
            setArgumentValues([]);
            setAssociatedProductGroups([]);
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            previous && previous.sectorDetailsWithFullProperties.loading &&
            !sectorDetailsWithFullProperties.loading && !sectorDetailsWithFullProperties.error
        ) {
            setArgumentValues(
                sectorDetailsWithFullProperties.data.arguments.map((argument) => ({
                    argument,
                    argumentId: argument.id,
                    rating: 1,
                    texts: details.data ?
                        ((details.data.arguments.find((arg) => arg.argument.id === argument.id) || {}).texts || []) :
                        [],
                }),
            ));
        }
    }, [
        previous, sectorDetailsWithFullProperties.loading, sectorDetailsWithFullProperties.error,
        setArgumentValues, sectorDetailsWithFullProperties.data, details.data,
    ]);

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-content');
            if (drawerBody) {
                drawerBody.scroll({
                    top: 0,
                });
            }
            listSelectCountries({ limit: 20 });
            listSelectProducts({ limit: 20 });
            listSelectSectors({
                sectorType: ['sector', 'subSector', 'solution', 'gamme', 'service', 'subGamme', 'subService'],
                limit: 20,
            });

            if (isEditing) {
                getDetails(id, false);
            } else {
                setAssociatedProductsFromSector(undefined);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const editPopulateEffectDependency = details.data && details.data.id;
    useEffect(() => {
        if (isEditing && details.data && !details.loading) {
            getSectorDetailsWithProperties(details.data.sectorId);
            setCrossSelling(cloneDeep(details.data.crossSelling));
            setArgumentValues(cloneDeep(details.data.arguments).map((argumentValue) => ({
                ...argumentValue,
                argumentId: argumentValue.argument.id,
            })));
            setPropertyValuesByPropertyId(
                details.data.properties.reduce<PropertyValuesByPropertyId>((acc, propertyValue) => {
                    if (acc[propertyValue.property.id]) {
                        acc[propertyValue.property.id].push(propertyValue);
                    } else {
                        acc[propertyValue.property.id] = [propertyValue];
                    }
                    return acc;
                }, {}),
            );
            setAssociatedProductGroups(cloneDeep(details.data.associatedProductGroups));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPopulateEffectDependency, setCrossSelling, details.loading]);

    useEffect(() => {
        if (
            !isEditing &&
            !sectorAssociatedProductsGroups.loading &&
            sectorAssociatedProductsGroups.data &&
            sectorAssociatedProductsGroups.data.length
        ) {
            setAssociatedProductGroups(sectorAssociatedProductsGroups.data.filter((group) =>
                group.associatedProductGroup.id !== 1,
            ));

            const newAssociatedProductsFromSector = sectorAssociatedProductsGroups.data.find(
                (group) => group.associatedProductGroup.id === 1,
            );

            setAssociatedProductsFromSector(
                newAssociatedProductsFromSector ?
                    newAssociatedProductsFromSector.associatedProducts :
                    undefined,
            );

            if (newAssociatedProductsFromSector) {
                form.setFieldsValue({
                    associatedProducts: newAssociatedProductsFromSector.associatedProducts.map((p) => p.id),
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectorAssociatedProductsGroups.loading]);

    useEffect(() => {
        if (
            !isEditing &&
            !sectorCrossSelling.loading &&
            sectorCrossSelling.data &&
            sectorCrossSelling.data.length
        ) {
            setCrossSelling(sectorCrossSelling.data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectorCrossSelling.loading]);

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
        setCrossSelling([]);
        setArgumentValues([]);
        setAssociatedProductGroups([]);
        setAssociatedProductsFromSector(undefined);
        detailsReset();
        sectorDetailsWithFullPropertiesReset();
        setTab('infos');
    };

    const onChangeSector: SelectProps['onChange'] = (value) => {
        const newSector = sectorsSelectState.data.find((s) => s.id === value)!;
        getSectorDetailsWithProperties(newSector.id);

        if (!isEditing) {
            listSectorAssociatedProductsGroups(newSector.id);
            listSectorCrossSelling(newSector.id);
        }
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: ['sector', 'subSector', 'solution', 'gamme', 'service', 'subGamme', 'subService'],
        });
    };

    const onProductSearch: SelectProps['onSearch'] = (value) => {
        listSelectProducts({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onCountriesSearch: SelectProps['onSearch'] = (value) => {
        listSelectCountries({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                if (Object.keys(err).includes('label') || Object.keys(err).includes('sectorId')) {
                    setTab('infos');
                }
                return;
            }

            const payload = {
                ...val,
                crossSelling: crossSelling.map((c) => ({
                    ...c,
                    products: c.products ? c.products.map((p) => p.id) : null,
                })),
                arguments: argumentValues.length ? argumentValues : [],
                propertyValues: Object.values(propertyValuesByPropertyId).flat().map((v) => v.id) || [],
                allAssociatedProductGroups: [
                    {
                        associatedProductGroupId: 1,
                        associatedProducts: val.associatedProducts,
                    },
                    ...associatedProductGroups.map((associatedProductGroup) => ({
                        id: associatedProductGroup.id,
                        associatedProductGroupId: associatedProductGroup.associatedProductGroup.id,
                        associatedProducts: associatedProductGroup.associatedProducts.map((p) => p.id),
                    })),
                ],
            };
            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...payload,
                });
            } else {
                create(payload);
            }
        });
    };

    const onChangeCrossSellingSectorId = (index: number, sectorId: Sector['id']) => {
        const vals = [...crossSelling];
        vals[index].sectorId = sectorId;
        setCrossSelling(vals);
    };

    const onChangeCrossSellingProducts = (index: number, products: Array<Product['id']>) => {
        const vals = [...crossSelling];
        vals[index].products = productsSelectState.data.filter((product) => {
            return products.some((productId) => productId === product.id);
        });
        setCrossSelling(vals);
    };

    const onChangeProductGroupProducts = (index: number, products: Array<Product['id']>) => {
        const vals = [...associatedProductGroups];
        vals[index].associatedProducts = productsSelectState.data.filter((product) => {
            return products.some((productId) => productId === product.id);
        });
        setAssociatedProductGroups(vals);
    };

    const onChangeProductGroup = (
        index: number,
        group: Partial<ProductGroup>,
    ) => {
        const vals = [...associatedProductGroups];
        vals[index].associatedProductGroup = group;
        setAssociatedProductGroups(vals);
    };

    const onChangeArgumentRating = (index: number, argumentRating: number | undefined) => {
        const vals = [...argumentValues];
        vals[index].rating = argumentRating;
        setArgumentValues(vals);
    };

    const onChangePropertyValue = (propertyId: Property['id'], values: Array<ProductPropertyValue['id']>) => {
        const propertyValuesFromSector = sectorDetailsWithFullProperties.data.properties
            .reduce<PropertyValue[]>((acc, property) => [...acc, ...property.values!], []);
        const propertyValuesSource = isEditing && details.data ?
            details.data.properties.reduce<Array<Partial<ProductPropertyValue>>>((acc, propertyValue) => {
                if (acc.find((p) => p.id === propertyValue.id)) {
                    acc.push({
                        id: propertyValue.id,
                        color: propertyValue.color,
                        name: propertyValue.name,
                    });
                }
                return acc;
            }, propertyValuesFromSector) :
            propertyValuesFromSector;
        setPropertyValuesByPropertyId({
            ...propertyValuesByPropertyId,
            [propertyId]: values.map((value) => propertyValuesSource.find((val) => val.id === value)!),
        });
    };

    const onChangeArgumentTextValue = (
        argumentIndex: number,
        index: number,
        lang: string,
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const vals = [...argumentValues];
        if (vals[argumentIndex].texts && vals[argumentIndex].texts![index] && vals[argumentIndex].texts![index].text) {
            vals[argumentIndex].texts![index].text![lang] = e.target.value;
        } else {
            vals[argumentIndex].texts = vals[argumentIndex].texts || [];
            vals[argumentIndex].texts![index] = vals[argumentIndex].texts![index] || { text: undefined };
            vals[argumentIndex].texts![index].text = {
                ...vals[argumentIndex].texts![index].text,
                [lang]: e.target.value,
            };
        }
        setArgumentValues(vals);
    };

    const addCrossSellingValue = () => {
        setCrossSelling([...cloneDeep(crossSelling), {}]);
    };

    const removeCrossSellingValue = (valueIndex: number) => {
        const vals = cloneDeep(crossSelling);
        vals.splice(valueIndex, 1);

        setCrossSelling(vals);
    };

    const addAssociatedProductGroup = () => {
        setAssociatedProductGroups([...cloneDeep(associatedProductGroups), {
            associatedProductGroup: {},
            associatedProducts: [],
        }]);
    };

    const removeAssociatedProductGroup = (groupIndex: number) => {
        const vals = cloneDeep(associatedProductGroups);
        vals.splice(groupIndex, 1);

        setAssociatedProductGroups(vals);
    };

    const addArgumentTextValue = (argumentIndex: number) => {
        const vals = cloneDeep(argumentValues);
        vals[argumentIndex].texts = [
            ...(vals[argumentIndex].texts || []),
            {},
        ];
        setArgumentValues(vals);
    };

    const removeArgumentTextValue = (argumentIndex: number, textIndex: number) => {
        const vals = cloneDeep(argumentValues);
        vals[argumentIndex].texts!.splice(textIndex, 1);

        setArgumentValues(vals);
    };

    const onChangeImages = (files: File | Blob | Array<File | Blob | undefined> | undefined) => {
        form.setFieldsValue({ imagesToUpload: files });
    };

    let error = creates.error || updates.error ?
        'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur' :
        null;

    if (
        (creates.error && creates.error.status && creates.error.status > 399 && creates.error.status < 500) ||
        (updates.error && updates.error.status && updates.error.status > 399 && updates.error.status < 500)
    ) {
        error = 'Veuillez vérifier les informations du formulaire.';
    }

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.label) : ''}` :
                        'Création d\'un produit'
                    }
                    width={720}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Form onSubmit={onSubmit} layout="vertical">
                            <Tabs size="small" className="form-tabs" onChange={setTab} activeKey={tab}>
                                <Tabs.TabPane
                                    tab="Informations"
                                    key="infos"
                                >
                                    <>
                                        <MultiLangInput label="Nom" required>
                                            {(lang) => getFieldDecorator(`label[${lang}]`, {
                                                rules: [{
                                                    required: lang === 'fr',
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && details.data && details.data.label ?
                                                    details.data.label[lang] :
                                                    undefined,
                                            })(
                                                <Input placeholder="Nom du produit" />,
                                            )}
                                        </MultiLangInput>
                                        <Form.Item label="Actif" extra="visible sur le catalogue">
                                            {getFieldDecorator('isEnabled', {
                                                valuePropName: 'checked',
                                                initialValue: isEditing && details.data ?
                                                    details.data.isEnabled :
                                                    false,
                                            })((
                                                <Switch />
                                            ))}
                                        </Form.Item>
                                        <div style={{ position: 'relative' }} id="hierarchi">
                                            <Form.Item label="Élément de hiérarchie associé" >
                                                {getFieldDecorator('sectorId', {
                                                    rules: [{
                                                        required: true,
                                                        message: 'champ requis',
                                                    }],
                                                    initialValue: isEditing && details.data ?
                                                        details.data.sectorId :
                                                        undefined,
                                                })((
                                                    <Select
                                                        allowClear={true}
                                                        filterOption={false}
                                                        loading={sectorsSelectState.loading}
                                                        // tslint:disable-next-line:jsx-no-lambda
                                                        getPopupContainer={() => document.getElementById('hierarchi') as HTMLInputElement}
                                                        onChange={onChangeSector}
                                                        onSearch={onSectorSearch}
                                                        placeholder="Rechercher et choisir un élément de hiérarchie"
                                                        showSearch
                                                    >
                                                        {(isEditing && details.data && details.data.sector ?
                                                            [details.data.sector].reduce((acc, sector) => {
                                                                if (!acc.find((s) => s.id === sector.id)) {
                                                                    acc.unshift(sector as SectorListItem);
                                                                }
                                                                return acc;
                                                            }, [...sectorsSelectData]) :
                                                            sectorsSelectData
                                                        ).map((sector) => (
                                                            <Select.Option
                                                                key={sector.id}
                                                                value={sector.id}
                                                            >
                                                                {t(sector.name)}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                ))}
                                            </Form.Item>
                                        </div>
                                        <div style={{ position: 'relative' }} id="Pays">
                                        <Form.Item label="Pays rattachés">
                                            {getFieldDecorator('countries', {
                                                initialValue: isEditing && details.data && details.data.countries ?
                                                    details.data.countries.map((s) => s.id) :
                                                    [],
                                            })((
                                                <Select
                                                    filterOption={false}
                                                    loading={countriesSelectState.loading}
                                                    // tslint:disable-next-line:jsx-no-lambda
                                                    getPopupContainer={() => document.getElementById('Pays') as HTMLInputElement}
                                                    onSearch={onCountriesSearch}
                                                    mode="multiple"
                                                    placeholder="Rechercher et choisir un ou plusieurs pays"
                                                    showSearch
                                                >
                                                    {(details.data && details.data.countries ?
                                                        details.data.countries.reduce((acc, country) => {
                                                            if (!acc.find((s) => s.id === country.id)) {
                                                                acc.unshift(country);
                                                            }
                                                            return acc;
                                                        }, [...countriesSelectState.data]) :
                                                        countriesSelectState.data
                                                    ).map((country) => (
                                                        <Select.Option
                                                            key={country.id}
                                                            value={country.id}
                                                        >
                                                            {t(country.name)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ))}
                                        </Form.Item>
                                        </div>
                                        <Form.Item label="Référence">
                                            {getFieldDecorator('reference', {
                                                initialValue: isEditing && details.data ?
                                                    details.data.reference :
                                                    null,
                                            })(
                                                <Input placeholder="Code référence" />,
                                            )}
                                        </Form.Item>
                                        <Form.Item label="PIM">
                                            {getFieldDecorator('pim', {
                                                initialValue: isEditing && details.data ?
                                                    details.data.pim :
                                                    null,
                                            })(
                                                <Input placeholder="PIM" />,
                                            )}
                                        </Form.Item>
                                        {isVisible && (
                                            <Form.Item label="Images">
                                                {getFieldDecorator('imagesToUpload', {
                                                    initialValue: isEditing && details.data ?
                                                        details.data.images :
                                                        false,
                                                })((
                                                    <FileUpload
                                                        fileType="image/jpeg"
                                                        fileSize={100}
                                                        hint="fichier jpeg de 100ko maximum et de 580x580 pixels"
                                                        initialValue={isEditing && details.data && details.data.images ?
                                                            details.data.images.map((image, index) => ({
                                                                uid: `${index + 1}`,
                                                                size: 0,
                                                                name: image.filename,
                                                                status: 'done',
                                                                type: 'image/jpeg',
                                                                response: image,
                                                                url: image.url,
                                                            })) :
                                                            undefined
                                                        }
                                                        isEditing={isEditing}
                                                        listType="picture-card"
                                                        onChange={onChangeImages}
                                                        onDeleteSuccess={onUpdateSuccess}
                                                        onUploadSuccess={onUpdateSuccess}
                                                        uploadPayload={{
                                                            productId: details.data ? details.data.id : -1,
                                                        }}
                                                        multiple
                                                    />
                                                ))}
                                            </Form.Item>
                                        )}
                                        <Form.Item label="Nouveauté">
                                            {getFieldDecorator('isNew', {
                                                valuePropName: 'checked',
                                                initialValue: isEditing && details.data ?
                                                    details.data.isNew :
                                                    false,
                                            })((
                                                <Switch />
                                            ))}
                                        </Form.Item>
                                        <div style={{ position: 'relative' }} id="seller">
                                        <Form.Item label="Best seller" extra="Choisissez les secteurs dans lesquels le produit est un best seller">
                                            {getFieldDecorator('bestSellers', {
                                                initialValue: isEditing && details.data && details.data.bestSellers ?
                                                    details.data.bestSellers.map((s) => s.id) :
                                                    [],
                                            })((
                                                <Select
                                                    allowClear={true}
                                                    filterOption={false}
                                                    loading={sectorsSelectState.loading}
                                                    onSearch={onSectorSearch}
                                                    // tslint:disable-next-line:jsx-no-lambda
                                                    getPopupContainer={() => document.getElementById('seller') as HTMLInputElement}
                                                    mode="multiple"
                                                    placeholder="Rechercher et choisir un ou plusieurs secteurs"
                                                    showSearch
                                                >
                                                    {(details.data ?
                                                        details.data.bestSellers.reduce((acc, sector) => {
                                                            if (!acc.find((s) => s.id === sector.id)) {
                                                                acc.unshift(sector as SectorListItem);
                                                            }
                                                            return acc;
                                                        }, [...sectorsSelectDataBestSeller]) :
                                                        sectorsSelectDataBestSeller
                                                    ).map((sector) => (
                                                        <Select.Option
                                                            key={sector.id}
                                                            value={sector.id}
                                                        >
                                                            {t(sector.name)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ))}
                                        </Form.Item>
                                        </div>
                                    </>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab="Cross selling"
                                    key="crossSelling"
                                >
                                    <CrossSellingFields
                                        isEditing={isEditing}
                                        values={crossSelling}
                                        sectorsSelectState={sectorsSelectState}
                                        onSectorSearch={onSectorSearch}
                                        onChangeSectorId={onChangeCrossSellingSectorId}
                                        onChangeProducts={onChangeCrossSellingProducts}
                                        removeValue={removeCrossSellingValue}
                                        addValue={addCrossSellingValue}
                                        productsSelectState={productsSelectState}
                                        onProductSearch={onProductSearch}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab="Filtres & Bénéfices"
                                    key="filters"
                                >
                                    <FilterFields
                                        details={details}
                                        isEditing={isEditing}
                                        argumentValues={argumentValues}
                                        onChangeArgumentRating={onChangeArgumentRating}
                                        onChangeArgumentTextValue={onChangeArgumentTextValue}
                                        removeTextValue={removeArgumentTextValue}
                                        addTextValue={addArgumentTextValue}
                                        propertyValuesByPropertyId={propertyValuesByPropertyId}
                                        onChangePropertyValue={onChangePropertyValue}
                                        sector={sectorDetailsWithFullProperties}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab="Produits associés"
                                    key="associatedProducts"
                                    forceRender
                                >
                                    <AssociatedProductsFields
                                        details={details}
                                        form={form}
                                        isEditing={isEditing}
                                        associatedProductGroups={associatedProductGroups}
                                        associatedProductsFromSector={associatedProductsFromSector}
                                        onChangeProductGroup={onChangeProductGroup}
                                        onChangeGroupProducts={onChangeProductGroupProducts}
                                        onProductSearch={onProductSearch}
                                        productsSelectState={productsSelectState}
                                        removeGroup={removeAssociatedProductGroup}
                                        addGroup={addAssociatedProductGroup}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                            {error ? (
                                <div className="login-error has-error">
                                    <span className="ant-form-explain">{error}</span>
                                </div>
                            ) : null}
                            <div className="form-actions">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    onClick={onSubmit}
                                    loading={updates.loading || creates.loading}
                                >
                                    {isEditing ? 'Mettre à jour' : 'Créer'}
                                </Button>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                        </Form>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreateProductState(state),
    countriesSelectState: getCountriesSelectState(state),
    details: getProductDetailsState(state),
    sectorAssociatedProductsGroups: getSectorAssociatedProductsGroupsState(state),
    sectorDetailsWithFullProperties: getSectorDetailsWithFullPropertiesState(state),
    sectorCrossSelling: getSectorCrossSellingState(state),
    sectorsSelectState: getSectorsSelectState(state),
    productsSelectState: getProductsSelectState(state),
    updates: getUpdateProductState(state),
});

const ProductFormDrawer = Form.create<ProductDrawerProps>()(ProductDrawer);

export default connect(
    mapStateToProps,
    {
        create: ProductsActions.create,
        listSectorAssociatedProductsGroups: SectorsActions.listAssociatedProductsGroups,
        listSectorCrossSelling: SectorsActions.listCrossSelling,
        listSelectCountries: CountriesActions.listSelect,
        listSelectProducts: ProductsActions.listSelect,
        listSelectSectors: SectorsActions.listSelect,
        getDetails: ProductsActions.details,
        getSectorDetailsWithProperties: SectorsActions.detailsWithFullProperties,
        update: ProductsActions.update,
        detailsReset: ProductsActions.detailsReset,
        sectorDetailsWithFullPropertiesReset: SectorsActions.detailsWithFullPropertiesReset,
    },
)(ProductFormDrawer);
