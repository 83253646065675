import React, { FC, useEffect } from 'react';
import { Icon, Button, Select } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { ButtonProps } from 'antd/lib/button';
import { connect } from 'react-redux';

import * as AssociatedProductGroupsActions from '../../store/actions/associatedProductGroups';
import { Product, ProductGroup } from '../../store/api/apiTypes';
import { ProductsState } from '../../store/reducers/products';
import { MainReducerState } from '../../store/reducers';
import { getAssociatedProductGroupsSelectState, AssociatedProductGroupsState } from '../../store/reducers/associatedProductGroups';

import { t } from '../../utils';
import { AssociatedProductGroupField } from './ProductFormDrawer';

interface AssociatedProductsFieldsProps {
    addGroup: ButtonProps['onClick'];
    associatedProductGroups: AssociatedProductGroupField[];
    associatedProductsFromSector?: Array<Partial<Product>>;
    associatedProductGroupsSelectState: AssociatedProductGroupsState['listSelect'];
    details: ProductsState['details'];
    form: WrappedFormUtils;
    isEditing: boolean;
    listSelectAssociatedProductGroups: typeof AssociatedProductGroupsActions.listSelect;
    onChangeProductGroup: (index: number, group: Partial<ProductGroup>) => void;
    onChangeGroupProducts: (index: number, productIds: Array<Product['id']>) => void;
    onProductSearch: SelectProps['onSearch'];
    productsSelectState: ProductsState['listSelect'];
    removeGroup: (valueIndex: number) => void;
}

const AssociatedProductsFields: FC<AssociatedProductsFieldsProps> = ({
    addGroup, associatedProductGroupsSelectState, associatedProductGroups,
    associatedProductsFromSector, details, form, isEditing, listSelectAssociatedProductGroups,
    onChangeProductGroup, onProductSearch, onChangeGroupProducts, productsSelectState, removeGroup,
}) => {
    useEffect(() => {
        listSelectAssociatedProductGroups({ limit: 20 });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onProductGroupSearch: SelectProps['onSearch'] = (value) => {
        listSelectAssociatedProductGroups({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onChangeGroup = (index: number, value: SelectValue) => {
        const group = associatedProductGroupsSelectState.data.find((g) => g.id === value);
        onChangeProductGroup(index, group || {});
    };

    return (
        <>
            <Form.Item label="Produits associés">
                {form.getFieldDecorator('associatedProducts', {
                    initialValue: isEditing && details.data && details.data.associatedProducts ?
                        details.data.associatedProducts.map((p) => p.id) :
                        [],
                })((
                    <Select
                        allowClear={true}
                        filterOption={false}
                        loading={productsSelectState.loading}
                        mode="multiple"
                        onSearch={onProductSearch}
                        placeholder="Rechercher et choisir un ou plusieurs produits"
                        showSearch
                    >
                        {(isEditing && details.data ?
                            details.data.associatedProducts.reduce((acc, product) => {
                                if (!acc.find((p) => p.id === product.id)) {
                                    acc.unshift(product);
                                }
                                return acc;
                            }, [...productsSelectState.data] as Array<Partial<Product>>) :
                            productsSelectState.data.reduce((acc, product) => {
                                if (!acc.find((p) => p.id === product.id)) {
                                    acc.unshift(product);
                                }
                                return acc;
                            }, associatedProductsFromSector || [])
                        ).map((product) => (
                            <Select.Option
                                key={product.id}
                                value={product.id}
                            >
                                {t(product.label)}
                            </Select.Option>
                        ))}
                    </Select>
                ))}
            </Form.Item>
            <div className="ant-col ant-form-item-label">
                <label title="Groupes de produits associés">Groupes de produits associés</label>
            </div>
            {associatedProductGroups.map((group, index: number) => (
                <div className="dynamic-item-wrapper" key={index}>
                    <Form.Item label="Groupe" required>
                        <Select
                            allowClear={true}
                            filterOption={false}
                            defaultValue={isEditing && group.associatedProductGroup ?
                                group.associatedProductGroup.id :
                                undefined
                            }
                            value={group.associatedProductGroup ?
                                group.associatedProductGroup.id :
                                undefined
                            }
                            loading={associatedProductGroupsSelectState.loading}
                            onSearch={onProductGroupSearch}
                            onChange={onChangeGroup.bind(null, index)}
                            placeholder="Rechercher et choisir un groupe"
                            showSearch
                        >
                            {(group.associatedProductGroup ?
                                [
                                    ...associatedProductGroupsSelectState.data.filter((g) =>
                                        g.id !== group.associatedProductGroup.id,
                                    ),
                                    group.associatedProductGroup,
                                ] :
                                associatedProductGroupsSelectState.data
                            ).map((g) => (
                                <Select.Option
                                    key={g.id}
                                    value={g.id}
                                >
                                    {t(g.name)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Produits" required>
                        <Select
                            allowClear={true}
                            filterOption={false}
                            defaultValue={isEditing && group.associatedProducts ?
                                group.associatedProducts.map((p) => p.id) as number[] :
                                []
                            }
                            value={group.associatedProducts ?
                                group.associatedProducts.map((product) => product.id) as number[] :
                                undefined
                            }
                            loading={productsSelectState.loading}
                            onSearch={onProductSearch}
                            onChange={onChangeGroupProducts.bind(null, index)}
                            mode="multiple"
                            placeholder="Rechercher et choisir un ou plusieurs produits"
                            showSearch
                        >
                            {(group.associatedProducts ?
                                group.associatedProducts.reduce((acc, product) => {
                                    if (!acc.find((p) => p.id === product.id)) {
                                        acc.unshift(product as Product);
                                    }
                                    return acc;
                                }, [...productsSelectState.data]) :
                                productsSelectState.data
                            ).map((product) => (
                                <Select.Option
                                    key={product.id}
                                    value={product.id}
                                >
                                    {t(product.label)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button
                        style={{
                            color: '#e20714',
                        }}
                        icon="minus-circle-o"
                        type="link"
                        onClick={removeGroup.bind(null, index)}
                    >
                        Supprimer
                    </Button>
                </div>
            ))}
            <Form.Item style={{ marginTop: 24 }}>
                <Button type="dashed" size="small" onClick={addGroup}>
                    <Icon type="plus" /> Ajouter une valeur
                </Button>
            </Form.Item>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    associatedProductGroupsSelectState: getAssociatedProductGroupsSelectState(state),
});

export default connect(
    mapStateToProps,
    {
        listSelectAssociatedProductGroups: AssociatedProductGroupsActions.listSelect,
    },
)(AssociatedProductsFields);
