import { Action, ActionCreator } from 'redux';

import { DataAction } from '../actions';
import * as reduxTypes from '../types/terms';

export interface LangAction extends Action {
    lang: string;
}

export const details: ActionCreator<LangAction> = (lang: string) => ({
    type: reduxTypes.DETAILS,
    lang,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

export const update: ActionCreator<DataAction<string> & LangAction> = (
    lang: string,
    data: string,
) => ({
    type: reduxTypes.UPDATE,
    lang,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});
