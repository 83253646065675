import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Input } from 'antd';
import Form, { FormComponentProps, FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';

import * as AuthActions from '../store/actions/auth';
import * as UsersActions from '../store/actions/users';
import { MainReducerState } from '../store/reducers';
import { UsersState, getUpdateUserState } from '../store/reducers/users';
import { getAuthState, AuthState } from '../store/reducers/auth';

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

interface EditProfileDrawerProps extends DrawerProps, FormComponentProps {
    authState: AuthState;
    fetchMe: typeof AuthActions.me;
    onClose: () => void;
    update: typeof UsersActions.update;
    updates: UsersState['update'];
}

const EditProfileDrawer: React.FC<EditProfileDrawerProps> = ({
    authState, fetchMe, form, onClose, update, updates, ...props
}) => {
    const { getFieldDecorator, getFieldsError } = form;
    const onSubmit: FormProps['onSubmit'] = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            if (authState.data) {
                update(authState.data.id, values);
            }
        });
    };
    const error = updates.error ? 'Une erreur est survenue' : null;
    useEffect(() => {
        if (updates.success) {
            fetchMe();
            onClose();
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Drawer
            title="Modifier mon profil"
            onClose={onClose}
            placement="right"
            width={450}
            {...props}
        >
            <Form onSubmit={onSubmit} layout="vertical">
                <Form.Item label="Nom">
                    {getFieldDecorator('lastName', {
                        rules: [{
                            required: true,
                            message: 'Champ requis',
                        }],
                        initialValue: authState.data ? authState.data.lastName : undefined,
                    })((
                        <Input
                            placeholder="Votre nom"
                        />
                    ))}
                </Form.Item>
                <Form.Item label="Prénom">
                    {getFieldDecorator('firstName', {
                        rules: [{
                            required: true,
                            message: 'Champ requis',
                        }],
                        initialValue: authState.data ? authState.data.firstName : undefined,
                    })((
                        <Input
                            placeholder="Votre prénom"
                        />
                    ))}
                </Form.Item>
                <Form.Item label="Adresse email">
                    {getFieldDecorator('email', {
                        rules: [{
                            required: true,
                            type: 'email',
                            message: 'Veuillez entrer une adresse email valide',
                        }],
                        initialValue: authState.data ? authState.data.email : undefined,
                        validateTrigger: 'onBlur',
                    })((
                        <Input
                            placeholder="Votre adresse email"
                            type="email"
                        />
                    ))}
                </Form.Item>
                <fieldset style={{ marginBottom: 32 }}>
                    <legend>Modifier le mot de passe</legend>
                    <Form.Item label="Mot de passe actuel" style={{marginBottom: 24}}>
                        {getFieldDecorator('oldPassword')((
                            <Input.Password
                                placeholder="Votre mot de passe actuel"
                            />
                        ))}
                    </Form.Item>
                    <Form.Item label="Nouveau mot de passe" style={{marginBottom: 0}}>
                        {getFieldDecorator('password')((
                            <Input.Password
                                placeholder="Votre nouveau mot de passe"
                            />
                        ))}
                    </Form.Item>
                </fieldset>
                {error ? (
                    <div className="login-error has-error">
                        <span className="ant-form-explain">{error}</span>
                    </div>
                ) : null}
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                    loading={updates.loading}
                    block
                >
                    Valider
                </Button>
            </Form>
        </Drawer>
    );
};

const EditProfileDrawerForm = Form.create<EditProfileDrawerProps>()(EditProfileDrawer);

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
    updates: getUpdateUserState(state),
});

export default connect(
    mapStateToProps,
    {
        fetchMe: AuthActions.me,
        update: UsersActions.update,
    },
)(EditProfileDrawerForm);
