import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { User } from './apiTypes';

export const list = (payload: SearchPaginationQuery) =>
    api.get(urlWithQuery('/api/users', payload));

export const details = (id: User['id']) =>
    api.get<User>(`/api/users/${id}`);

export const create = (body: Partial<User>) =>
    api.post<User>('/api/users', body);

export const update = (id: User['id'], body: Partial<User>) =>
    api.patch<User>(`/api/users/${id}`, body);

export const del = (id: User['id']) =>
    api.delete(`/api/users/${id}`);
