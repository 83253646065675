export const LIST = 'sectors/LIST';
export const LIST_SUCCESS = 'sectors/LIST_SUCCESS';
export const LIST_FAILED = 'sectors/LIST_FAILED';

export const LIST_SELECT = 'sectors/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'sectors/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAILED = 'sectors/LIST_SELECT_FAILED';

export const DETAILS = 'sectors/DETAILS';
export const DETAILS_SUCCESS = 'sectors/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'sectors/DETAILS_FAILED';

export const DETAILS_FULL_PROPERTIES = 'sectors/DETAILS_FULL_PROPERTIES';
export const DETAILS_FULL_PROPERTIES_SUCCESS = 'sectors/DETAILS_FULL_PROPERTIES_SUCCESS';
export const DETAILS_FULL_PROPERTIES_FAILED = 'sectors/DETAILS_FULL_PROPERTIES_FAILED';
export const DETAILS_FULL_PROPERTIES_RESET = 'sectors/DETAILS_FULL_PROPERTIES_RESET';

export const UPDATE = 'sectors/UPDATE';
export const UPDATE_SUCCESS = 'sectors/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'sectors/UPDATE_FAILED';

export const CREATE = 'sectors/CREATE';
export const CREATE_SUCCESS = 'sectors/CREATE_SUCCESS';
export const CREATE_FAILED = 'sectors/CREATE_FAILED';

export const DELETE = 'sectors/DELETE';
export const DELETE_SUCCESS = 'sectors/DELETE_SUCCESS';
export const DELETE_FAILED = 'sectors/DELETE_FAILED';

export const LIST_ASSOCIATED_PRODUCTS_GROUPS = 'sectors/LIST_ASSOCIATED_PRODUCTS_GROUPS';
export const LIST_ASSOCIATED_PRODUCTS_GROUPS_SUCCESS = 'sectors/LIST_ASSOCIATED_PRODUCTS_GROUPS_SUCCESS';
export const LIST_ASSOCIATED_PRODUCTS_GROUPS_FAILED = 'sectors/LIST_ASSOCIATED_PRODUCTS_GROUPS_FAILED';

export const LIST_CROSS_SELLING = 'sectors/LIST_CROSS_SELLING';
export const LIST_CROSS_SELLING_SUCCESS = 'sectors/LIST_CROSS_SELLING_SUCCESS';
export const LIST_CROSS_SELLING_FAILED = 'sectors/LIST_CROSS_SELLING_FAILED';

export const LIST_MARKETS = 'sectors/LIST_MARKETS';
export const LIST_MARKETS_SUCCESS = 'sectors/LIST_MARKETS_SUCCESS';
export const LIST_MARKETS_FAILED = 'sectors/LIST_MARKETS_FAILED';

export const LIST_SECTORS = 'sectors/LIST_SECTORS';
export const LIST_SECTORS_SUCCESS = 'sectors/LIST_SECTORS_SUCCESS';
export const LIST_SECTORS_FAILED = 'sectors/LIST_SECTORS_FAILED';

export const LIST_SUB_SECTORS = 'sectors/LIST_SUB_SECTORS';
export const LIST_SUB_SECTORS_SUCCESS = 'sectors/LIST_SUB_SECTORS_SUCCESS';
export const LIST_SUB_SECTORS_FAILED = 'sectors/LIST_SUB_SECTORS_FAILED';

export const LIST_SOLUTIONS = 'sectors/LIST_SOLUTIONS';
export const LIST_SOLUTIONS_SUCCESS = 'sectors/LIST_SOLUTIONS_SUCCESS';
export const LIST_SOLUTIONS_FAILED = 'sectors/LIST_SOLUTIONS_FAILED';

export const LIST_GAMMES_AND_SERVICES = 'sectors/LIST_GAMMES_AND_SERVICES';
export const LIST_GAMMES_AND_SERVICES_SUCCESS = 'sectors/LIST_GAMMES_AND_SERVICES_SUCCESS';
export const LIST_GAMMES_AND_SERVICES_FAILED = 'sectors/LIST_GAMMES_AND_SERVICES_FAILED';

export const LIST_GAMMES = 'sectors/LIST_GAMMES';
export const LIST_GAMMES_SUCCESS = 'sectors/LIST_GAMMES_SUCCESS';
export const LIST_GAMMES_FAILED = 'sectors/LIST_GAMMES_FAILED';

export const LIST_SERVICES = 'sectors/LIST_SERVICES';
export const LIST_SERVICES_SUCCESS = 'sectors/LIST_SERVICES_SUCCESS';
export const LIST_SERVICES_FAILED = 'sectors/LIST_SERVICES_FAILED';
