import React, { useState, useCallback, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Layout, Menu } from 'antd';

import { getRoute, RoutePathName } from '../routes';
import { MainReducerState } from '../store/reducers';
import { getUser } from '../store/reducers/auth';
import { connect } from 'react-redux';
import { UserDetails } from '../store/api/apiTypes';
import { isUserAllowed } from '../utils';

interface MainMenuProps {
    currentUser?: UserDetails;
}

const MainMenu: FC<MainMenuProps> = ({ currentUser }) => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const onCollapse = useCallback((value) => {
        setCollapsed(value);
    }, [setCollapsed]);

    return (
        <Layout.Sider
            breakpoint="lg"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            width={250}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                defaultOpenKeys={['sub1']}
                theme="light"
            >
                <Menu.Item key={getRoute(RoutePathName.dashboard)}>
                    <Link to={getRoute(RoutePathName.dashboard)}>
                        <Icon type="dashboard" />
                        <span>Tableau de bord</span>
                    </Link>
                </Menu.Item>
                {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                    <Menu.Item key={getRoute(RoutePathName.admins)}>
                        <Link to={getRoute(RoutePathName.admins)}>
                            <Icon type="team" />
                            <span>Administrateurs</span>
                        </Link>
                    </Menu.Item>
                )}
                {isUserAllowed({ user: currentUser, permission: 'users' }) && (
                    <Menu.Item key={getRoute(RoutePathName.users)}>
                        <Link to={getRoute(RoutePathName.users)}>
                            <Icon type="user" />
                            <span>Utilisateurs</span>
                        </Link>
                    </Menu.Item>
                )}
                {isUserAllowed({ user: currentUser, permission: 'customers' }) && (
                    <Menu.Item key={getRoute(RoutePathName.customers)}>
                        <Link to={getRoute(RoutePathName.customers)}>
                            <Icon type="solution" />
                            <span>Clients</span>
                        </Link>
                    </Menu.Item>
                )}
                {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                    <Menu.SubMenu
                        key="sectors"
                        title={(
                            <span>
                                <Icon type="layout" />
                                <span>Secteurs</span>
                            </span>
                        )}
                    >
                        <Menu.Item key={getRoute(RoutePathName.markets)}>
                            <Link to={getRoute(RoutePathName.markets)}>
                                <Icon type="layout" />
                                <span>Marchés</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={getRoute(RoutePathName.sectors)}>
                            <Link to={getRoute(RoutePathName.sectors)}>
                                <Icon type="layout" />
                                <span>Secteurs d'activités</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={getRoute(RoutePathName.subSectors)}>
                            <Link to={getRoute(RoutePathName.subSectors)}>
                                <Icon type="layout" />
                                <span>Sous-secteurs</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                {isUserAllowed({ user: currentUser, permission: 'sectors' }) && (
                    <Menu.SubMenu
                        key="solutions"
                        title={(
                            <span>
                                <Icon type="apartment" />
                                <span>Hiérarchie produit</span>
                            </span>
                        )}
                    >
                        <Menu.Item key={getRoute(RoutePathName.solutions)}>
                            <Link to={getRoute(RoutePathName.solutions)}>
                                <Icon type="bulb" />
                                <span>Besoins & solutions</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={getRoute(RoutePathName.gammesAndServices)}>
                            <Link to={getRoute(RoutePathName.gammesAndServices)}>
                                <Icon type="tag" />
                                <span>Gammes & services</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                {isUserAllowed({ user: currentUser, permission: 'products' }) && (
                    <Menu.SubMenu
                        key="products"
                        title={(
                            <span>
                                <Icon type="skin" />
                                <span>Produits</span>
                            </span>
                        )}
                    >
                        <Menu.Item key={getRoute(RoutePathName.products)}>
                            <Link to={getRoute(RoutePathName.products)}>
                                <Icon type="skin" />
                                <span>Liste</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={getRoute(RoutePathName.associatedProductGroups)}>
                            <Link to={getRoute(RoutePathName.associatedProductGroups)}>
                                <Icon type="gold" />
                                <span>Groupes</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                {isUserAllowed({ user: currentUser, permission: 'sectors' }) && (
                    <Menu.SubMenu
                        key="filters"
                        title={(
                            <span>
                                <Icon type="filter" />
                                <span>Filtres</span>
                            </span>
                        )}
                    >
                        <Menu.Item key={getRoute(RoutePathName.arguments)}>
                            <Link to={getRoute(RoutePathName.arguments)}>
                                <Icon type="star" />
                                <span>Vos motivations</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={getRoute(RoutePathName.properties)}>
                            <Link to={getRoute(RoutePathName.properties)}>
                                <Icon type="check-square" />
                                <span>Propriétés</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                {isUserAllowed({ user: currentUser, permission: 'presentations' }) && (
                    <Menu.SubMenu
                        key="presentations"
                        title={(
                            <span>
                                <Icon type="file-pdf" />
                                <span>Présentations</span>
                            </span>
                        )}
                    >
                        <Menu.Item key={getRoute(RoutePathName.themes)}>
                            <Link to={getRoute(RoutePathName.themes)}>
                                <Icon type="container" />
                                <span>Thèmes</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={getRoute(RoutePathName.chapters)}>
                            <Link to={getRoute(RoutePathName.chapters)}>
                                <Icon type="book" />
                                <span>Chapitres</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                    <Menu.Item key={getRoute(RoutePathName.countries)}>
                        <Link to={getRoute(RoutePathName.countries)}>
                            <Icon type="global" />
                            <span>Pays</span>
                        </Link>
                    </Menu.Item>
                )}
                {isUserAllowed({ user: currentUser, permission: 'news' }) && (
                    <Menu.Item key={getRoute(RoutePathName.news)}>
                        <Link to={getRoute(RoutePathName.news)}>
                            <Icon type="read" />
                            <span>News</span>
                        </Link>
                    </Menu.Item>
                )}
                {isUserAllowed({ user: currentUser, permission: 'terms' }) && (
                    <Menu.Item key={getRoute(RoutePathName.terms)}>
                        <Link to={getRoute(RoutePathName.terms)}>
                            <Icon type="file-text" />
                            <span>CGU</span>
                        </Link>
                    </Menu.Item>
                )}
                <Menu.Item key={getRoute(RoutePathName.naceDigitalBook)}>
                    <Link to={getRoute(RoutePathName.naceDigitalBook)}>
                        <Icon type="file-text" />
                        <span>NACE & Book digital</span>
                    </Link>
                </Menu.Item>
            </Menu>
        </Layout.Sider>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentUser: getUser(state),
});

export default connect(
    mapStateToProps,
)(MainMenu);
