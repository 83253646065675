import { all, call, delay, takeLatest, put } from 'redux-saga/effects';
import { Action } from 'redux';

import * as actions from '../actions/sectors';
import * as reduxTypes from '../types/sectors';
import * as api from '../api/sectors';
import { SearchPaginationQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Sector, FileSubType } from '../api/apiTypes';
import { create as createFile } from '../api/files';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSelectSuccess(response));
    } catch (error) {
        return yield put(actions.listSelectFailed(error));
    }
}

export function* listAssociatedProductsGroups(action: IdAction<Sector['id']>) {
    try {
        const response = yield call(api.listAssociatedProductsGroups, action.id);

        return yield put(actions.listAssociatedProductsGroupsSuccess(response));
    } catch (error) {
        return yield put(actions.listAssociatedProductsGroupsFailed(error));
    }
}

export function* listCrossSelling(action: IdAction<Sector['id']>) {
    try {
        const response = yield call(api.listCrossSelling, action.id);

        return yield put(actions.listCrossSellingSuccess(response));
    } catch (error) {
        return yield put(actions.listCrossSellingFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Sector['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(actions.detailsSuccess(response));
    } catch (error) {
        return yield put(actions.detailsFailed(error));
    }
}

export function* detailsWithFullPropertiesSaga(action: IdAction<Sector['id']>) {
    try {
        const response = yield call(api.detailsWithFullProperties, action.id);
        return yield put(actions.detailsWithFullPropertiesSuccess(response));
    } catch (error) {
        return yield put(actions.detailsWithFullPropertiesFailed(error));
    }
}

export function* updateSaga(action: IdAction<Sector['id']> & DataAction<Partial<Sector>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export function* createSaga(action: DataAction<Partial<Sector> & {
    image?: File;
    sectorInformation?: File;
    sectorInformationWithoutLogos?: File;
    salesAcademy?: File;
}>) {
    try {
        const response = yield call(api.create, action.data);

        const filesQueries = [];

        if (action.data.image) {
            filesQueries.push(call(createFile, action.data.image, { sectorId: response.id }));
        }

        if (action.data.sectorInformation) {
            filesQueries.push(call(createFile, action.data.sectorInformation, {
                sectorId: response.id,
                subType: FileSubType.sectorInformation,
            }));
        }

        if (action.data.sectorInformationWithoutLogos) {
            filesQueries.push(call(createFile, action.data.sectorInformationWithoutLogos, {
                sectorId: response.id,
                subType: FileSubType.sectorInformationWithoutLogos,
            }));
        }

        if (action.data.salesAcademy) {
            filesQueries.push(call(createFile, action.data.salesAcademy, {
                sectorId: response.id,
                subType: FileSubType.salesAcademy,
            }));
        }

        yield all(filesQueries);

        return yield put(actions.createSuccess(response));
    } catch (error) {
        return yield put(actions.createFailed(error));
    }
}

export function* deleteSaga(action: IdAction<Sector['id']>) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(actions.delSuccess(response));
    } catch (error) {
        return yield put(actions.delFailed(error));
    }
}

export function* listMarketsSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listMarketsSuccess(response));
    } catch (error) {
        return yield put(actions.listMarketsFailed(error));
    }
}

export function* listSectorsSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSectorsSuccess(response));
    } catch (error) {
        return yield put(actions.listSectorsFailed(error));
    }
}

export function* listSubSectorsSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSubSectorsSuccess(response));
    } catch (error) {
        return yield put(actions.listSubSectorsFailed(error));
    }
}

export function* listSolutionsSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSolutionsSuccess(response));
    } catch (error) {
        return yield put(actions.listSolutionsFailed(error));
    }
}

export function* listGammesSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listGammesSuccess(response));
    } catch (error) {
        return yield put(actions.listGammesFailed(error));
    }
}

export function* listGammesAndServicesSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listGammesAndServicesSuccess(response));
    } catch (error) {
        return yield put(actions.listGammesAndServicesFailed(error));
    }
}

export function* listServicesSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listServicesSuccess(response));
    } catch (error) {
        return yield put(actions.listServicesFailed(error));
    }
}

export default function* sectorsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS, listAssociatedProductsGroups);
    yield takeLatest(reduxTypes.LIST_CROSS_SELLING, listCrossSelling);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.DETAILS_FULL_PROPERTIES, detailsWithFullPropertiesSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
    yield takeLatest(reduxTypes.LIST_MARKETS, listMarketsSaga);
    yield takeLatest(reduxTypes.LIST_SECTORS, listSectorsSaga);
    yield takeLatest(reduxTypes.LIST_SUB_SECTORS, listSubSectorsSaga);
    yield takeLatest(reduxTypes.LIST_SOLUTIONS, listSolutionsSaga);
    yield takeLatest(reduxTypes.LIST_GAMMES_AND_SERVICES, listGammesAndServicesSaga);
    yield takeLatest(reduxTypes.LIST_GAMMES, listGammesSaga);
    yield takeLatest(reduxTypes.LIST_SERVICES, listServicesSaga);
}
