import * as React from 'react';

import '../assets/styles/EmptyResult.less';

import { IconPlaceholder } from './icons';

interface EmptyResultProps extends React.HTMLProps<JSX.Element> {
    text?: string | React.ReactNode;
}

const EmptyResult: React.FC<EmptyResultProps> = ({ text = 'Aucuns résultats' }) => (
    <div className="empty-result">
        <IconPlaceholder />
        <p>{text}</p>
    </div>
);

export default EmptyResult;
