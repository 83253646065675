import {
    IconShirt, IconLinen, IconFloor, IconSanitary, IconDrink, IconDasri, IconWipers, IconRat,
    IconCleaner, IconCommerces, IconIndustry,
} from '../../components/icons';
import { IconProps } from 'antd/lib/icon';

export { default as Sectors } from './Sectors';
export { default as SectorFormDrawer } from './SectorFormDrawer';
export { default as Markets } from './Markets';
export { default as MarketFormDrawer } from './MarketFormDrawer';
export { default as SubSectors } from './SubSectors';
export { default as SubSectorFormDrawer } from './SubSectorFormDrawer';
export { default as Solutions } from './Solutions';
export { default as SolutionFormDrawer } from './SolutionFormDrawer';
export { default as GammesAndServices } from './GammesAndServices';
export { default as GammeAndServiceFormDrawer } from './GammeAndServiceFormDrawer';

export const sectorIconsMap: {
    [key: string]: React.FunctionComponent<IconProps>;
} = {
    commerce: IconCommerces,
    industry: IconIndustry,
};

export const solutionIconsMap: {
    [key: string]: React.FunctionComponent<IconProps>;
} = {
    shirt: IconShirt,
    linen: IconLinen,
    floor: IconFloor,
    sanitary: IconSanitary,
    drink: IconDrink,
    dasri: IconDasri,
    wipers: IconWipers,
    rat: IconRat,
    cleaner: IconCleaner,
};
