import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { InputProps, SearchProps } from 'antd/lib/input';

import '../../assets/styles/Sectors.less';

import { Sector } from '../../store/api/apiTypes';
import * as SectorsActions from '../../store/actions/sectors';
import { MainReducerState } from '../../store/reducers';
import { SectorsState, getDeleteSectorState, getSectorsListState } from '../../store/reducers/sectors';

import { constants, t, scrollToElement } from '../../utils';
import { sectorIconsMap, SectorFormDrawer } from '.';
import ListTitle from '../../components/ListTitle';

export interface SectorsProps {
    deleteSector: typeof SectorsActions.del;
    deletes: SectorsState['delete'];
    sectorsState: SectorsState['listSectors'];
    list: typeof SectorsActions.listSectors;
}

const rowKey = (item: Sector) => `${item.id}`;

const Sectors: FC<SectorsProps> = ({ deletes, deleteSector, sectorsState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;
    const payload = {
        page: currentPage,
        search,
        limit: constants.PAGE_SIZE,
        sectorType: 'sector',
    };

    useEffect(() => {
        list(payload);
    }, [list, pageParam, deletes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list(payload);
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            ...payload,
            page: search ? undefined : currentPage,
            throttling: 300,
        });
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: Sector['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    // const onConfirmDelete = (id: Sector['id']) => {
    //     deleteSector(id);
    // };

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onTableChange: TableProps<Sector>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list({
            ...payload,
            throttling: 300,
        });
    };

    const columns: Array<ColumnProps<Sector>> = [
        {
            dataIndex: 'reference',
            title: 'Réference',
        },
        {
            dataIndex: 'name',
            title: 'Nom',
            render: t,
        },
        {
            dataIndex: 'icon',
            title: 'Icône',
            render: (val) => {
                const SectorIcon = sectorIconsMap[val];

                return SectorIcon ? <SectorIcon /> : null;
            },
        },
        {
            dataIndex: 'image',
            title: 'Bannière',
            render: (val, record) => val ? <img src={val.url} alt={t(record.name)} /> : null,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    {/* <Popconfirm
                        title="Êtes-vous sûr ?"
                        onConfirm={onConfirmDelete.bind(null, record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            loading={deletes.loading}
                            icon="delete"
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        />
                    </Popconfirm> */}
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <ListTitle total={sectorsState.total}>
                    Liste des secteurs d'activités
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer un secteur d'activité
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<Sector>
                bordered={false}
                className="sectors-list"
                rowKey={rowKey}
                columns={columns}
                dataSource={sectorsState.data}
                loading={sectorsState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: sectorsState.total,
                }}
            />
            <SectorFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    deletes: getDeleteSectorState(state),
    sectorsState: getSectorsListState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteSector: SectorsActions.del,
        list: SectorsActions.listSectors,
    },
)(Sectors);
