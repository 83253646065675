export const LIST = 'users/LIST';
export const LIST_SUCCESS = 'users/LIST_SUCCESS';
export const LIST_FAILED = 'users/LIST_FAILED';

export const LIST_ADMINS = 'users/LIST_ADMINS';
export const LIST_ADMINS_SUCCESS = 'users/LIST_ADMINS_SUCCESS';
export const LIST_ADMINS_FAILED = 'users/LIST_ADMINS_FAILED';

export const DETAILS = 'users/DETAILS';
export const DETAILS_SUCCESS = 'users/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'users/DETAILS_FAILED';

export const CREATE = 'users/CREATE';
export const CREATE_SUCCESS = 'users/CREATE_SUCCESS';
export const CREATE_FAILED = 'users/CREATE_FAILED';

export const UPDATE = 'users/UPDATE';
export const UPDATE_SUCCESS = 'users/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'users/UPDATE_FAILED';

export const DELETE = 'users/DELETE';
export const DELETE_SUCCESS = 'users/DELETE_SUCCESS';
export const DELETE_FAILED = 'users/DELETE_FAILED';
