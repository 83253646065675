import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { DateTime } from 'luxon';

import { User, UserDetails } from '../../store/api/apiTypes';
import * as UsersActions from '../../store/actions/users';
import { MainReducerState } from '../../store/reducers';
import { getAdminsState, UsersState, getDeleteUserState } from '../../store/reducers/users';
import { getFullName, constants, t, scrollToElement, isUserAllowed } from '../../utils';
import { InputProps, SearchProps } from 'antd/lib/input';
import ListTitle from '../../components/ListTitle';
import { IconTrash } from '../../components/icons';
import { usePrevious } from '../../hooks';
import { AdminFormDrawer } from '.';
import { getUser } from '../../store/reducers/auth';

export interface AdminsProps {
    currentUser?: UserDetails;
    deletes: UsersState['delete'];
    deleteUser: typeof UsersActions.del;
    list: typeof UsersActions.listAdmins;
    usersState: UsersState['listAdmins'];
}

const rowKey = (item: User) => `${item.id}`;

const Admins: FC<AdminsProps> = ({ currentUser, deletes, deleteUser, usersState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const prevDeletes = usePrevious<Partial<AdminsProps>>({ deletes });
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;
    const listPayload = {
        page: currentPage,
        search,
        limit: constants.PAGE_SIZE,
        role: isUserAllowed({ user: currentUser, role: 'superAdmin' }) ? ['superAdmin', 'admin'] : 'admin',
    };

    useEffect(() => {
        list(listPayload);
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            ...listPayload,
            throttling: 300,
        });
    }, [location.pathname, search]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevDeletes && prevDeletes.deletes && prevDeletes.deletes.loading && !deletes.loading && deletes.success) {
            list(listPayload);
        }
    }, [deletes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onTableChange: TableProps<User>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onClickDelete = (id: User['id']) => {
        deleteUser(id);
    };

    const setEditMode = (id: User['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list(listPayload);
    };

    const columns: Array<ColumnProps<User>> = [
        {
            key: 'name',
            title: 'Nom',
            render: (text, record) => getFullName(record.firstName, record.lastName),
        },
        {
            dataIndex: 'email',
            title: 'E-mail',
        },
        {
            dataIndex: 'profile.name',
            title: 'Profil',
            render: t,
        },
        {
            dataIndex: 'market.name',
            title: 'Marché',
            render: t,
        },
        {
            dataIndex: 'createAt',
            title: 'Date de création',
            render: (text) => DateTime.fromISO(text).toLocaleString(),
        },
        {
            dataIndex: 'country.name',
            title: 'Pays',
            render: t,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    <Popconfirm
                        cancelText="non"
                        okText="oui"
                        onConfirm={onClickDelete.bind(null, record.id)}
                        title="Voulez-vous vraiment supprimer cet administrateur ?"
                    >
                        <Button
                            loading={deletes.loading}
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        >
                            <IconTrash />
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <ListTitle total={usersState.total}>
                    Liste des administrateurs
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer un administrateur
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<User>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={usersState.data}
                loading={usersState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: usersState.total,
                }}
            />
            <AdminFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentUser: getUser(state),
    deletes: getDeleteUserState(state),
    usersState: getAdminsState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteUser: UsersActions.del,
        list: UsersActions.listAdmins,
    },
)(Admins);
