import * as reduxTypes from '../types/arguments';
import { ArgumentValue, ArgumentDetails, ArgumentListItem } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';

export interface ArgumentsState {
    readonly list: RequestState<ArgumentListItem[]>;
    readonly listSelect: RequestState<ArgumentListItem[]>;
    readonly details: RequestState<ArgumentDetails>;
    readonly update: RequestState<Partial<ArgumentDetails>>;
    readonly create: RequestState<Partial<ArgumentValue>>;
    readonly delete: RequestState;
}

const initialState: ArgumentListItem[] = [];

export default combineReducers<ArgumentsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getArgumentsState = (state: MainReducerState) => state.args.list;
export const getArgumentsSelectState = (state: MainReducerState) => state.args.listSelect;
export const getArgumentDetailsState = (state: MainReducerState) => state.args.details;
export const getUpdateArgumentState = (state: MainReducerState) => state.args.update;
export const getCreateArgumentState = (state: MainReducerState) => state.args.create;
export const getDeleteArgumentState = (state: MainReducerState) => state.args.delete;
