import * as reduxTypes from '../types/permissions';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { Permission } from '../api/apiTypes';

export type PermissionsState = RequestState<Permission[]>;

const initialState: Permission[] = [];

export default requestReducer<Permission[]>({
    START: reduxTypes.LIST,
    SUCCESS: reduxTypes.LIST_SUCCESS,
    FAILED: reduxTypes.LIST_FAILED,
}, initialState);

export const getPermissionsState = (state: MainReducerState) => state.permissions;
