import * as reduxTypes from '../types/themes';
import { Theme, ThemeDetails } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';

export interface ThemesState {
    readonly list: RequestState<Theme[]>;
    readonly listSelect: RequestState<Theme[]>;
    readonly details: RequestState<ThemeDetails>;
    readonly update: RequestState<Partial<Theme>>;
    readonly create: RequestState<Partial<Theme>>;
    readonly delete: RequestState;
}

const initialState: Theme[] = [];

export default combineReducers<ThemesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getThemesState = (state: MainReducerState) => state.themes.list;
export const getThemes = (state: MainReducerState) => state.themes.list.data;
export const getThemesSelectState = (state: MainReducerState) => state.themes.listSelect;
export const getThemeDetailsState = (state: MainReducerState) => state.themes.details;
export const getUpdateThemeState = (state: MainReducerState) => state.themes.update;
export const getCreateThemeState = (state: MainReducerState) => state.themes.create;
export const getDeleteThemeState = (state: MainReducerState) => state.themes.delete;
