import { all, call, delay, takeLatest, put, CallEffect } from 'redux-saga/effects';
import { Action } from 'redux';

import * as actions from '../actions/products';
import * as reduxTypes from '../types/products';
import * as api from '../api/products';
import { SearchPaginationQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Product } from '../api/apiTypes';
import { create as createFile } from '../api/files';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSuccess(response));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);

        const response = yield call(api.list, action);

        return yield put(actions.listSelectSuccess(response));
    } catch (error) {
        return yield put(actions.listSelectFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Product['id']> & {full: boolean}) {
    try {
        const response = yield call(api.details, action.id,   action.full);
        return yield put(actions.detailsSuccess(response));
    } catch (error) {
        return yield put(actions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Product['id']> & DataAction<Partial<Product>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export function* setEnabledSaga(action: IdAction<Product['id']> & DataAction<Partial<Product>>) {
    try {
        const response = yield call(api.setEnabled, action.id, action.data);
        return yield put(actions.setEnabledSuccess(response));
    } catch (error) {
        return yield put(actions.setEnabledFailed(error));
    }
}
export function* createSaga(action: DataAction<Partial<Product> & {
    imagesToUpload?: File[];
}>) {
    try {
        const response = yield call(api.create, action.data);

        const filesQueries: CallEffect[] = [];

        if (action.data.imagesToUpload) {
            action.data.imagesToUpload.forEach((image) => {
                filesQueries.push(call(createFile, image, { productId: response.id }));
            });
        }

        yield all(filesQueries);

        return yield put(actions.createSuccess(response));
    } catch (error) {
        return yield put(actions.createFailed(error));
    }
}

export function* deleteSaga(action: IdAction<Product['id']>) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(actions.delSuccess(response));
    } catch (error) {
        return yield put(actions.delFailed(error));
    }
}

export default function* productsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
    yield takeLatest(reduxTypes.SET_ENABLED, setEnabledSaga);
}
