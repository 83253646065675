import * as React from 'react';

import ButtonLink from '../../components/ButtonLink';
import EmptyResult from '../../components/EmptyResult';
import { getRoute, RoutePathName } from '../../routes';

const Unauthorized: React.FC = () => (
    <div className="container text-center" style={{ paddingTop: 64 }}>
        <h1>403 Accès non autorisé</h1>
        <EmptyResult
            text="Vous n'avez pas accès à cette page, veuillez contacter un administrateur"
        />
        <ButtonLink to={getRoute(RoutePathName.dashboard)} type="primary">
            {'Retourner à l\'accueil'}
        </ButtonLink>
    </div>
);

export default Unauthorized;
