import * as reduxTypes from '../types/customers';
import { Customer } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export type CustomersState = RequestState<Customer[]>;

const initialState: Customer[] = [];

const customers = requestReducer<Customer[]>({
    START: reduxTypes.LIST,
    SUCCESS: reduxTypes.LIST_SUCCESS,
    FAILED: reduxTypes.LIST_FAILED,
}, initialState);

export default customers;

export const getCustomersState = (state: MainReducerState) => state.customers;
