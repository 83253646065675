import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';

import * as CountriesActions from '../../store/actions/countries';
import * as SectorsActions from '../../store/actions/sectors';
import { SectorsState, getSectorDetailsState, getUpdateSectorState, getCreateSectorState } from '../../store/reducers/sectors';
import { RequestState, MainReducerState } from '../../store/reducers';
import { Sector } from '../../store/api/apiTypes';
import { getCountriesSelectState, CountriesState } from '../../store/reducers/countries';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';

interface MarketFormDrawerProps extends FormComponentProps {
    countriesSelectState: CountriesState['listSelect'];
    create: typeof SectorsActions.create;
    creates: RequestState;
    details: SectorsState['details'];
    getDetails: typeof SectorsActions.details;
    id?: Sector['id'];
    isVisible: boolean;
    listSelectCountries: typeof CountriesActions.listSelect;
    onClose: () => void;
    onUpdateSuccess: () => void;
    update: typeof SectorsActions.update;
    updates: RequestState;
}

const MarketForm: FC<MarketFormDrawerProps> = ({
    countriesSelectState, create, creates, details, getDetails, form, id, isVisible,
    listSelectCountries, onClose, onUpdateSuccess, update, updates,

}) => {
    const isEditing = id !== undefined;

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }
            listSelectCountries({ limit: 20 });

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onCountriesSearch: SelectProps['onSearch'] = (value) => {
        listSelectCountries({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                    icon: val.icon || null,
                });
            } else {
                create({
                    type: 'market',
                    sectorParents: [],
                    excludedSectors: [],
                    ...val,
                    icon: val.icon || null,
                });
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'un marché'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom">
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom du marché" />,
                                    )}
                                </MultiLangInput>
                                <Form.Item label="Pays rattachés">
                                    {getFieldDecorator('countries', {
                                        initialValue: isEditing && details.data && details.data.countries ?
                                            details.data.countries.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            filterOption={false}
                                            loading={countriesSelectState.loading}
                                            onSearch={onCountriesSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs pays"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.countries.reduce((acc, country) => {
                                                    if (!acc.find((s) => s.id === country.id)) {
                                                        acc.unshift(country);
                                                    }
                                                    return acc;
                                                }, [...countriesSelectState.data]) :
                                                countriesSelectState.data
                                            ).map((country) => (
                                                <Select.Option
                                                    key={country.id}
                                                    value={country.id}
                                                >
                                                    {t(country.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    countriesSelectState: getCountriesSelectState(state),
    creates: getCreateSectorState(state),
    details: getSectorDetailsState(state),
    updates: getUpdateSectorState(state),
});

const MarketFormDrawer = Form.create<MarketFormDrawerProps>()(MarketForm);

export default connect(
    mapStateToProps,
    {
        create: SectorsActions.create,
        listSelectCountries: CountriesActions.listSelect,
        getDetails: SectorsActions.details,
        update: SectorsActions.update,
    },
)(MarketFormDrawer);
