import { call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '../actions/dashboard';
import * as reduxTypes from '../types/dashboard';
import * as api from '../api/dashboard';

export function* fetchSaga() {
    try {
        const response = yield call(api.fetch);
        return yield put(actions.fetchSuccess(response));
    } catch (error) {
        return yield put(actions.fetchFailed(error));
    }
}

export default function* statsSaga() {
    yield takeLatest(reduxTypes.FETCH, fetchSaga);
}
