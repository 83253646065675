import { combineReducers } from 'redux';

import * as reduxTypes from '../types/sectors';
import { AssociatedProductGroup, SectorDetails, SectorListItem, CrossSelling } from '../api/apiTypes';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from './';

export interface SectorsState {
    readonly list: RequestState<SectorListItem[]>;
    readonly listSelect: RequestState<SectorListItem[]>;
    readonly details: RequestState<SectorDetails>;
    readonly detailsWithFullProperties: RequestState<SectorDetails>;
    readonly update: RequestState<Partial<SectorDetails>>;
    readonly create: RequestState<Partial<SectorDetails>>;
    readonly delete: RequestState;
    readonly listAssociatedProductsGroups: RequestState<AssociatedProductGroup[]>;
    readonly listCrossSelling: RequestState<CrossSelling[]>;
    readonly listMarkets: RequestState<SectorListItem[]>;
    readonly listSectors: RequestState<SectorListItem[]>;
    readonly listSubSectors: RequestState<SectorListItem[]>;
    readonly listSolutions: RequestState<SectorListItem[]>;
    readonly listGammesAndServices: RequestState<SectorListItem[]>;
}

const initialState: SectorListItem[] = [];

export default combineReducers<SectorsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    detailsWithFullProperties: requestReducer({
        START: reduxTypes.DETAILS_FULL_PROPERTIES,
        SUCCESS: reduxTypes.DETAILS_FULL_PROPERTIES_SUCCESS,
        FAILED: reduxTypes.DETAILS_FULL_PROPERTIES_FAILED,
        RESET: reduxTypes.DETAILS_FULL_PROPERTIES_RESET,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
    listAssociatedProductsGroups: requestReducer({
        START: reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS,
        SUCCESS: reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS_SUCCESS,
        FAILED: reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS_FAILED,
    }),
    listCrossSelling: requestReducer({
        START: reduxTypes.LIST_CROSS_SELLING,
        SUCCESS: reduxTypes.LIST_CROSS_SELLING_SUCCESS,
        FAILED: reduxTypes.LIST_CROSS_SELLING_FAILED,
    }),
    listMarkets: requestReducer({
        START: reduxTypes.LIST_MARKETS,
        SUCCESS: reduxTypes.LIST_MARKETS_SUCCESS,
        FAILED: reduxTypes.LIST_MARKETS_FAILED,
    }, initialState),
    listSectors: requestReducer({
        START: reduxTypes.LIST_SECTORS,
        SUCCESS: reduxTypes.LIST_SECTORS_SUCCESS,
        FAILED: reduxTypes.LIST_SECTORS_FAILED,
    }, initialState),
    listSubSectors: requestReducer({
        START: reduxTypes.LIST_SUB_SECTORS,
        SUCCESS: reduxTypes.LIST_SUB_SECTORS_SUCCESS,
        FAILED: reduxTypes.LIST_SUB_SECTORS_FAILED,
    }, initialState),
    listSolutions: requestReducer({
        START: reduxTypes.LIST_SOLUTIONS,
        SUCCESS: reduxTypes.LIST_SOLUTIONS_SUCCESS,
        FAILED: reduxTypes.LIST_SOLUTIONS_FAILED,
    }, initialState),
    listGammesAndServices: requestReducer({
        START: reduxTypes.LIST_GAMMES_AND_SERVICES,
        SUCCESS: reduxTypes.LIST_GAMMES_AND_SERVICES_SUCCESS,
        FAILED: reduxTypes.LIST_GAMMES_AND_SERVICES_FAILED,
    }, initialState),
});

export const getSectorsState = (state: MainReducerState) => state.sectors.list;
export const getSectorsSelectState = (state: MainReducerState) => state.sectors.listSelect;
export const getSectorDetailsState = (state: MainReducerState) => state.sectors.details;
export const getSectorDetailsWithFullPropertiesState = (state: MainReducerState) =>
    state.sectors.detailsWithFullProperties;
export const getUpdateSectorState = (state: MainReducerState) => state.sectors.update;
export const getCreateSectorState = (state: MainReducerState) => state.sectors.create;
export const getDeleteSectorState = (state: MainReducerState) => state.sectors.delete;
export const getSectorAssociatedProductsGroupsState = (state: MainReducerState) =>
    state.sectors.listAssociatedProductsGroups;
export const getSectorCrossSellingState = (state: MainReducerState) =>
    state.sectors.listCrossSelling;
export const getMarketsState = (state: MainReducerState) => state.sectors.listMarkets;
export const getSectorsListState = (state: MainReducerState) => state.sectors.listSectors;
export const getSubSectorsState = (state: MainReducerState) => state.sectors.listSubSectors;
export const getSolutionsState = (state: MainReducerState) => state.sectors.listSolutions;
export const getGammesAndServicesState = (state: MainReducerState) => state.sectors.listGammesAndServices;
