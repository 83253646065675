import React, { FC, ReactNode } from 'react';
import { Badge } from 'antd';

interface ListTitleProps {
    total?: ReactNode;
}

const ListTitle: FC<ListTitleProps> = ({ children, total }) => (
    <h1>
        {children}
        {total !== undefined && (
            <Badge
                style={{
                    background: '#00a5aa',
                    color: '#fff',
                    marginLeft: 8,
                }}
                overflowCount={99999}
                count={
                    typeof total === 'number' &&
                        new Intl.NumberFormat().format(total)
                }
            />
        )}
    </h1>
);

export default ListTitle;
