import * as reduxTypes from '../types/languages';
import { Language } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export type LanguagesState = RequestState<Language[]>;

const initialState: Language[] = [];

const languages = requestReducer<Language[]>({
    START: reduxTypes.LIST,
    SUCCESS: reduxTypes.LIST_SUCCESS,
    FAILED: reduxTypes.LIST_FAILED,
}, initialState);

export default languages;

export const getLanguagesState = (state: MainReducerState) => state.languages;
export const getLanguages = (state: MainReducerState) => state.languages.data;
