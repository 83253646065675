import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, Row, Col, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';

import * as CountriesActions from '../../store/actions/countries';
import * as SectorsActions from '../../store/actions/sectors';
import * as PermissionsActions from '../../store/actions/permissions';
import * as ProfilesActions from '../../store/actions/profiles';
import * as UsersActions from '../../store/actions/users';
import {
    UsersState, getDetailsUserState, getUpdateUserState, getCreateUserState,
} from '../../store/reducers/users';
import {  MainReducerState } from '../../store/reducers';
import { User, UserDetails, SectorListItem, Role } from '../../store/api/apiTypes';
import { ProfilesState, getProfilesState } from '../../store/reducers/profiles';
import { getMarketsState, SectorsState, getSectorsState } from '../../store/reducers/sectors';
import { getCountriesSelectState, CountriesState } from '../../store/reducers/countries';

import { t, getFullName, isUserAllowed } from '../../utils';
import { getUser } from '../../store/reducers/auth';
import { getPermissionsState, PermissionsState } from '../../store/reducers/permissions';

interface AdminFormDrawerProps extends FormComponentProps {
    countriesSelectState: CountriesState['listSelect'];
    creates: UsersState['create'];
    create: typeof UsersActions.create;
    currentUser?: UserDetails;
    details: UsersState['details'];
    getDetails: typeof UsersActions.details;
    id?: User['id'];
    isVisible: boolean;
    listSelectCountries: typeof CountriesActions.listSelect;
    listMarkets: typeof SectorsActions.listMarkets;
    listSectors: typeof SectorsActions.list;
    listPermissions: typeof PermissionsActions.list;
    listProfiles: typeof ProfilesActions.list;
    marketsState: SectorsState['listMarkets'];
    onClose: () => void;
    onUpdateSuccess: () => void;
    permissionsState: PermissionsState;
    profilesState: ProfilesState;
    sectorsState: SectorsState['list'];
    updates: UsersState['update'];
    update: typeof UsersActions.update;
}

const AdminDrawer: FC<AdminFormDrawerProps> = ({
    countriesSelectState, create, creates, currentUser, details, getDetails, form, id, isVisible,
    listProfiles, listSectors, listSelectCountries, listPermissions, listMarkets, marketsState,
    onClose, onUpdateSuccess, permissionsState, profilesState, sectorsState, update, updates,
}) => {
    const isEditing = id !== undefined;
    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }

            if (isEditing) {
                getDetails(id);
            }
            listMarkets({ sectorType: 'market', limit: 20 });
            listPermissions({ limit: 60 });
            listProfiles({ limit: 20 });
            listSectors({ sectorType: 'solution', limit: 20 });
            listSelectCountries({ limit: 20 });
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onCountriesSearch: SelectProps['onSearch'] = (value) => {
        listSelectCountries({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onMarketSearch: SelectProps['onSearch'] = (value) => {
        listMarkets({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSectorsSeach: SelectProps['onSearch'] = (value) => {
        listSectors({
            sectorType: 'solution',
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onProfileSearch: SelectProps['onSearch'] = (value) => {
        listProfiles({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            const payload = {
                ...val,
                permissions: val.permissions || (
                    val.role === Role.SuperAdmin ? permissionsState.data.map((p) => p.id) : undefined
                ),
                relatedUserId: currentUser && currentUser.id,
            };

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...payload,
                });
            } else {
                create(payload);
            }
        });
    };

    const { getFieldDecorator, getFieldValue } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? getFullName(details.data.firstName, details.data.lastName) : ''}` :
                        'Création d\'un administrateur'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <Row gutter={24}>
                                    <Col xs={12}>
                                        <Form.Item label="Prénom">
                                            {getFieldDecorator('firstName', {
                                                rules: [{
                                                    required: true,
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && details.data ?
                                                    details.data.firstName :
                                                    '',
                                            })((
                                                <Input placeholder="Prénom de l'administrateur" />
                                            ))}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="Nom">
                                            {getFieldDecorator('lastName', {
                                                rules: [{
                                                    required: true,
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && details.data ?
                                                    details.data.lastName :
                                                    '',
                                            })((
                                                <Input placeholder="Nom de l'administrateur" />
                                            ))}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Adresse email">
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            required: true,
                                            type: 'email',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data ?
                                            details.data.email :
                                            '',
                                    })((
                                        <Input type="email" placeholder="Adresse email de l'administrateur" />
                                    ))}
                                </Form.Item>
                                <Form.Item label="Profile">
                                    {getFieldDecorator('profileId', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.profile ?
                                            details.data.profile.id :
                                            undefined,
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={profilesState.loading}
                                            onSearch={onProfileSearch}
                                            placeholder="Rechercher et choisir un profile"
                                            showSearch
                                        >
                                            {(isEditing && details.data && details.data.profile ?
                                                [
                                                    ...profilesState.data.filter((c) =>
                                                        c.id !== details.data.profile.id,
                                                    ),
                                                    details.data.profile,
                                                ] :
                                                profilesState.data
                                            ).map((profile) => (
                                                <Select.Option
                                                    key={profile.id}
                                                    value={profile.id}
                                                >
                                                    {t(profile.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Row gutter={24}>
                                    <Col xs={12}>
                                        <Form.Item label="Pays rattaché">
                                            {getFieldDecorator('countryId', {
                                                rules: [{
                                                    required: true,
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && details.data ?
                                                    details.data.country.id :
                                                    undefined,
                                            })((
                                                <Select
                                                    filterOption={false}
                                                    loading={countriesSelectState.loading}
                                                    onSearch={onCountriesSearch}
                                                    placeholder="Rechercher et choisir un pays"
                                                    showSearch
                                                >
                                                    {(isEditing && details.data ?
                                                        [
                                                            ...countriesSelectState.data.filter((c) =>
                                                                c.id !== details.data.country.id,
                                                            ),
                                                            details.data.country,
                                                        ] :
                                                        countriesSelectState.data
                                                    ).map((country) => (
                                                        <Select.Option
                                                            key={country.id}
                                                            value={country.id}
                                                        >
                                                            {t(country.name)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ))}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="Marché associé">
                                            {getFieldDecorator('marketId', {
                                                rules: [{
                                                    required: true,
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && details.data ?
                                                    details.data.market.id :
                                                    undefined,
                                            })((
                                                <Select
                                                    allowClear={true}
                                                    filterOption={false}
                                                    loading={marketsState.loading}
                                                    onSearch={onMarketSearch}
                                                    placeholder="Rechercher et choisir un marché"
                                                    showSearch
                                                >
                                                    {(isEditing && details.data ?
                                                        [
                                                            ...marketsState.data.filter((c) =>
                                                                c.id !== details.data.market.id,
                                                            ),
                                                            details.data.market,
                                                        ] :
                                                        marketsState.data
                                                    ).map((sector) => (
                                                        <Select.Option
                                                            key={sector.id}
                                                            value={sector.id}
                                                        >
                                                            {t(sector.name)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ))}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Besoins & solutions associés">
                                    {getFieldDecorator('sectors', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data ?
                                            details.data.sectors.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={sectorsState.loading}
                                            onSearch={onSectorsSeach}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un marché"
                                            showSearch
                                        >
                                            {(isEditing && details.data ?
                                                details.data.sectors.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...sectorsState.data]) :
                                                sectorsState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Role">
                                    {getFieldDecorator('role', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data ?
                                            details.data.role :
                                            Role.Admin,
                                    })((
                                        <Select
                                            filterOption={false}
                                            placeholder="Choisir un role"
                                        >
                                            {isUserAllowed({ user: currentUser, role: 'superAdmin' }) && (
                                                <Select.Option
                                                    key="superAdmin"
                                                    value="superAdmin"
                                                >
                                                    Super Admin
                                                </Select.Option>
                                            )}
                                            <Select.Option
                                                key="admin"
                                                value="admin"
                                            >
                                                Admin
                                            </Select.Option>
                                        </Select>
                                    ))}
                                </Form.Item>
                                {getFieldValue('role') === Role.Admin && (
                                    <Form.Item label="Permissions">
                                        {getFieldDecorator('permissions', {
                                            initialValue: isEditing && details.data ?
                                                details.data.permissions.map((p) => p.id) :
                                                permissionsState.data.map((p) => p.id),
                                        })((
                                            <Checkbox.Group>
                                                <Row>
                                                    {permissionsState.data.map((permission) => (
                                                        <Col key={permission.id} style={{marginBottom: 8}}>
                                                            <Checkbox value={permission.id}>
                                                                {t(permission.name)}
                                                            </Checkbox>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Checkbox.Group>
                                        ))}
                                    </Form.Item>
                                )}
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentUser: getUser(state),
    countriesSelectState: getCountriesSelectState(state),
    creates: getCreateUserState(state),
    details: getDetailsUserState(state),
    marketsState: getMarketsState(state),
    permissionsState: getPermissionsState(state),
    profilesState: getProfilesState(state),
    sectorsState: getSectorsState(state),
    updates: getUpdateUserState(state),
});

const AdminFormDrawer = Form.create<AdminFormDrawerProps>()(AdminDrawer);

export default connect(
    mapStateToProps,
    {
        create: UsersActions.create,
        getDetails: UsersActions.details,
        listMarkets: SectorsActions.listMarkets,
        listPermissions: PermissionsActions.list,
        listProfiles: ProfilesActions.list,
        listSectors: SectorsActions.list,
        listSelectCountries: CountriesActions.listSelect,
        update: UsersActions.update,
    },
)(AdminFormDrawer);
