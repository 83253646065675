import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form, Input, Button, Layout,
} from 'antd';

import '../../assets/styles/Login.less';

import * as Auth from '../../store/actions/auth';
import { getAuthState, AuthState } from '../../store/reducers/auth';

import { IconLogo, IconLogoText, IconArrow } from '../../components/icons';
import { RoutePathName } from '../../routes';
import { MainReducerState } from '../../store/reducers';
import ButtonLink from '../../components/ButtonLink';

interface ForgottenPasswordProps extends RouteComponentProps, FormComponentProps {
    auth: AuthState;
    forgottenPassword: typeof Auth.forgottenPassword;
}

interface ForgottenPasswordState {
    address?: string;
}

class ForgottenPassword extends React.Component<ForgottenPasswordProps, ForgottenPasswordState> {
    public state = {
        address: undefined,
    };

    public onSubmit = (e: React.FormEvent): void => {
        const { form, forgottenPassword } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({address: values.email});

            forgottenPassword(values);
        });
    }

    public renderFrom() {
        const { auth, form } = this.props;
        const { getFieldDecorator } = form;
        let error = auth.forgottenPasswordError ?
            'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur' :
            null;

        if (
            auth.forgottenPasswordError &&
            auth.forgottenPasswordError.status &&
            auth.forgottenPasswordError.status === 404
        ) {
            error = 'Êtes-vous sûr d\'avoir renseigné votre propre adresse email ?';
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                className={`login-form forgotten-password${auth.forgottenPasswordSuccess ? ' forgotten-password-success' : ''}`}
            >
                <Link to={RoutePathName.login}>
                    <IconArrow />
                    <h1>Mot de passe oublié</h1>
                </Link>
                {auth.forgottenPasswordSuccess ? (
                    <>
                        <p>
                            Un nouveau mot de passe vient de vous être envoyé à l'adresse {this.state.address}.
                            <br /><br />
                            Merci de surveiller vos e-mails.
                        </p>
                        <ButtonLink
                            size="large"
                            to={RoutePathName.login}
                            block
                        >
                            Connexion
                        </ButtonLink>
                    </>
                ) : (
                    <>
                        <p>Entrez votre adresse e-mail ci-dessous pour recevoir votre nouveau mot de passe.</p>
                        <Form.Item label="Adresse email professionnelle">
                            {getFieldDecorator('email', {
                                rules: [{
                                    required: true,
                                    type: 'email',
                                    message: 'Veuillez entrer une adresse email valide',
                                }],
                                validateTrigger: 'onBlur',
                            })((
                                <Input
                                    placeholder="Entrez votre adresse email"
                                    type="email"
                                />
                            ))}
                        </Form.Item>
                        {error ? (
                            <div className="login-error has-error">
                                <span className="ant-form-explain">{error}</span>
                            </div>
                        ) : null}
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={auth.loading}
                            block
                            style={{ marginTop: 0 }}
                        >
                            Envoyer
                        </Button>
                    </>
                )}
            </Form>
        );
    }

    public render() {
        return (
            <Layout id="login-layout">
                <Layout.Content>
                    <header>
                        <IconLogo />
                        <IconLogoText />
                    </header>
                    {this.renderFrom()}
                </Layout.Content>
            </Layout>
        );
    }
}

const mapStateToProps = (state: MainReducerState): any => ({
    auth: getAuthState(state),
});

const ForgottenPasswordForm = Form.create()(ForgottenPassword);

export default connect(
    mapStateToProps,
    { forgottenPassword: Auth.forgottenPassword },
)(ForgottenPasswordForm);
