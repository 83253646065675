import React, { FC, ReactNode } from 'react';
import { Tabs, Form } from 'antd';
import { connect } from 'react-redux';
import { MainReducerState } from '../store/reducers';
import { getLanguages } from '../store/reducers/languages';
import { Language } from '../store/api/apiTypes';

interface MultiLangInput {
    children: (lang: string) => ReactNode;
    languages: Language[];
    label?: ReactNode;
    required?: boolean;
}

const MultiLangInput: FC<MultiLangInput> = ({ children, label, languages, required }) => (
    <>
        {label && (
            <div className={`ant-col ant-form-item-label${required ? ' ant-form-item-required' : ''}`}>
                <label>{label}</label>
            </div>
        )}
        <Tabs type="card" className="lang-input-tabs">
            {languages.map((lang) => (
                <Tabs.TabPane
                    tab={lang}
                    key={lang}
                >
                    <Form.Item>
                        {children(lang)}
                    </Form.Item>
                </Tabs.TabPane>
            ))}
        </Tabs>
    </>
);

const mapStateToProps = (state: MainReducerState) => ({
    languages: getLanguages(state),
});

export default connect(
    mapStateToProps,
)(MultiLangInput);
