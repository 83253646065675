import { call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '../actions/digitalBook';
import * as reduxTypes from '../types/digitalBook';
import * as api from '../api/digitalBook';
import { DataAction } from '../actions';

export function* updateSaga(action: DataAction<File>) {
    try {
        const response = yield call(api.update, action.data);
        return yield put(actions.updateSuccess(response));
    } catch (error) {
        return yield put(actions.updateFailed(error));
    }
}

export default function* digitalBookSaga() {
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
