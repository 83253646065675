export const LIST = 'properties/LIST';
export const LIST_SUCCESS = 'properties/LIST_SUCCESS';
export const LIST_FAILED = 'properties/LIST_FAILED';

export const LIST_SELECT = 'properties/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'properties/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAILED = 'properties/LIST_SELECT_FAILED';

export const DETAILS = 'properties/DETAILS';
export const DETAILS_SUCCESS = 'properties/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'properties/DETAILS_FAILED';

export const UPDATE = 'properties/UPDATE';
export const UPDATE_SUCCESS = 'properties/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'properties/UPDATE_FAILED';

export const CHANGE_ORDER = 'properties/CHANGE_ORDER';
export const CHANGE_ORDER_SUCCESS = 'properties/CHANGE_ORDER_SUCCESS';
export const CHANGE_ORDER_FAILED = 'properties/CHANGE_ORDER_FAILED';

export const CREATE = 'properties/CREATE';
export const CREATE_SUCCESS = 'properties/CREATE_SUCCESS';
export const CREATE_FAILED = 'properties/CREATE_FAILED';

export const DELETE = 'properties/DELETE';
export const DELETE_SUCCESS = 'properties/DELETE_SUCCESS';
export const DELETE_FAILED = 'properties/DELETE_FAILED';

export const GROUP_LIST = 'properties/GROUP_LIST';
export const GROUP_LIST_SUCCESS = 'properties/GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILED = 'properties/GROUP_LIST_FAILED';

export const GROUP_LIST_SELECT = 'properties/GROUP_LIST_SELECT';
export const GROUP_LIST_SELECT_SUCCESS = 'properties/GROUP_LIST_SELECT_SUCCESS';
export const GROUP_LIST_SELECT_FAILED = 'properties/GROUP_LIST_SELECT_FAILED';

export const GROUP_DETAILS = 'properties/GROUP_DETAILS';
export const GROUP_DETAILS_SUCCESS = 'properties/GROUP_DETAILS_SUCCESS';
export const GROUP_DETAILS_FAILED = 'properties/GROUP_DETAILS_FAILED';

export const GROUP_UPDATE = 'properties/GROUP_UPDATE';
export const GROUP_UPDATE_SUCCESS = 'properties/GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAILED = 'properties/GROUP_UPDATE_FAILED';

export const GROUP_CREATE = 'properties/GROUP_CREATE';
export const GROUP_CREATE_SUCCESS = 'properties/GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_FAILED = 'properties/GROUP_CREATE_FAILED';

export const GROUP_DELETE = 'properties/GROUP_DELETE';
export const GROUP_DELETE_SUCCESS = 'properties/GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_FAILED = 'properties/GROUP_DELETE_FAILED';
