import axios from 'axios';
import { RequestError, SearchPaginationQuery } from './';

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'x-elis-api-key': '0DK6PJ1ZLyYfYt8LdY96o6anbgBLFuDzSe9hAoyX',
    },
    withCredentials: true,
});

client.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const formattedError: RequestError = {
            status: 0,
            message: '',
        };
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            formattedError.status = error.response.status;
            formattedError.data = error.response.data;
            formattedError.headers = error.response.headers;

            if (error.response.status === 401) {
                const e = document.createEvent('CustomEvent');
                e.initCustomEvent('unauthorized.error', true, false, 401);
                window.dispatchEvent(e);
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }

        throw formattedError;
    },
);

export const urlWithQuery = (url: string, payload: SearchPaginationQuery, queryParams: any = {}): string => {
    const query = {
        ...queryParams,
    };

    if (payload.page !== undefined) {
        query.page = payload.page;
    }

    if (payload.search !== undefined) {
        query.search = payload.search;
    }

    if (payload.limit !== undefined) {
        query.limit = payload.limit;
    }

    if (payload.sort !== undefined) {
        query.sort = payload.sort;
    }

    if (payload.sortOrder !== undefined) {
        query.sortOrder = payload.sortOrder;
    }

    if (payload.full !== undefined) {
        query.full = payload.full;
    }

    if (payload.sectorType !== undefined) {
        query.type = payload.sectorType;
    }

    if (payload.sectorId !== undefined) {
        query.sectorId = payload.sectorId;
    }

    if (payload.role !== undefined) {
        query.role = payload.role;
    }

    let urlQueryParams: URLSearchParams;

    if (Object.values(query).some((q) => Array.isArray(q))) {
        urlQueryParams = new URLSearchParams();
        Object.keys(query).forEach((key) => {
            if (Array.isArray(query[key])) {
                query[key].forEach((val: any) => {
                    urlQueryParams.append(key, val);
                });
            } else {
                urlQueryParams.append(key, query[key]);
            }
        });

        return `${url}?${urlQueryParams.toString()}`;
    }

    urlQueryParams = new URLSearchParams(query);
    return Object.keys(query).length ? `${url}?${urlQueryParams.toString()}` : url;
};

export default client;
