import * as reduxTypes from '../types/associatedProductGroups';
import { ProductGroup } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';

export interface AssociatedProductGroupsState {
    readonly list: RequestState<ProductGroup[]>;
    readonly listSelect: RequestState<ProductGroup[]>;
    readonly details: RequestState<ProductGroup>;
    readonly update: RequestState<Partial<ProductGroup>>;
    readonly create: RequestState<Partial<ProductGroup>>;
    readonly delete: RequestState;
}

const initialState: ProductGroup[] = [];

export default combineReducers<AssociatedProductGroupsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getAssociatedProductGroupsState = (state: MainReducerState) => state.associatedProductGroups.list;
export const getAssociatedProductGroupsSelectState = (state: MainReducerState) =>
    state.associatedProductGroups.listSelect;
export const getAssociatedProductGroupState = (state: MainReducerState) => state.associatedProductGroups.details;
export const getUpdateAssociatedProductGroupState = (state: MainReducerState) => state.associatedProductGroups.update;
export const getCreateAssociatedProductGroupState = (state: MainReducerState) => state.associatedProductGroups.create;
export const getDeleteAssociatedProductGroupState = (state: MainReducerState) => state.associatedProductGroups.delete;
