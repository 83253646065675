import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';

import * as ArgumentsActions from '../../store/actions/arguments';
import * as SectorsActions from '../../store/actions/sectors';
import { getSectorsSelectState, SectorsState } from '../../store/reducers/sectors';
import {
    ArgumentsState, getArgumentDetailsState, getUpdateArgumentState, getCreateArgumentState,
} from '../../store/reducers/arguments';
import { RequestState, MainReducerState } from '../../store/reducers';
import { Sector, SectorListItem } from '../../store/api/apiTypes';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';

interface ArgumentFormDrawerProps extends FormComponentProps {
    create: typeof ArgumentsActions.create;
    creates: RequestState;
    details: ArgumentsState['details'];
    getDetails: typeof ArgumentsActions.details;
    id?: Sector['id'];
    isVisible: boolean;
    listSelectSectors: typeof SectorsActions.listSelect;
    onClose: () => void;
    onUpdateSuccess: () => void;
    sectorsSelectState: SectorsState['listSelect'];
    update: typeof ArgumentsActions.update;
    updates: RequestState;
}

const ArgumentFormDrawer: FC<ArgumentFormDrawerProps> = ({
    create, creates, details, getDetails, form, id, isVisible, listSelectSectors, onClose,
    onUpdateSuccess, sectorsSelectState, update, updates,

}) => {
    const isEditing = id !== undefined;

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }
            listSelectSectors();

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                    icon: val.icon || null,
                });
            } else {
                create({
                    ...val,
                    icon: val.icon || null,
                });
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'une motivation'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom de la motivation" required>
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom de la motivation" />,
                                    )}
                                </MultiLangInput>
                                <Form.Item label="Secteurs associés">
                                    {getFieldDecorator('argumentSectors', {
                                        initialValue: isEditing && details.data && details.data.argumentSectors ?
                                            details.data.argumentSectors.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={sectorsSelectState.loading}
                                            onSearch={onSectorSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs secteurs"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.argumentSectors.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.unshift(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...sectorsSelectState.data]) :
                                                sectorsSelectState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Nombre de valeur du slider" extra="correspond au nombre total de valeurs du slider, standard et renforcé compris">
                                    {getFieldDecorator('maxRating', {
                                        rules: [{
                                            type: 'number',
                                        }],
                                        initialValue: isEditing && details.data ?
                                            details.data.maxRating :
                                            2,
                                    })((
                                        <InputNumber min={1} disabled={isEditing} />
                                    ))}
                                </Form.Item>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreateArgumentState(state),
    details: getArgumentDetailsState(state),
    sectorsSelectState: getSectorsSelectState(state),
    updates: getUpdateArgumentState(state),
});

const ProductFormDrawer = Form.create<ArgumentFormDrawerProps>()(ArgumentFormDrawer);

export default connect(
    mapStateToProps,
    {
        create: ArgumentsActions.create,
        listSelectSectors: SectorsActions.listSelect,
        getDetails: ArgumentsActions.details,
        update: ArgumentsActions.update,
    },
)(ProductFormDrawer);
