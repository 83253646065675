import { Action, ActionCreator } from 'redux';

import { DataAction, IdAction } from '../actions';
import * as reduxTypes from '../types/products';
import { Product } from '../api/apiTypes';
import { SearchPaginationQuery } from '../api';

export const list: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST,
    ...queryParams,
});

export const listSuccess: ActionCreator<DataAction<Product[]>> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const listSelect: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SELECT,
    ...queryParams,
});

export const listSelectSuccess: ActionCreator<DataAction<Product[]>> = (data) => ({
    type: reduxTypes.LIST_SELECT_SUCCESS,
    data,
});

export const listSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_FAILED,
    data,
});

export const details: ActionCreator<IdAction<Product['id']>> = (id: Product['id'], full: boolean) => ({
    type: reduxTypes.DETAILS,
    id,
    full,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});
export const detailsReset: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_RESET,
    data,
});

export const create: ActionCreator<DataAction<Partial<Product>>> = (data) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

export const update: ActionCreator<DataAction<Partial<Product>> & IdAction<Product['id']>> = (
    id: Product['id'],
    data: Partial<Product>,
) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});

export const setEnabled: ActionCreator<DataAction<Partial<Product>> & IdAction<Product['id']>> = (
    id: Product['id'],
    data: Partial<Product>,
) => ({
    type: reduxTypes.SET_ENABLED,
    id,
    data,
});

export const setEnabledSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.SET_ENABLED_SUCCESS,
    data,
});

export const setEnabledFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.SET_ENABLED_FAILED,
    data,
});

export const del: ActionCreator<IdAction<Product>> = (id) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const delFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
});
