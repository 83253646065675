export const LIST = 'associatedProductGroups/LIST';
export const LIST_SUCCESS = 'associatedProductGroups/LIST_SUCCESS';
export const LIST_FAILED = 'associatedProductGroups/LIST_FAILED';

export const LIST_SELECT = 'associatedProductGroups/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'associatedProductGroups/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAILED = 'associatedProductGroups/LIST_SELECT_FAILED';

export const DETAILS = 'associatedProductGroups/DETAILS';
export const DETAILS_SUCCESS = 'associatedProductGroups/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'associatedProductGroups/DETAILS_FAILED';

export const UPDATE = 'associatedProductGroups/UPDATE';
export const UPDATE_SUCCESS = 'associatedProductGroups/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'associatedProductGroups/UPDATE_FAILED';

export const CREATE = 'associatedProductGroups/CREATE';
export const CREATE_SUCCESS = 'associatedProductGroups/CREATE_SUCCESS';
export const CREATE_FAILED = 'associatedProductGroups/CREATE_FAILED';

export const DELETE = 'associatedProductGroups/DELETE';
export const DELETE_SUCCESS = 'associatedProductGroups/DELETE_SUCCESS';
export const DELETE_FAILED = 'associatedProductGroups/DELETE_FAILED';
