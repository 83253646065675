import * as reduxTypes from '../types/chapters';
import { Chapter, ChapterDetails } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';

export interface ChaptersState {
    readonly list: RequestState<Chapter[]>;
    readonly listSelect: RequestState<Chapter[]>;
    readonly details: RequestState<ChapterDetails>;
    readonly update: RequestState<Partial<Chapter>>;
    readonly create: RequestState<Partial<Chapter>>;
    readonly delete: RequestState;
}

const initialState: Chapter[] = [];

export default combineReducers<ChaptersState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getChaptersState = (state: MainReducerState) => state.chapters.list;
export const getChapters = (state: MainReducerState) => state.chapters.list.data;
export const getChaptersSelectState = (state: MainReducerState) => state.chapters.listSelect;
export const getChapterDetailsState = (state: MainReducerState) => state.chapters.details;
export const getUpdateChapterState = (state: MainReducerState) => state.chapters.update;
export const getCreateChapterState = (state: MainReducerState) => state.chapters.create;
export const getDeleteChapterState = (state: MainReducerState) => state.chapters.delete;
