import { hasOwnProp } from './utils';

export enum RoutePathName {
    admins = 'admins',
    arguments = 'arguments',
    associatedProductGroups = 'associatedProductGroups',
    chapter = 'chapter',
    chapters = 'chapters',
    countries = 'countries',
    customers = 'customers',
    dashboard = 'dashboard',
    forgottenPassword = 'forgottenPassword',
    gammesAndServices = 'gammesAndServices',
    login = 'login',
    markets = 'markets',
    naceDigitalBook = 'naceDigitalBook',
    news = 'news',
    notFound = 'notFound',
    products = 'products',
    properties = 'properties',
    sectors = 'sectors',
    solutions = 'solutions',
    subSectors = 'subSectors',
    terms = 'terms',
    themes = 'themes',
    unauthorized = 'unauthorized',
    users = 'users',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.admins]: '/admins',
    [RoutePathName.arguments]: '/arguments',
    [RoutePathName.associatedProductGroups]: '/associated-product-groups',
    [RoutePathName.chapter]: '/chapters/:id',
    [RoutePathName.chapters]: '/chapters',
    [RoutePathName.countries]: '/countries',
    [RoutePathName.customers]: '/customers',
    [RoutePathName.dashboard]: '/',
    [RoutePathName.forgottenPassword]: '/forgotten-password',
    [RoutePathName.gammesAndServices]: '/gammes-services',
    [RoutePathName.login]: '/login',
    [RoutePathName.markets]: '/markets',
    [RoutePathName.naceDigitalBook]: '/nace-digital-book',
    [RoutePathName.news]: '/news',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.products]: '/products',
    [RoutePathName.properties]: '/properties',
    [RoutePathName.sectors]: '/sectors',
    [RoutePathName.solutions]: '/solutions',
    [RoutePathName.subSectors]: '/sub-sectors',
    [RoutePathName.terms]: '/terms',
    [RoutePathName.themes]: '/themes',
    [RoutePathName.unauthorized]: '/403',
    [RoutePathName.users]: '/users',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path
export const getRawRoute = (path: RoutePathName) => {
    if (!hasOwnProp(routes, path)) {
        console.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
) => {
    let route = getRawRoute(path);

    if (params && Object.keys(params).length) {
        Object.keys(params).forEach((routeParam: RouteParams['param']) => {
            const search = new RegExp(`:${routeParam}`);
            if (search.test(route)) {
                route = route.replace(search, `${params[routeParam]}`);
            } else {
                console.warn(`[getRoute] Route param not found in route "${route}", skipping param.`);
            }
        });
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    return route;
};
