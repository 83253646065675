import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { News } from './apiTypes';

export const list = (payload: SearchPaginationQuery) =>
    api.get(urlWithQuery('/api/news', payload));

export const details = (id: News['id']) =>
    api.get<News>(`/api/news/${id}`);

export const create = (body: Partial<News>) =>
    api.post<News>('/api/news', body);

export const update = (id: News['id'], body: Partial<News>) =>
    api.put<News>(`/api/news/${id}`, body);

export const del = (id: News['id']) =>
    api.delete(`/api/news/${id}`);
