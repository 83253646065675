import { call, takeLatest, put } from 'redux-saga/effects';
import * as actions from '../actions/permissions';
import * as reduxTypes from '../types/permissions';
import * as api from '../api/permissions';

export function* listSaga() {
    try {
        const response = yield call(api.list);

        return yield put(actions.listSuccess(response.results));
    } catch (error) {
        return yield put(actions.listFailed(error));
    }
}

export default function* permissionsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
