export const LIST = 'news/LIST';
export const LIST_SUCCESS = 'news/LIST_SUCCESS';
export const LIST_FAILED = 'news/LIST_FAILED';

export const DETAILS = 'news/DETAILS';
export const DETAILS_SUCCESS = 'news/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'news/DETAILS_FAILED';

export const CREATE = 'news/CREATE';
export const CREATE_SUCCESS = 'news/CREATE_SUCCESS';
export const CREATE_FAILED = 'news/CREATE_FAILED';

export const UPDATE = 'news/UPDATE';
export const UPDATE_SUCCESS = 'news/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'news/UPDATE_FAILED';

export const DELETE = 'news/DELETE';
export const DELETE_SUCCESS = 'news/DELETE_SUCCESS';
export const DELETE_FAILED = 'news/DELETE_FAILED';
