import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { InputProps, SearchProps } from 'antd/lib/input';

import { Chapter } from '../../store/api/apiTypes';
import * as ChaptersActions from '../../store/actions/chapters';
import { MainReducerState } from '../../store/reducers';
import { getChaptersState, ChaptersState, getDeleteChapterState } from '../../store/reducers/chapters';

import { constants, t, scrollToElement } from '../../utils';
import ListTitle from '../../components/ListTitle';
import { ChapterFormDrawer } from '.';
import { IconTrash } from '../../components/icons';
import { Link } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { usePrevious } from '../../hooks';

export interface ChaptersProps {
    deletes: ChaptersState['delete'];
    deleteChapter: typeof ChaptersActions.del;
    list: typeof ChaptersActions.list;
    chaptersState: ChaptersState['list'];
}

const rowKey = (item: Chapter) => `${item.id}`;

const Chapters: FC<ChaptersProps> = ({ deleteChapter, deletes, chaptersState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const previousDeletes = usePrevious(deletes);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const searchParam = urlParams.get('search');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;

    const columns: Array<ColumnProps<Chapter>> = [
        {
            key: 'name',
            title: 'Nom',
            render: (record) => (
                <Link to={getRoute(RoutePathName.chapter, { id: record.id })}>
                    {t(record.name)}
                </Link>
            ),
        },
        {
            dataIndex: 'theme',
            title: 'Thème',
            render: (val) => val ? t(val.name) : null,
        },
        {
            dataIndex: 'subChapters',
            title: () => <>N<sup>bre</sup> sous-chapitres</>,
            render: (val) => new Intl.NumberFormat().format(val.length),
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    <Popconfirm
                        cancelText="non"
                        okText="oui"
                        onConfirm={onClickDelete.bind(null, record.id)}
                        title="Voulez-vous vraiment supprimer ce chapitre ?"
                    >
                        <Button
                            loading={deletes.loading}
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        >
                            <IconTrash />
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    useEffect(() => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            page: currentPage,
            search,
            throttling: 300,
            limit: constants.PAGE_SIZE,
        });
    }, [location.pathname, searchParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (previousDeletes && previousDeletes.loading && !deletes.loading) {
            list({
                page: currentPage,
                search,
                throttling: 300,
                limit: constants.PAGE_SIZE,
            });
        }
    }, [list, search, previousDeletes, deletes.loading, currentPage]);

    const setEditMode = (id: Chapter['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onSearchChange: InputProps['onChange'] = (e) => {
        urlParams.set('page', '0');
        urlParams.set('search', e.target.value);
        setSearch(e.target.value);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        urlParams.set('page', '0');
        urlParams.set('search', value);
        setSearch(value);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onTableChange: TableProps<Chapter>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    };

    const onClickDelete = (id: Chapter['id']) => {
        deleteChapter(id);
    };

    return (
        <>
            <div className="list-header">
                <ListTitle
                    total={chaptersState.total}
                >
                    Liste des chapitres de présentations et propositions
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer un chapitre
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<Chapter>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={chaptersState.data}
                loading={chaptersState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: chaptersState.total,
                }}
            />
            <ChapterFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    deletes: getDeleteChapterState(state),
    chaptersState: getChaptersState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteChapter: ChaptersActions.del,
        list: ChaptersActions.list,
    },
)(Chapters);
