import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { ProductGroup } from './apiTypes';

export const list = (payload: SearchPaginationQuery) =>
    api.get<ProductGroup[]>(urlWithQuery('/api/associatedProductGroups', payload));

export const details = (id: ProductGroup['id']) =>
    api.get<ProductGroup>(`/api/associatedProductGroups/${id}`);

export const update = (id: ProductGroup['id'], body: Partial<ProductGroup>) =>
    api.put<ProductGroup>(`/api/associatedProductGroups/${id}`, body);

export const create = (body: Partial<ProductGroup>) =>
    api.post<ProductGroup>('/api/associatedProductGroups', body);

export const del = (id: ProductGroup['id']) =>
    api.delete(`/api/associatedProductGroups/${id}`);
