import { call, takeLatest, put } from 'redux-saga/effects';
import * as actions from '../actions/auth';
import * as reduxTypes from '../types/auth';
import * as api from '../api/auth';

export function* loginSaga(action: any) {
    try {
        const response = yield call(api.login, action.data);

        return yield put(actions.loginSuccess(response));
    } catch (error) {
        return yield put(actions.loginFailed(error));
    }
}

export function* logoutSaga(action: any) {
    try {
        const response = yield call(api.logout);

        return yield put(actions.logoutSuccess(response));
    } catch (error) {
        return yield put(actions.logoutFailed(error));
    }
}

export function* checkLoginStatusSaga(action: any) {
    try {
        const response = yield call(api.getUserDetails);
        return yield put(actions.checkLoginStatusSuccess(response));
    } catch (error) {
        return yield put(actions.checkLoginStatusFailed(error));
    }
}

export function* meSaga() {
    try {
        const response = yield call(api.getUserDetails);
        return yield put(actions.meSuccess(response));
    } catch (error) {
        return yield put(actions.meFailed(error));
    }
}

export function* forgottenPasswordSaga(action: any) {
    try {
        const response = yield call(api.forgottenPassword, { ...action.data });

        return yield put(actions.forgottenPasswordSuccess(response));
    } catch (error) {
        return yield put(actions.forgottenPasswordFailed(error));
    }
}

export default function* authSaga() {
    yield takeLatest(reduxTypes.LOGIN, loginSaga);
    yield takeLatest(reduxTypes.LOGOUT, logoutSaga);
    yield takeLatest(reduxTypes.CHECK_LOGIN_STATUS, checkLoginStatusSaga);
    yield takeLatest(reduxTypes.ME, meSaga);
    yield takeLatest(reduxTypes.FORGOTTEN_PASSWORD, forgottenPasswordSaga);
}
