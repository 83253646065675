import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import * as AssociatedProductGroupsActions from '../../store/actions/associatedProductGroups';
import {
    AssociatedProductGroupsState, getAssociatedProductGroupState,
    getUpdateAssociatedProductGroupState, getCreateAssociatedProductGroupState,
} from '../../store/reducers/associatedProductGroups';
import { RequestState, MainReducerState } from '../../store/reducers';
import { ProductGroup } from '../../store/api/apiTypes';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';

interface AssociatedProductGroupsFormDrawerProps extends FormComponentProps {
    create: typeof AssociatedProductGroupsActions.create;
    creates: RequestState;
    details: AssociatedProductGroupsState['details'];
    getDetails: typeof AssociatedProductGroupsActions.details;
    id?: ProductGroup['id'];
    isVisible: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    update: typeof AssociatedProductGroupsActions.update;
    updates: RequestState;
}

const AssociatedProductGroupsFormDrawer: FC<AssociatedProductGroupsFormDrawerProps> = ({
    create, creates, details, getDetails, form, id, isVisible, onClose, onUpdateSuccess, update,
    updates,

}) => {
    const isEditing = id !== undefined;

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, val);
            } else {
                create(val);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'un groupe de produits associés'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom" required>
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom du groupe" />,
                                    )}
                                </MultiLangInput>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreateAssociatedProductGroupState(state),
    details: getAssociatedProductGroupState(state),
    updates: getUpdateAssociatedProductGroupState(state),
});

const ProductFormDrawer = Form.create<AssociatedProductGroupsFormDrawerProps>()(AssociatedProductGroupsFormDrawer);

export default connect(
    mapStateToProps,
    {
        create: AssociatedProductGroupsActions.create,
        getDetails: AssociatedProductGroupsActions.details,
        update: AssociatedProductGroupsActions.update,
    },
)(ProductFormDrawer);
