import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/profiles';
import { DataAction } from '.';
import { Profile } from '../api/apiTypes';

export const list: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction<Profile>> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});
