import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { Country } from './apiTypes';

export const list = (query: SearchPaginationQuery) =>
    api.get(urlWithQuery('/api/countries', query));

export const details = (id: Country['id']) =>
    api.get<Country>(`/api/countries/${id}`);

export const update = (id: Country['id'], body: Partial<Country>) =>
    api.put<Country>(`/api/countries/${id}`, body);
