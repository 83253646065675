import api from './_client';
import { User } from './apiTypes';

export const details = (lang: string) =>
    api.get<User>(`/terms/use/${lang}`);

export const update = (lang: string, body: string) =>
    api.put<User>(`/api/terms/use/${lang}`, {
        terms: body,
    });
