import { combineReducers } from 'redux';
import * as reduxTypes from '../types/users';
import { User, UserDetails } from '../api/apiTypes';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from './';

export interface UsersState {
    readonly list: RequestState<User[]>;
    readonly listAdmins: RequestState<User[]>;
    readonly details: RequestState<UserDetails>;
    readonly update: RequestState<Partial<User>>;
    readonly create: RequestState<Partial<User>>;
    readonly delete: RequestState;
}

const initialState: User[] = [];

export default combineReducers<UsersState>({
    list: requestReducer<User[]>({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listAdmins: requestReducer<User[]>({
        START: reduxTypes.LIST_ADMINS,
        SUCCESS: reduxTypes.LIST_ADMINS_SUCCESS,
        FAILED: reduxTypes.LIST_ADMINS_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getUsersState = (state: MainReducerState) => state.users.list;
export const getAdminsState = (state: MainReducerState) => state.users.listAdmins;
export const getDetailsUserState = (state: MainReducerState) => state.users.details;
export const getUpdateUserState = (state: MainReducerState) => state.users.update;
export const getCreateUserState = (state: MainReducerState) => state.users.create;
export const getDeleteUserState = (state: MainReducerState) => state.users.delete;
