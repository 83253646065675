import { combineReducers } from 'redux';
import * as reduxTypes from '../types/news';
import { News } from '../api/apiTypes';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from './';

export interface NewsState {
    readonly list: RequestState<News[]>;
    readonly details: RequestState<News>;
    readonly update: RequestState<Partial<News>>;
    readonly create: RequestState<Partial<News>>;
    readonly delete: RequestState;
}

const initialState: News[] = [];

export default combineReducers<NewsState>({
    list: requestReducer<News[]>({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getNewsState = (state: MainReducerState) => state.news.list;
export const getDetailsNewsState = (state: MainReducerState) => state.news.details;
export const getUpdateNewsState = (state: MainReducerState) => state.news.update;
export const getCreateNewsState = (state: MainReducerState) => state.news.create;
export const getDeleteNewsState = (state: MainReducerState) => state.news.delete;
