import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Statistic, Card, Row, Col } from 'antd';

import '../../assets/styles/Dashboard.less';

import { DashboardStats } from '../../store/api/apiTypes';
import * as DashboardActions from '../../store/actions/dashboard';
import { MainReducerState } from '../../store/reducers';
import { getStatsState } from '../../store/reducers/dashboard';

interface DashboardProps {
    getDashboard: typeof DashboardActions.fetch;
    statsState: DashboardStats;
}

const Dashboard: FC<DashboardProps> = ({ getDashboard, statsState }) => {
    useEffect(() => {
        getDashboard();
    }, [getDashboard]);

    return (
        <>
            <div className="dashboard-layout">
                <h1>Tableau de bord</h1>
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                    <Col xs={24} sm={12} md={8}>
                        <Card>
                        <Statistic
                            className="stats-card"
                            groupSeparator=" "
                            title="Nombre d’utilisateurs inscrits"
                            value={statsState.userCount}
                        />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Card>
                        <Statistic
                            className="stats-card"
                            groupSeparator=" "
                            title="Nombre de clients répertoriés"
                            value={statsState.customerCount}
                        />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Card>
                        <Statistic
                            className="stats-card"
                            groupSeparator=" "
                            title="Nombre de produits enregistrés"
                            value={statsState.productCount}
                        />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    statsState: getStatsState(state),
});

export default connect(
    mapStateToProps,
    {
        getDashboard: DashboardActions.fetch,
    },
)(Dashboard);
