interface WithId {
    id: number;
}

export type Language = string;

export interface Name {
    [key: string]: number | string | undefined;
    id?: number;
}

export interface Country extends WithId {
    createAt: string;
    updateAt?: string;
    name: Name;
    sectors?: Sector[];
}

export enum Role {
    SuperAdmin = 'superAdmin',
    Admin = 'admin',
    User = 'user',
}

export interface Permission extends WithId {
    reference: string;
    name: Name;
}

interface UserCommon extends WithId {
    firstName: string;
    lastName: string;
    email: string;
    createAt: string;
    role: Role;
}

export interface User extends UserCommon {
    profileId: Profile['id'];
    countryId: Country['id'];
    marketId: Sector['id'];
    sectors: Array<Sector['id']>;
    permissions: Array<Permission['id']>;
}

export interface UserDetails extends UserCommon {
    profile: Profile;
    profileId: Profile['id'];
    countryId: Country['id'];
    country: Country;
    market: Sector;
    sectors: Sector[];
    permissions: Permission[];
}

export interface ArgumentValue extends WithId {
    name: Name;
    maxRating: number;
    source?: string;
}

export interface ArgumentListItem extends ArgumentValue {
    argumentSectors: Array<Sector['id']>;
}

export interface ArgumentDetails extends ArgumentValue {
    createAt: string;
    source: string;
    texts: Text[];
    updateAt: string | null;
    argumentSectors: Sector[];
}

export interface ProductArgumentValue extends WithId {
    argument: ArgumentValue;
    argumentId?: ArgumentDetails['id'];
    rating: number;
    texts: Array<Partial<Text>>;
}

export interface Profile extends WithId {
    name: Name;
}

export enum PropertyType {
    color = 'color',
    gamme = 'gamme',
    service = 'service',
}

export interface PropertyValue extends WithId {
    name: Name;
    color: string | null;
    property?: Property;
    image?: File;
}

export interface PropertyGroup extends WithId {
    name: Name;
    order: number;
}

export interface PropertyGroupDetails extends PropertyGroup {
    createAt: string;
    source: string;
    updateAt: string | null;
    properties: Array<Partial<PropertyDetails>>;
}

export interface Property extends WithId {
    color: string | null;
    group: PropertyGroup | null;
    name: Name;
    source: string;
    type: PropertyType | null;
    values?: PropertyValue[];
}

export interface PropertyListItem extends WithId {
    type: PropertyType | null;
    group: PropertyGroup | null;
    name: Name;
    propertySectors: Array<Sector['id']>;
    values: PropertyValue[];
    source?: string;
    order: number;
}

export interface PropertyDetails extends WithId {
    type: PropertyType | null;
    group: PropertyGroup | null;
    name: Name;
    createAt: string;
    source: string;
    updateAt: string | null;
    groupId: PropertyGroup['id'] | null;
    propertySectors: Sector[];
    values: PropertyValue[];
}

export enum SectorType {
    Market = 'market',
    Sector = 'sector',
    SubSector = 'subSector',
    Solution = 'solution',
    Gamme = 'gamme',
    Service = 'service',
    SubGamme = 'subGamme',
    SubService = 'subService',
}

export interface Sector extends WithId {
    arguments: ArgumentValue[];
    color: string | null;
    countries: Country[];
    name: Name;
    icon: string | null;
    image: File | null;
    sectorInformation: File | null;
    sectorInformationWithoutLogos: File | null;
    properties: Property[];
    reference: string;
    salesAcademy: File | null;
    skipSubLevel: boolean;
    type: SectorType;
}

export interface SectorListItem extends Sector {
    excludedSectors: Array<Sector['id']>;
    sectorParents: Array<Sector['id']>;
    parents?: Sector[];
}

export interface SectorDetails extends Sector {
    excludedSectors: Sector[];
    sectorParents: Sector[];
}

export enum FileType {
    image = 'image',
    PDF = 'pdf',
    thumbnail = 'thumbnail',
}

export enum FileSubType {
    chapter = 'chapter',
    presentation = 'presentation',
    selection = 'selection',
    logo = 'logo',
    pricing = 'pricing',
    other = 'other',
    sectorInformation = 'sectorInformation',
    sectorInformationWithoutLogos = 'sectorInformationWithoutLogos',
    salesAcademy = 'salesAcademy',
}

export interface File extends WithId {
    [key: string]: any;
    createAt: string;
    expirationDate: string | null;
    filename: string;
    subType: FileSubType | null;
    url: string;
    type: FileType;
    source: string | null;
    productId?: Product['id'];
    productPropertyId?: Property['id'];
    productPropertyValueId?: PropertyValue['id'];
    selectionId?: Selection['id'];
    chapterFileId?: File['id'];
    sectorId?: Sector['id'];
    fileId?: File['id'];
    presentationId?: number; // Presentation['id'] | null;
    thumbnail: File | null;
}

export interface ProductPropertyValue extends PropertyValue {
    property: Property;
}

export interface ProductGroup extends WithId {
    name: Name;
}

export interface Text extends WithId {
    text: Name;
}

export interface CrossSelling extends WithId {
    uid?: string;
    sectorId: Sector['id'];
    sector: Sector;
    products: Product[];
}

export interface CrossSellingPayload {
    id?: number;
    sectorId: Sector['id'];
    products: Array<Product['reference']>;
}

export interface AssociatedProductGroup extends WithId {
    associatedProductGroup: ProductGroup;
    associatedProducts: Array<Partial<Product>>;
}

export interface Product extends WithId {
    arguments: ProductArgumentValue[];
    associatedProductGroups: AssociatedProductGroup[];
    associatedProducts: Array<Partial<Product>>;
    // allAssociatedProductGroups?: Array<{
    //     associatedProductGroupId: AssociatedProductGroup['id'];
    //     associatedProducts: Array<Product['id']>;
    // }>;
    bestSellers: Sector[];
    countries: Country[];
    crossSelling: CrossSelling[];
    images?: File[];
    isEnabled: boolean;
    isNew?: boolean;
    isDeleted: boolean;
    label: Name;
    properties: ProductPropertyValue[];
    reference: string;
    sectorId: Sector['id'];
    sector: Sector & {
        parents: Sector[];
    };
    createAt: string | null;
    updateAt: string | null;
    source: string | null;
    pim: string;
}

export interface News extends WithId {
    createAt: string;
    link: string | null;
    market: Sector;
    text: Name;
    title: Name;
    updateAt: string | null;
}

export interface Assortment extends WithId {
    title: string;
    image: File;
    solutionId: number;
}

export interface Customer extends WithId {
    createAt: string;
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    sectorId: Sector['id'];
    source?: string;
}

export interface Favorites {
    [key: string]: Array<Sector['id']> | Array<Product['id']>;
    favoritesSectors: Array<Sector['id']>;
    favoritesProducts: Array<Product['id']>;
}

export interface Selection extends WithId {
    createAt?: string;
    customer: Customer | null;
    customerId: Customer['id'] | null;
    isValidated: boolean;
    pdf?: File;
    products: Product[];
    updateAt: string;
    userId: User['id'];
}

export interface Nace {
    naf: {
        id: string;
        description: string;
    };
    nace: {
        id: number;
        description: string;
    };
    market: string;
    sector: string;
    subSector: string;
}

export interface DashboardStats {
    userCount: number;
    customerCount: number;
    productCount: number;
}

export interface ChapterFile extends WithId {
    sectorId: number | null;
    sector: Sector | null;
    pdf: File;
    pdfToUpload?: any;
    language: string;
    type: 'presentation' | 'proposition';
    fileId?: number;
}

export interface ChapterBase extends WithId {
    name: Name;
    parent: Chapter;
    theme: Theme;
    chapterFiles: ChapterFile[];
}

export interface Chapter extends ChapterBase {
    subChapters: Array<Pick<Chapter, 'id' | 'name'>>;
}

export type ChapterDetailsSubChapter = Pick<Chapter, 'id' | 'name' | 'chapterFiles'>;

export interface ChapterDetails extends ChapterBase {
    createAt: string | null;
    updateAt: string | null;
    isDeleted: boolean;
    source: string;
    themeId: Theme['id'];
    parentId: Chapter['id'];
    subChapters: ChapterDetailsSubChapter[];
}

export interface Theme extends WithId {
    name: Name;
    chapters: Array<Pick<ChapterDetails, 'id' | 'name' | 'chapterFiles' | 'subChapters'>>;
}

export interface ThemeDetails extends Theme {
    createAt: string | null;
    updateAt: string | null;
    isDeleted: boolean;
    source: string;
}
