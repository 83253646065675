import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button, Avatar, Switch, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import { Product, SectorType, Sector, UserDetails } from '../../store/api/apiTypes';
import * as ProductsActions from '../../store/actions/products';
import { MainReducerState } from '../../store/reducers';
import {
    getProductsState, ProductsState, getSetEnabledProductState, getProductDetailsState,
    getCreateProductState,
 } from '../../store/reducers/products';
import { constants, t, scrollToElement } from '../../utils';
import { InputProps } from 'antd/lib/input';
import { ProductFormDrawer } from '.';
import ListTitle from '../../components/ListTitle';
import { getUser } from '../../store/reducers/auth';
import { usePrevious, useQueryParams } from '../../hooks';

export interface ProductsProps {
    currentUser?: UserDetails;
    productsState: ProductsState['list'];
    detailsReset: typeof ProductsActions.detailsReset;
    list: typeof ProductsActions.list;
    create: typeof ProductsActions.create;
    setEnabled: typeof ProductsActions.setEnabled;
    getDetails: typeof ProductsActions.details;
    createState: ProductsState['create'];
    details: ProductsState['details'];
    setsEnabled: ProductsState['setEnabled'];
}

const rowKey = (item: Product) => `${item.id}`;
let searchTimeout: number;

const Products: FC<ProductsProps> = ({
    currentUser, productsState, list, setEnabled, create, detailsReset, getDetails, details,
    createState, setsEnabled,
}) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const urlParams = useQueryParams();
    const pageParam = urlParams.get('page');
    const searchParam = urlParams.get('search') || '';
    const previous = usePrevious({ details, createState, setsEnabled });
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;

    const onSearchChange: InputProps['onChange'] = (e) => {
        const value = e.target.value;

        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }

        searchTimeout = window.setTimeout(() => {
            const params = new URLSearchParams(location.search);
            params.set('page', '0');
            params.set('search', value);

            history.push({
                pathname: location.pathname,
                search: params.toString(),
            });
        }, 300);
    };

    const onTableChange: TableProps<Product>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
        setSelectedId(undefined);
    };

    const onUpdateSuccess = () => {
        list({
            page: currentPage,
            search: searchParam,
            limit: constants.PAGE_SIZE,
            sectorId: currentUser && currentUser.sectors.map((s) => s.id),
        });
    };

    const setEditMode = (id: Product['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };
    // const setDuplicateMode = (id: Product['id']) => {
    //     detailsReset();
    //     getDetails(id, true);
    //     setIsDuplicating(true);
    // };

    const onChangeEnabled = (product: Product) => {
        setEnabled(product.id, {
            isEnabled: !product.isEnabled,
        });
    };

    const columns: Array<ColumnProps<Product>> = [
        {
            dataIndex: 'images',
            title: 'Image',
            render: (images) => images[0] ? (
                <Avatar
                    shape="square"
                    size={64}
                    src={images[0].url}
                    style={{ margin: '-12px 0 -12px' }}
                />
            ) : null,
        },
        {
            dataIndex: 'reference',
            title: 'Reference',
        },
        {
            dataIndex: 'label',
            title: 'Label',
            render: t,
        },
        {
            dataIndex: 'sector.parents',
            title: 'Besoin & solution',
            render: (val) => {
                const match = val.find((s: Sector) => s.type === SectorType.Solution);

                if (match) {
                    return t(match.name);
                } else {
                    return null;
                }
            },
        },
        {
            dataIndex: 'isNew',
            title: 'Nouveauté',
            render: (val) => val ? 'Oui' : '',
        },
        {
            dataIndex: 'sector.name',
            title: 'Élmt de hiérarchie',
            render: t,
        },
        {
            dataIndex: 'pim',
            title: 'PIM',
        },
        {
            dataIndex: 'associatedProducts',
            title: () => <>N<sup>bre</sup> produits associés</>,
            render: (val) => val.length,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Popconfirm
                        cancelText="non"
                        okText="oui"
                        onConfirm={onChangeEnabled.bind(null, record)}
                        title={`Voulez-vous vraiment ${record.isEnabled ? 'dés' : ''}activer ce produit ?`}
                    >
                        <Switch
                            loading={setsEnabled.loading}
                            checked={record.isEnabled}
                        />
                    </Popconfirm>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    {/* <Button
                        icon="copy"
                        className="list-action-button-edit"
                        onClick={setDuplicateMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    /> */}
                </>
            ),
        },
    ];

    useEffect(() => {
        list({
            page: currentPage,
            search: searchParam,
            limit: constants.PAGE_SIZE,
            sectorId: currentUser && currentUser.sectors.map((s) => s.id),
        });
    }, [list, currentPage, searchParam, currentUser]);

    useEffect(() => {
        if (
            previous && previous.details && previous.details.loading &&
            !details.loading && isDuplicating
        ) {
            details.data.label.fr = details.data.label.fr + '-copie';
            details.data.isEnabled = false;
            create(details.data);
            /*setSelectedId(id);
            setIsFormVisible(true);*/
        }
    }, [previous, details.data, details.loading, isDuplicating, create]);

    useEffect(() => {
        if (
            previous && previous.createState && previous.createState.loading &&
            !createState.loading && isDuplicating
        ) {
            setSelectedId(createState.data.id);
            detailsReset();
            setIsDuplicating(false);
            setIsFormVisible(true);
        }
    }, [previous, createState.data, createState.loading, isDuplicating, detailsReset]);

    useEffect(() => {
        if (
            previous && previous.setsEnabled && previous.setsEnabled.loading &&
            !setsEnabled.loading
        ) {
            list({
                page: currentPage,
                search: searchParam,
                limit: constants.PAGE_SIZE,
                sectorId: currentUser && currentUser.sectors.map((s) => s.id),
            });
        }
    }, [previous, setsEnabled.data, setsEnabled.loading, currentPage, searchParam, currentUser, list]);

    return (
        <>
            <div className="list-header">
                <ListTitle total={productsState.total}>
                    Liste des produits
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer un produit
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                size="small"
                defaultValue={searchParam}
            />
            <Table<Product>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={productsState.data}
                loading={productsState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: productsState.total,
                }}
            />
            <ProductFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentUser: getUser(state),
    productsState: getProductsState(state),
    setsEnabled: getSetEnabledProductState (state),
    details: getProductDetailsState(state),
    createState: getCreateProductState(state),
});

export default connect(

    mapStateToProps,
    {
        list: ProductsActions.list,
        setEnabled: ProductsActions.setEnabled,
        getDetails: ProductsActions.details,
        create: ProductsActions.create,
        detailsReset: ProductsActions.detailsReset,

    },
)(Products);
