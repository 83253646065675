import { combineReducers } from 'redux';

import * as reduxTypes from '../types/countries';
import { Country } from '../api/apiTypes';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';

export interface CountriesState {
    readonly list: RequestState<Country[]>;
    readonly listSelect: RequestState<Country[]>;
    readonly details: RequestState<Country>;
    readonly update: RequestState<Partial<Country>>;
}

const initialState: Country[] = [];

export default combineReducers<CountriesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
});

export const getCountriesState = (state: MainReducerState) => state.countries.list;
export const getCountriesSelectState = (state: MainReducerState) => state.countries.listSelect;
export const getCountryDetailsState = (state: MainReducerState) => state.countries.details;
export const getUpdateCountryState = (state: MainReducerState) => state.countries.update;
