import { Action, ActionCreator } from 'redux';

import { DataAction, IdAction } from '../actions';
import * as reduxTypes from '../types/sectors';
import { Sector, SectorDetails } from '../api/apiTypes';
import { SearchPaginationQuery } from '../api';

export const list: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST,
    ...queryParams,
});

export const listSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const listSelect: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SELECT,
    ...queryParams,
});

export const listSelectSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_SELECT_SUCCESS,
    data,
});

export const listSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_FAILED,
    data,
});

export const details: ActionCreator<IdAction<Sector['id']>> = (id: Sector['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

export const detailsWithFullProperties: ActionCreator<IdAction<Sector['id']>> = (id: Sector['id']) => ({
    type: reduxTypes.DETAILS_FULL_PROPERTIES,
    id,
});

export const detailsWithFullPropertiesSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FULL_PROPERTIES_SUCCESS,
    data,
});

export const detailsWithFullPropertiesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FULL_PROPERTIES_FAILED,
    data,
});
export const detailsWithFullPropertiesReset: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FULL_PROPERTIES_RESET,
    data,
});

export const create: ActionCreator<DataAction<Partial<SectorDetails>>> = (data) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

export const update: ActionCreator<DataAction<Partial<SectorDetails>> & IdAction<Sector['id']>> = (
    id: Sector['id'],
    data: Partial<SectorDetails>,
) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});

export const del: ActionCreator<IdAction<Sector>> = (id) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const delFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
});

export const listAssociatedProductsGroups: ActionCreator<IdAction<Sector['id']>> = (id: Sector['id']) => ({
    type: reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS,
    id,
});

export const listAssociatedProductsGroupsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS_SUCCESS,
    data,
});

export const listAssociatedProductsGroupsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_ASSOCIATED_PRODUCTS_GROUPS_FAILED,
    data,
});

export const listCrossSelling: ActionCreator<IdAction<Sector['id']>> = (id: Sector['id']) => ({
    type: reduxTypes.LIST_CROSS_SELLING,
    id,
});

export const listCrossSellingSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_CROSS_SELLING_SUCCESS,
    data,
});

export const listCrossSellingFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_CROSS_SELLING_FAILED,
    data,
});

export const listMarkets: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_MARKETS,
    ...queryParams,
});

export const listMarketsSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_MARKETS_SUCCESS,
    data,
});

export const listMarketsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_MARKETS_FAILED,
    data,
});

export const listSectors: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SECTORS,
    ...queryParams,
});

export const listSectorsSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_SECTORS_SUCCESS,
    data,
});

export const listSectorsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SECTORS_FAILED,
    data,
});

export const listSubSectors: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SUB_SECTORS,
    ...queryParams,
});

export const listSubSectorsSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_SUB_SECTORS_SUCCESS,
    data,
});

export const listSubSectorsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUB_SECTORS_FAILED,
    data,
});

export const listSolutions: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SOLUTIONS,
    ...queryParams,
});

export const listSolutionsSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_SOLUTIONS_SUCCESS,
    data,
});

export const listSolutionsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SOLUTIONS_FAILED,
    data,
});

export const listGammesAndServices: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_GAMMES_AND_SERVICES,
    ...queryParams,
});

export const listGammesAndServicesSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_GAMMES_AND_SERVICES_SUCCESS,
    data,
});

export const listGammesAndServicesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_GAMMES_AND_SERVICES_FAILED,
    data,
});

export const listGammes: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_GAMMES,
    ...queryParams,
});

export const listGammesSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_GAMMES_SUCCESS,
    data,
});

export const listGammesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_GAMMES_FAILED,
    data,
});

export const listServices: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SERVICES,
    ...queryParams,
});

export const listServicesSuccess: ActionCreator<DataAction<Sector[]>> = (data) => ({
    type: reduxTypes.LIST_SERVICES_SUCCESS,
    data,
});

export const listServicesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SERVICES_FAILED,
    data,
});
