import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { Theme, ThemeDetails } from './apiTypes';

export const list = (queryParams: SearchPaginationQuery) =>
    api.get<Theme[]>(urlWithQuery('/api/themes', queryParams));

export const details = (id: Theme['id']) =>
    api.get<ThemeDetails>(`/api/themes/${id}`);

export const update = (id: Theme['id'], body: Partial<Theme>) =>
    api.put<Theme>(`/api/themes/${id}`, body);

export const create = (body: Partial<Theme>) =>
    api.post<Theme>('/api/themes', body);

export const del = (id: Theme['id']) =>
    api.delete(`/api/themes/${id}`);
export const duplicate = (id: Theme['id']) =>
    api.get(`/api/themes/${id}`);
