import * as reduxTypes from '../types/profiles';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { Profile } from '../api/apiTypes';

export type ProfilesState = RequestState<Profile[]>;

const initialState: Profile[] = [];

export default requestReducer<Profile[]>({
    START: reduxTypes.LIST,
    SUCCESS: reduxTypes.LIST_SUCCESS,
    FAILED: reduxTypes.LIST_FAILED,
}, initialState);

export const getProfilesState = (state: MainReducerState) => state.profiles;

export const getProfileById = (state: MainReducerState, id: Profile['id']) =>
    state.profiles.data ? state.profiles.data.find((profile) => profile.id === id) : undefined;
