import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import { ArgumentListItem } from '../../store/api/apiTypes';
import * as ArgumentsActions from '../../store/actions/arguments';
import { MainReducerState } from '../../store/reducers';
import { getArgumentsState, ArgumentsState } from '../../store/reducers/arguments';
import { constants, t, scrollToElement } from '../../utils';
import { InputProps, SearchProps } from 'antd/lib/input';
import { ArgumentFormDrawer } from '.';
import ListTitle from '../../components/ListTitle';

export interface ArgumentsProps {
    argumentsState: ArgumentsState['list'];
    list: typeof ArgumentsActions.list;
}

const rowKey = (item: ArgumentListItem) => `${item.id}`;

const Arguments: FC<ArgumentsProps> = ({ argumentsState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;

    useEffect(() => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            page: currentPage,
            search,
            throttling: 300,
            limit: constants.PAGE_SIZE,
        });
    }, [location.pathname, search]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: ArgumentListItem['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onTableChange: TableProps<ArgumentListItem>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    };

    const columns: Array<ColumnProps<ArgumentListItem>> = [
        {
            dataIndex: 'name',
            title: 'Nom',
            render: t,
        },
        {
            dataIndex: 'source',
            title: 'Fichier import',
        },
        {
            dataIndex: 'argumentSectors',
            title: () => <>N<sup>bre</sup> secteurs associés</>,
            render: (val) => val.length,
        },
        {
            dataIndex: 'maxRating',
            title: 'Note maximum',
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <ListTitle total={argumentsState.total}>
                    Liste des motivations
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer une motivation
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<ArgumentListItem>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={argumentsState.data}
                loading={argumentsState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: argumentsState.total,
                }}
            />
            <ArgumentFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    argumentsState: getArgumentsState(state),
});

export default connect(
    mapStateToProps,
    {
        list: ArgumentsActions.list,
    },
)(Arguments);
