import { Name, UserDetails } from '../store/api/apiTypes';

export { default as constants } from './constants';

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname || ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const hasOwnProp = (obj: object, value: string | number | [], strict = true) => {
    let result = false;

    if (typeof obj === 'object') {
        if (Array.isArray(value)) {
            result = strict ?
                !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property)) :
                value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
        } else {
            result = Object.prototype.hasOwnProperty.call(obj, value);
        }
    }

    return result;
};

export const t = (name: Name | undefined) => {
    if (!name) {
        return '-';
    }

    const { id, fr, ...langs } = name;

    if (fr) {
        return `${fr}`;
    } else if (Object.keys(langs).length) {
        return `[${Object.keys(langs)[0]}] ${langs[Object.keys(langs)[0]]}`;
    } else {
        return 'non traduit';
    }
};

export const scrollToElement = (element: HTMLElement | null, offset?: number) => {
    if (element) {
        const elementTop = element.getBoundingClientRect().top +
            (window.pageYOffset || document.documentElement.scrollTop);

        window.scrollTo({
            top: elementTop - (offset || 0),
            behavior: 'smooth',
        });
    }
};

type IsUserAllowed = (params: {
    user?: UserDetails,
    permission?: string,
    role?: string,
}) => boolean;

export const isUserAllowed: IsUserAllowed = ({ user, permission, role }) => {
    if (!user || (permission === undefined && role === undefined)) {
        return false;
    }

    let isRoleValid = true;
    let isPermissionValid = true;

    if (user) {
        if (role !== undefined && user.role !== role) {
            isRoleValid = false;
        }

        if (permission !== undefined && !user.permissions.some((p) => p.reference === permission)) {
            isPermissionValid = false;
        }

        return isRoleValid && isPermissionValid;
    }

    return false;
};
