export const LIST = 'themes/LIST';
export const LIST_SUCCESS = 'themes/LIST_SUCCESS';
export const LIST_FAILED = 'themes/LIST_FAILED';

export const LIST_SELECT = 'themes/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'themes/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAILED = 'themes/LIST_SELECT_FAILED';

export const DETAILS = 'themes/DETAILS';
export const DETAILS_SUCCESS = 'themes/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'themes/DETAILS_FAILED';

export const UPDATE = 'themes/UPDATE';
export const UPDATE_SUCCESS = 'themes/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'themes/UPDATE_FAILED';

export const CREATE = 'themes/CREATE';
export const CREATE_SUCCESS = 'themes/CREATE_SUCCESS';
export const CREATE_FAILED = 'themes/CREATE_FAILED';

export const DELETE = 'themes/DELETE';
export const DELETE_SUCCESS = 'themes/DELETE_SUCCESS';
export const DELETE_FAILED = 'themes/DELETE_FAILED';
