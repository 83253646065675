export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'auth/LOGOUT_FAILED';

export const CHECK_LOGIN_STATUS = 'auth/CHECK_LOGIN_STATUS';
export const CHECK_LOGIN_STATUS_SUCCESS = 'auth/CHECK_LOGIN_STATUS_SUCCESS';
export const CHECK_LOGIN_STATUS_FAILED = 'auth/CHECK_LOGIN_STATUS_FAILED';

export const ME = 'auth/ME';
export const ME_SUCCESS = 'auth/ME_SUCCESS';
export const ME_FAILED = 'auth/ME_FAILED';

export const FORGOTTEN_PASSWORD = 'auth/FORGOTTEN_PASSWORD';
export const FORGOTTEN_PASSWORD_SUCCESS = 'auth/FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAILED = 'auth/FORGOTTEN_PASSWORD_FAILED';
