import * as reduxTypes from '../types/properties';
import { Property, PropertyDetails, PropertyListItem, PropertyGroup, PropertyGroupDetails } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';

export interface PropertiesState {
    readonly list: RequestState<PropertyListItem[]>;
    readonly listSelect: RequestState<PropertyListItem[]>;
    readonly details: RequestState<PropertyDetails>;
    readonly update: RequestState<Partial<PropertyDetails>>;
    readonly changeOrder: RequestState;
    readonly create: RequestState<Partial<Property>>;
    readonly delete: RequestState;
    readonly listGroup: RequestState<PropertyGroup[]>;
    readonly listSelectGroup: RequestState<PropertyGroup[]>;
    readonly detailsGroup: RequestState<PropertyGroupDetails>;
    readonly updateGroup: RequestState<Partial<PropertyGroupDetails>>;
    readonly createGroup: RequestState<Partial<PropertyGroup>>;
    readonly deleteGroup: RequestState;
}

const propertyListInitialState: PropertyListItem[] = [];
const propertyGroupListInitialState: PropertyGroup[] = [];

export default combineReducers<PropertiesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, propertyListInitialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, propertyListInitialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    changeOrder: requestReducer({
        START: reduxTypes.CHANGE_ORDER,
        SUCCESS: reduxTypes.CHANGE_ORDER_SUCCESS,
        FAILED: reduxTypes.CHANGE_ORDER_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
    listGroup: requestReducer({
        START: reduxTypes.GROUP_LIST,
        SUCCESS: reduxTypes.GROUP_LIST_SUCCESS,
        FAILED: reduxTypes.GROUP_LIST_FAILED,
    }, propertyGroupListInitialState),
    listSelectGroup: requestReducer({
        START: reduxTypes.GROUP_LIST_SELECT,
        SUCCESS: reduxTypes.GROUP_LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.GROUP_LIST_SELECT_FAILED,
    }, propertyGroupListInitialState),
    detailsGroup: requestReducer({
        START: reduxTypes.GROUP_DETAILS,
        SUCCESS: reduxTypes.GROUP_DETAILS_SUCCESS,
        FAILED: reduxTypes.GROUP_DETAILS_FAILED,
    }),
    updateGroup: requestReducer({
        START: reduxTypes.GROUP_UPDATE,
        SUCCESS: reduxTypes.GROUP_UPDATE_SUCCESS,
        FAILED: reduxTypes.GROUP_UPDATE_FAILED,
    }),
    createGroup: requestReducer({
        START: reduxTypes.GROUP_CREATE,
        SUCCESS: reduxTypes.GROUP_CREATE_SUCCESS,
        FAILED: reduxTypes.GROUP_CREATE_FAILED,
    }),
    deleteGroup: requestReducer({
        START: reduxTypes.GROUP_DELETE,
        SUCCESS: reduxTypes.GROUP_DELETE_SUCCESS,
        FAILED: reduxTypes.GROUP_DELETE_FAILED,
    }),
});

export const getPropertiesState = (state: MainReducerState) => state.properties.list;
export const getPropertiesSelectState = (state: MainReducerState) => state.properties.listSelect;
export const getPropertyDetailsState = (state: MainReducerState) => state.properties.details;
export const getUpdatePropertyState = (state: MainReducerState) => state.properties.update;
export const getChangePropertyOrderState = (state: MainReducerState) => state.properties.changeOrder;
export const getCreatePropertyState = (state: MainReducerState) => state.properties.create;
export const getDeletePropertyState = (state: MainReducerState) => state.properties.delete;

export const getPropertyGroupsState = (state: MainReducerState) => state.properties.listGroup;
export const getPropertyGroupsSelectState = (state: MainReducerState) => state.properties.listSelectGroup;
export const getPropertyGroupDetailsState = (state: MainReducerState) => state.properties.detailsGroup;
export const getUpdatePropertyGroupState = (state: MainReducerState) => state.properties.updateGroup;
export const getCreatePropertyGroupState = (state: MainReducerState) => state.properties.createGroup;
export const getDeletePropertyGroupState = (state: MainReducerState) => state.properties.deleteGroup;
