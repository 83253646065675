import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const usePrevious = <T extends {}>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};
