import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { ArgumentValue, ArgumentDetails, ArgumentListItem } from './apiTypes';

export const list = (payload: SearchPaginationQuery) =>
    api.get<ArgumentListItem[]>(urlWithQuery('/api/products/arguments', payload));

export const details = (id: ArgumentValue['id']) =>
    api.get<ArgumentDetails>(`/api/products/arguments/${id}`);

export const update = (id: ArgumentValue['id'], body: Partial<ArgumentValue>) =>
    api.put<ArgumentValue>(`/api/products/arguments/${id}`, body);

export const create = (body: Partial<ArgumentValue>) =>
    api.post<ArgumentValue>('/api/products/arguments', body);

export const del = (id: ArgumentValue['id']) =>
    api.delete(`/api/products/arguments/${id}`);
