import { combineReducers } from 'redux';

import * as reduxTypes from '../types/terms';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from './';

export interface TermsState {
    readonly details: RequestState<string>;
    readonly update: RequestState<string>;
}

export default combineReducers<TermsState>({
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
});

export const getDetailsTermsState = (state: MainReducerState) => state.terms.details;
export const getUpdateTermsState = (state: MainReducerState) => state.terms.update;
