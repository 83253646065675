import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import * as PropertiesActions from '../../store/actions/properties';
import {
    PropertiesState, getCreatePropertyGroupState, getPropertyGroupDetailsState,
    getUpdatePropertyGroupState,
} from '../../store/reducers/properties';
import {  MainReducerState } from '../../store/reducers';
import { PropertyGroup } from '../../store/api/apiTypes';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import { usePrevious } from '../../hooks';

interface PropertyGroupFormDrawerProps extends FormComponentProps {
    create: typeof PropertiesActions.createGroup;
    creates: PropertiesState['createGroup'];
    details: PropertiesState['detailsGroup'];
    getDetails: typeof PropertiesActions.detailsGroup;
    id?: PropertyGroup['id'];
    isVisible: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    updates: PropertiesState['updateGroup'];
    update: typeof PropertiesActions.updateGroup;
}

const PropertyGroupFormDrawer: FC<PropertyGroupFormDrawerProps> = ({
    create, creates, details, getDetails, form, id, isVisible, onClose, onUpdateSuccess, update, updates,
}) => {
    const previous = usePrevious({ creates, updates });
    const isEditing = id !== undefined;

    useEffect(() => {
        if (
            previous && previous.creates && previous.creates.loading &&
            !creates.loading
        ) {
            if (creates.error) {
                message.error('Une erreur est survenue, veuillez réessayer plus tard ou contacter le support.');
            } else {
                form.resetFields();
                onUpdateSuccess();
                onClose();
            }
        }
    }, [previous, creates.data, creates.loading, creates.error, form, onClose, onUpdateSuccess]);

    useEffect(() => {
        if (
            previous && previous.updates && previous.updates.loading &&
            !updates.loading
        ) {
            if (updates.error) {
                message.error('Une erreur est survenue, veuillez réessayer plus tard ou contacter le support.');
            } else {
                form.resetFields();
                onUpdateSuccess();
                onClose();
            }
        }
    }, [previous, updates.data, updates.loading, updates.error, form, onClose, onUpdateSuccess]);

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, val);
            } else {
                create(val);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        'Création d\'un groupe de propriétés'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom du groupe" required>
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder="Nom du groupe" />,
                                    )}
                                </MultiLangInput>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreatePropertyGroupState(state),
    details: getPropertyGroupDetailsState(state),
    updates: getUpdatePropertyGroupState(state),
});

const ProductFormDrawer = Form.create<PropertyGroupFormDrawerProps>()(PropertyGroupFormDrawer);

export default connect(
    mapStateToProps,
    {
        create: PropertiesActions.createGroup,
        getDetails: PropertiesActions.detailsGroup,
        update: PropertiesActions.updateGroup,
    },
)(ProductFormDrawer);
