import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { Product } from './apiTypes';

export const list = (queryParams: SearchPaginationQuery) =>
    api.get(urlWithQuery('/api/products', {...queryParams, full: true}));

export const details = (id: Product['id'], full: boolean) =>
    api.get<Product>(`/api/products/${id}/${full}`);

export const update = (id: Product['id'], body: Partial<Product>) =>
    api.put<Product>(`/api/products/${id}`, body);

export const setEnabled = (id: Product['id'], body: Partial<Product>) =>
    api.post<Product>(`/api/products/${id}/setEnabled`, body);

export const create = (body: Partial<Product>) =>
    api.post<Product>('/api/products', body);

export const del = (id: Product['id']) =>
    api.delete(`/api/products/${id}`);
