import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input, Button, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { DateTime } from 'luxon';

import { News } from '../../store/api/apiTypes';
import * as NewsActions from '../../store/actions/news';
import { MainReducerState } from '../../store/reducers';
import { getNewsState, NewsState, getDeleteNewsState } from '../../store/reducers/news';
import { constants, t, scrollToElement } from '../../utils';
import { InputProps, SearchProps } from 'antd/lib/input';
import ListTitle from '../../components/ListTitle';
import { NewsFormDrawer } from '.';
import { usePrevious } from '../../hooks';
import { IconTrash } from '../../components/icons';

export interface NewsProps {
    list: typeof NewsActions.list;
    deletes: NewsState['delete'];
    deleteNews: typeof NewsActions.del;
    newsState: NewsState['list'];
}

const rowKey = (item: News) => `${item.id}`;

const NewsPage: FC<NewsProps> = ({ deletes, deleteNews, newsState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const prevDeletes = usePrevious<Partial<NewsProps>>({ deletes });
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;
    const listPayload = {
        page: currentPage,
        search,
        limit: constants.PAGE_SIZE,
        role: 'user',
    };

    useEffect(() => {
        list(listPayload);
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            ...listPayload,
            throttling: 300,
        });
    }, [location.pathname, search]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevDeletes && prevDeletes.deletes && prevDeletes.deletes.loading && !deletes.loading && deletes.success) {
            list(listPayload);
        }
    }, [deletes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onTableChange: TableProps<News>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    const onClickDelete = (id: News['id']) => {
        deleteNews(id);
    };

    const setEditMode = (id: News['id'] | undefined) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        list(listPayload);
    };

    const columns: Array<ColumnProps<News>> = [
        {
            dataIndex: 'title',
            title: 'Titre',
            render: t,
        },
        {
            dataIndex: 'text',
            title: 'Contenu',
            render: t,
            ellipsis: true,
        },
        {
            dataIndex: 'market.name',
            title: 'Marché',
        },
        {
            dataIndex: 'link',
            title: 'Lien',
        },
        {
            dataIndex: 'createAt',
            title: 'Date de création',
            render: (text) => DateTime.fromISO(text).toLocaleString(),
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                        size="small"
                        type="link"
                    />
                    <Popconfirm
                        cancelText="non"
                        okText="oui"
                        onConfirm={onClickDelete.bind(null, record.id)}
                        title="Voulez-vous vraiment supprimer cette news ?"
                    >
                        <Button
                            loading={deletes.loading}
                            size="small"
                            style={{ color: '#e20714' }}
                            type="link"
                        >
                            <IconTrash />
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <ListTitle total={newsState.total}>
                    Liste des news
                </ListTitle>
                <Button
                    onClick={setEditMode.bind(null, undefined)}
                    type="primary"
                >
                    Créer une news
                </Button>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<News>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={newsState.data}
                loading={newsState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: newsState.total,
                }}
            />
            <NewsFormDrawer
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    deletes: getDeleteNewsState(state),
    newsState: getNewsState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteNews: NewsActions.del,
        list: NewsActions.list,
    },
)(NewsPage);
