import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs, Modal, Spin, message } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import * as TermsActions from '../../store/actions/terms';
import { MainReducerState } from '../../store/reducers';
import { getUpdateTermsState, TermsState, getDetailsTermsState } from '../../store/reducers/terms';
import { Language } from '../../store/api/apiTypes';
import { getLanguages } from '../../store/reducers/languages';

import ListTitle from '../../components/ListTitle';
import { usePrevious } from '../../hooks';

export interface TermsProps {
    update: typeof TermsActions.update;
    languages: Language[];
    details: typeof TermsActions.details;
    termsDetailsState: TermsState['details'];
    updateState: TermsState['update'];
}

const TermsPage: FC<TermsProps> = ({ details, languages, termsDetailsState, update, updateState }) => {
    const [currentLang, setCurrentLang] = useState(languages[0]);
    const [initialValue, setInitialValue] = useState('');
    const [value, setValue] = useState('');
    const previous = usePrevious({ termsDetailsState, updateState });
    const onChange = (content: string, delta: any, source: any, editor: any) => {
        setValue(content);
    };
    const onChangeLanguage: TabsProps['onChange'] = (key) => {
        if (initialValue !== value) {
            Modal.confirm({
                title: 'Vous avez des changements en cours pour cette langue',
                content: 'Cliquez sur "sauvegarder et continuer" pour sauvegarder vos modifications, ou sur annuler pour rester sur la langue actuelle',
                onOk() {
                    onSave();
                    setCurrentLang(key);
                },
                okText: 'Sauvegarder et continuer',
            });
        } else {
            setCurrentLang(key);
        }
    };
    const onSave = () => {
        update(currentLang, value);
    };

    useEffect(() => {
        details(currentLang);
    }, [details, currentLang]);

    useEffect(() => {
        if (
            previous && previous.termsDetailsState && previous.termsDetailsState.loading &&
            !termsDetailsState.loading
        ) {
            setValue(termsDetailsState.data);
            setInitialValue(termsDetailsState.data);
        }
    }, [previous, termsDetailsState.data, termsDetailsState.loading]);

    useEffect(() => {
        if (
            previous && previous.updateState && previous.updateState.loading &&
            !updateState.loading && !updateState.error
        ) {
            setInitialValue(value);
            message.success('Sauvegardé');
        }
    }, [previous, updateState.data, updateState.loading, updateState.error, value]);

    return (
        <>
            <div className="list-header">
                <ListTitle>
                    Conditions générales d'utilisation
                </ListTitle>
            </div>
            <Tabs
                type="card"
                className="lang-input-tabs"
                onChange={onChangeLanguage}
                activeKey={currentLang}
            >
                {languages.map((lang) => (
                    <Tabs.TabPane
                        tab={lang}
                        key={lang}
                    >
                        <Spin spinning={termsDetailsState.loading}>
                            <Button
                                onClick={onSave}
                                loading={updateState.loading}
                                type="primary"
                                size="small"
                                style={{ position: 'absolute', right: 4, top: 5 }}
                            >
                                Sauvegarder
                            </Button>
                            <ReactQuill
                                placeholder="Contenu"
                                modules={{
                                    toolbar: [
                                        [
                                            { header: [2, 3, false] },
                                        ],
                                        ['bold', 'italic', 'underline'],
                                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                        ['image', 'link'],
                                    ],
                                }}
                                formats={[
                                    'header',
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet', 'indent',
                                    'link', 'image',
                                ]}
                                theme="snow"
                                value={value || ''}
                                onChange={onChange}
                            />
                        </Spin>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    updateState: getUpdateTermsState(state),
    languages: getLanguages(state),
    termsDetailsState: getDetailsTermsState(state),
});

export default connect(
    mapStateToProps,
    {
        details: TermsActions.details,
        update: TermsActions.update,
    },
)(TermsPage);
