import { fork } from 'redux-saga/effects';
import args from './arguments';
import associatedProductGroups from './associatedProductGroups';
import auth from './auth';
import chapters from './chapters';
import countries from './countries';
import customers from './customers';
import dashboard from './dashboard';
import digitalBook from './digitalBook';
import languages from './languages';
import nace from './nace';
import news from './news';
import permissions from './permissions';
import products from './products';
import profiles from './profiles';
import properties from './properties';
import sectors from './sectors';
import terms from './terms';
import themes from './themes';
import users from './users';

export default function* mainSaga() {
    yield fork(args);
    yield fork(associatedProductGroups);
    yield fork(auth);
    yield fork(chapters);
    yield fork(countries);
    yield fork(customers);
    yield fork(dashboard);
    yield fork(digitalBook);
    yield fork(languages);
    yield fork(nace);
    yield fork(news);
    yield fork(permissions);
    yield fork(products);
    yield fork(profiles);
    yield fork(properties);
    yield fork(sectors);
    yield fork(terms);
    yield fork(themes);
    yield fork(users);
}
