import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import { Spin, Drawer, Form, Input, Button, Select, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import * as SectorsActions from '../../store/actions/sectors';
import * as NewsActions from '../../store/actions/news';
import {
    NewsState, getDetailsNewsState, getUpdateNewsState, getCreateNewsState,
} from '../../store/reducers/news';
import {  MainReducerState } from '../../store/reducers';
import { News } from '../../store/api/apiTypes';
import { getMarketsState, SectorsState } from '../../store/reducers/sectors';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import { SelectProps } from 'antd/lib/select';
import { usePrevious } from '../../hooks';

interface NewsFormDrawerProps extends FormComponentProps {
    creates: NewsState['create'];
    create: typeof NewsActions.create;
    details: NewsState['details'];
    getDetails: typeof NewsActions.details;
    id?: News['id'];
    isVisible: boolean;
    listMarkets: typeof SectorsActions.listMarkets;
    marketsState: SectorsState['listMarkets'];
    onClose: () => void;
    onUpdateSuccess: () => void;
    updates: NewsState['update'];
    update: typeof NewsActions.update;
}

const NewsDrawer: FC<NewsFormDrawerProps> = ({
    create, creates, details, getDetails, form, id, isVisible, listMarkets, marketsState, onClose,
    onUpdateSuccess, update, updates,
}) => {
    const isEditing = id !== undefined;
    const previous = usePrevious({ creates, updates });
    const { getFieldDecorator } = form;
    const onMarketSearch: SelectProps['onSearch'] = (value) => {
        listMarkets({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: 'market',
        });
    };
    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };
    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                    text: {
                        ...details.data.text,
                        ...val.text,
                    },
                    title: {
                        ...details.data.title,
                        ...val.title,
                    },
                });
            } else {
                create(val);
            }
        });
    };

    useEffect(() => {
        if (previous && (
            (previous.creates.loading && !creates.loading) ||
            (previous.updates.loading && !updates.loading)
        )) {
            if (creates.error || updates.error) {
                message.error('Une erreur est survenue, veuillez réessayer plus tard ou contacter le support.');
            } else {
                form.resetFields();
                onUpdateSuccess();
                onClose();
            }
        }
    }, [
        previous, creates.loading, creates.error, updates.loading, updates.error, form, onClose,
        onUpdateSuccess,
    ]);

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }

            if (isEditing) {
                getDetails(id);
            }
            listMarkets({ sectorType: 'market', limit: 20 });
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        'Edition de news' :
                        'Création d\'une news'
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Titre" required>
                                    {(lang) => getFieldDecorator(`title[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.title ?
                                            details.data.title[lang] :
                                            '',
                                    })((
                                        <Input placeholder="Titre de la news" />
                                    ))}
                                </MultiLangInput>
                                {isVisible && (
                                    <MultiLangInput label="Contenu" required>
                                        {(lang) => getFieldDecorator(`text[${lang}]`, {
                                            rules: [{
                                                required: lang === 'fr',
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && details.data && details.data.text ?
                                                details.data.text[lang] :
                                                '',
                                        })((
                                            <ReactQuill
                                                placeholder="Contenu de la news"
                                                modules={{
                                                    toolbar: [
                                                        [
                                                            { header: [2, 3, false] },
                                                        ],
                                                        ['bold', 'italic', 'underline'],
                                                        [
                                                            { list: 'ordered' },
                                                            { list: 'bullet' },
                                                            { indent: '-1' },
                                                            { indent: '+1' },
                                                        ],
                                                        ['image', 'link'],
                                                    ],
                                                }}
                                                formats={[
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image',
                                                ]}
                                                theme="snow"
                                            />
                                        ))}
                                    </MultiLangInput>
                                )}
                                <Form.Item label="Marché associé">
                                    {getFieldDecorator('marketId', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data ?
                                            details.data.market.id :
                                            undefined,
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={marketsState.loading}
                                            onSearch={onMarketSearch}
                                            placeholder="Rechercher et choisir un marché"
                                            showSearch
                                        >
                                            {(isEditing && details.data ?
                                                [
                                                    ...marketsState.data.filter((c) =>
                                                        c.id !== details.data.market.id,
                                                    ),
                                                    details.data.market,
                                                ] :
                                                marketsState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreateNewsState(state),
    details: getDetailsNewsState(state),
    marketsState: getMarketsState(state),
    updates: getUpdateNewsState(state),
});

const NewsFormDrawer = Form.create<NewsFormDrawerProps>()(NewsDrawer);

export default connect(
    mapStateToProps,
    {
        create: NewsActions.create,
        getDetails: NewsActions.details,
        listMarkets: SectorsActions.listMarkets,
        update: NewsActions.update,
    },
)(NewsFormDrawer);
