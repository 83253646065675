import { combineReducers } from 'redux';

import * as reduxTypes from '../types/digitalBook';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from './';

export interface DigitalBookState {
    readonly update: RequestState<string>;
}

export default combineReducers<DigitalBookState>({
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
});

export const getUpdateDigitalBookState = (state: MainReducerState) => state.digitalBook.update;
