import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import * as digitalBookActions from '../../store/actions/digitalBook';
import * as NaceActions from '../../store/actions/nace';
import { MainReducerState } from '../../store/reducers';

import FileUpload from '../../components/FileUpload';
import { Form, Spin, message } from 'antd';
import { getUpdateDigitalBookState, DigitalBookState } from '../../store/reducers/digitalBook';
import { getUpdateNaceState, NaceState } from '../../store/reducers/nace';
import { usePrevious } from '../../hooks';

interface NaceDigitalBookProps {
    updateDigitalBook: typeof digitalBookActions.update;
    updateNace: typeof NaceActions.update;
    updatesDigitalBook: DigitalBookState['update'];
    updatesNace: NaceState['update'];
}

const NaceDigitalBook: FC<NaceDigitalBookProps> = ({
    updateDigitalBook, updateNace, updatesDigitalBook, updatesNace,
}) => {
    const previous = usePrevious({ updatesDigitalBook, updatesNace });
    const onChangeFile = (
        field: 'digitalBook' | 'nace',
        file: File | Blob | Array<File | Blob | undefined> | undefined,
        removed?: boolean,
    ) => {
        if (!removed) {
            switch (field) {
                case 'digitalBook':
                    updateDigitalBook(file);
                    break;

                case 'nace':
                    updateNace(file);
                    break;
            }
        }
    };

    useEffect(() => {
        if (previous && previous.updatesDigitalBook.loading && !updatesDigitalBook.loading) {
            if (updatesDigitalBook.error) {
                message.error('Une erreur est survenue lors de l\'envoi du fichier.');
            } else {
                message.success('Mis à jour avec succès');
            }
        }
    }, [previous, updatesDigitalBook.error, updatesDigitalBook.loading]);

    useEffect(() => {
        if (previous && previous.updatesNace.loading && !updatesNace.loading) {
            if (updatesNace.error) {
                message.error('Une erreur est survenue lors de l\'envoi du fichier.');
            } else {
                message.success('Mis à jour avec succès');
            }
        }
    }, [previous, updatesNace.error, updatesNace.loading]);

    return (
        <div id="nace-digital-book">
            <Form.Item label="Book digital">
                <Spin spinning={updatesDigitalBook.loading}>
                    <FileUpload
                        fileType="application/pdf"
                        fileSize={250000}
                        hint="fichier pdf de 250M maximum"
                        isEditing={false}
                        listType="text"
                        onChange={onChangeFile.bind(null, 'digitalBook')}
                    />
                </Spin>
            </Form.Item>
            <Form.Item label="NACE">
                <Spin spinning={updatesNace.loading}>
                    <FileUpload
                        fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        fileSize={50000}
                        hint="fichier xlsx de 50M maximum (colonnes : NAF Rev2 | NAF description | NACE | NACE description | Market | Macro-Segment | Segment)"
                        isEditing={false}
                        listType="text"
                        onChange={onChangeFile.bind(null, 'nace')}
                    />
                </Spin>
            </Form.Item>
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    updatesDigitalBook: getUpdateDigitalBookState(state),
    updatesNace: getUpdateNaceState(state),
});

export default connect(
    mapStateToProps,
    {
        updateNace: NaceActions.update,
        updateDigitalBook: digitalBookActions.update,
    },
)(NaceDigitalBook);
