import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { Sector, SectorDetails } from './apiTypes';

export const list = (payload: SearchPaginationQuery) =>
    api.get(urlWithQuery('/api/sectors', payload));

export const details = (id: Sector['id']) =>
    api.get<Sector>(`/api/sectors/${id}`);

export const detailsWithFullProperties = (id: Sector['id']) =>
    api.get<Sector>(`/api/sectorsProperties/${id}`);

export const update = (id: Sector['id'], body: Partial<SectorDetails>) =>
    api.put<Sector>(`/api/sectors/${id}`, body);

export const create = (body: Partial<SectorDetails>) =>
    api.post<Sector>('/api/sectors', body);

export const del = (id: Sector['id']) =>
    api.delete(`/api/sectors/${id}`);

export const listAssociatedProductsGroups = (id: Sector['id']) =>
    api.get(`/api/sectors/${id}/associatedProductsGroups`);

export const listCrossSelling = (id: Sector['id']) =>
    api.get(`/api/sectors/${id}/crossSelling`);
