import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';
import { RadioProps } from 'antd/lib/radio';

import * as CountriesActions from '../../store/actions/countries';
import * as SectorsActions from '../../store/actions/sectors';
import {
    SectorsState, getSectorDetailsState, getUpdateSectorState, getCreateSectorState, getSectorsState,
    getSectorsSelectState,
} from '../../store/reducers/sectors';
import { RequestState, MainReducerState } from '../../store/reducers';
import { Sector, SectorListItem, SectorType } from '../../store/api/apiTypes';
import { getCountriesSelectState, CountriesState } from '../../store/reducers/countries';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import FileUpload from '../../components/FileUpload';

interface GammeAndServiceFormDrawerProps extends FormComponentProps {
    countriesSelectState: CountriesState['listSelect'];
    create: typeof SectorsActions.create;
    creates: RequestState;
    details: SectorsState['details'];
    getDetails: typeof SectorsActions.details;
    id?: Sector['id'];
    type: Sector['type'];
    isVisible: boolean;
    listSelectCountries: typeof CountriesActions.listSelect;
    listSelectSectors: typeof SectorsActions.listSelect;
    listAncestorSectors: typeof SectorsActions.list;
    onClose: () => void;
    onUpdateSuccess: () => void;
    sectorsSelectState: SectorsState['listSelect'];
    ancestorSectors: SectorsState['list'];
    update: typeof SectorsActions.update;
    updates: RequestState;
}

const GammeAndServiceForm: FC<GammeAndServiceFormDrawerProps> = ({
    ancestorSectors, countriesSelectState, create, creates, details, getDetails, form, id, isVisible,
    listAncestorSectors, listSelectCountries, listSelectSectors, onClose, onUpdateSuccess,
    sectorsSelectState, update, updates, type: originalType,
}) => {
    const isEditing = id !== undefined;
    const [type, setType] = useState<SectorType>(
        isEditing && details.data ?
        details.data.type :
        originalType,
    );
    const listSelectSectorType = (selectType: SectorType) =>
        selectType === 'gamme' || selectType === 'service' ?
            'solution' :
            (selectType === 'subGamme' ? 'gamme' : 'service');
    const listAncestorsSectorType = (ancestorType: SectorType) =>
        ancestorType === 'gamme' || ancestorType === 'service' ?
            ['market', 'sector', 'subSector', 'solution'] :
            ['market', 'sector', 'subSector', 'solution', ancestorType === 'subGamme' ? 'gamme' : 'service'];

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }
            listAncestorSectors({
                sectorType: listAncestorsSectorType(type),
                limit: 20,
            });
            listSelectSectors({
                sectorType: listSelectSectorType(type),
                limit: 20,
            });
            listSelectCountries({ limit: 20 });

            if (isEditing) {
                getDetails(id);
            } else {
                setType(originalType);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeIdDependency = details.data && details.data.id;
    useEffect(() => {
        if (isEditing && details.data && details.data.id) {
            setType(details.data.type);
        }
    }, [changeIdDependency]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: listSelectSectorType(type),
        });
    };

    const onAncestorSectorSearch: SelectProps['onSearch'] = (value) => {
        listAncestorSectors({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: listAncestorsSectorType(type),
        });
    };

    const onCountriesSearch: SelectProps['onSearch'] = (value) => {
        listSelectCountries({
            search: value,
            throttling: 300,
            limit: 20,
        });
    };

    const onChangeFile = (field: string, file: File | Blob | Array<File | Blob | undefined> | undefined) => {
        form.setFieldsValue({ [field]: file });
    };

    const onChangeType: RadioProps['onChange'] = (e) => {
        setType(e.target.value);
        listAncestorSectors({
            sectorType: listAncestorsSectorType(e.target.value),
            limit: 20,
        });
        listSelectSectors({
            sectorType: listSelectSectorType(e.target.value),
            limit: 20,
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...val,
                });
            } else {
                create({
                    ...val,
                    type,
                });
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={isEditing ?
                        `Edition de ${details.data ? t(details.data.name) : ''}` :
                        `Création d'un${type === 'gamme' || type === 'subGamme' ? 'e' : ''} ${type === 'subGamme' || type === 'subService' ? 'sous-' : ''}${type === 'gamme' || type === 'subGamme' ? 'gamme' : 'service'}`
                    }
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    {(!isEditing || (isEditing && !details.loading && details.data)) && (
                        <Spin spinning={details.loading}>
                            <Form onSubmit={onSubmit} layout="vertical">
                                <MultiLangInput label="Nom">
                                    {(lang) => getFieldDecorator(`name[${lang}]`, {
                                        rules: [{
                                            required: lang === 'fr',
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && details.data && details.data.name ?
                                            details.data.name[lang] :
                                            undefined,
                                    })(
                                        <Input placeholder={`Nom ${type === 'gamme' ? 'de la' : 'du'} ${type === 'subGamme' || type === 'subService' ? 'sous-' : ''}${type === 'gamme' || type === 'subGamme' ? 'gamme' : 'service'}`} />,
                                    )}
                                </MultiLangInput>
                                <Form.Item label="Type">
                                    <Radio.Group
                                        buttonStyle="solid"
                                        defaultValue={isEditing && details.data ? details.data.type : 'gamme'}
                                        onChange={onChangeType}
                                        size="small"
                                        value={type}
                                    >
                                        {originalType === SectorType.Gamme || originalType === SectorType.SubGamme ? (
                                            <>
                                                <Radio.Button value="gamme">Gamme</Radio.Button>
                                                <Radio.Button value="subGamme">Sous-gamme</Radio.Button>
                                            </>
                                        ) : (
                                            <>
                                                <Radio.Button value="service">Service</Radio.Button>
                                                <Radio.Button value="subService">Sous-service</Radio.Button>
                                            </>
                                        )}
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="Parents">
                                    {getFieldDecorator('sectorParents', {
                                        initialValue: isEditing && details.data ?
                                            details.data.sectorParents.map((s: Sector) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={sectorsSelectState.loading}
                                            onSearch={onSectorSearch}
                                            mode="multiple"
                                            placeholder={type === 'gamme' || type === 'service' ?
                                                'Rechercher et choisir un ou plusieurs besoins' :
                                                `Rechercher et choisir un${type === 'gamme' ? 'e' : ''} ou plusieurs ${type === 'gamme' ? 'gammes' : 'services'}`
                                            }
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.sectorParents.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...sectorsSelectState.data]) :
                                                sectorsSelectState.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Exclusions">
                                    {getFieldDecorator('excludedSectors', {
                                        initialValue: isEditing && details.data ?
                                            details.data.excludedSectors.map((s: Sector) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            allowClear={true}
                                            filterOption={false}
                                            loading={ancestorSectors.loading}
                                            onSearch={onAncestorSectorSearch}
                                            mode="multiple"
                                            placeholder={type === 'gamme' || type === 'service' ?
                                                // tslint:disable-next-line: max-line-length
                                                'Rechercher et choisir un ou plusieurs marchés, secteurs, sous-secteurs, besoins & solution' :
                                                `Rechercher et choisir un ou plusieurs marchés, secteurs, sous-secteurs, besoins & solution, ${type === 'gamme' ? 'gamme' : 'service'}`
                                            }
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.excludedSectors.reduce((acc, sector) => {
                                                    if (!acc.find((s) => s.id === sector.id)) {
                                                        acc.push(sector as SectorListItem);
                                                    }
                                                    return acc;
                                                }, [...ancestorSectors.data]) :
                                                ancestorSectors.data
                                            ).map((sector) => (
                                                <Select.Option
                                                    key={sector.id}
                                                    value={sector.id}
                                                >
                                                    {t(sector.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                <Form.Item label="Pays rattachés">
                                    {getFieldDecorator('countries', {
                                        initialValue: isEditing && details.data && details.data.countries ?
                                            details.data.countries.map((s) => s.id) :
                                            [],
                                    })((
                                        <Select
                                            filterOption={false}
                                            loading={countriesSelectState.loading}
                                            onSearch={onCountriesSearch}
                                            mode="multiple"
                                            placeholder="Rechercher et choisir un ou plusieurs pays"
                                            showSearch
                                        >
                                            {(details.data ?
                                                details.data.countries.reduce((acc, country) => {
                                                    if (!acc.find((s) => s.id === country.id)) {
                                                        acc.unshift(country);
                                                    }
                                                    return acc;
                                                }, [...countriesSelectState.data]) :
                                                countriesSelectState.data
                                            ).map((country) => (
                                                <Select.Option
                                                    key={country.id}
                                                    value={country.id}
                                                >
                                                    {t(country.name)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ))}
                                </Form.Item>
                                {(type === 'gamme' || type === 'service') && (
                                    <Form.Item label="Image">
                                        {getFieldDecorator('image', {
                                            initialValue: isEditing && details.data ?
                                                details.data.image :
                                                false,
                                        })((
                                            <FileUpload
                                                fileType="image/jpeg"
                                                fileSize={100}
                                                hint="fichier jpg de 100ko maximum"
                                                initialValue={isEditing && details.data && details.data.image ?
                                                    [{
                                                        uid: '1',
                                                        size: 0,
                                                        name: details.data.image.filename,
                                                        status: 'done',
                                                        type: 'image/jpeg',
                                                        response: details.data.image,
                                                        url: details.data.image.url,
                                                    }] : undefined}
                                                isEditing={isEditing}
                                                onChange={onChangeFile.bind(null, 'image')}
                                                onDeleteSuccess={onUpdateSuccess}
                                                onUploadSuccess={onUpdateSuccess}
                                                uploadPayload={{
                                                    sectorId: details.data ? details.data.id : -1,
                                                }}
                                            />
                                        ))}
                                    </Form.Item>
                                )}
                                <div className="form-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updates.loading || creates.loading}
                                    >
                                        {isEditing ? 'Mettre à jour' : 'Créer'}
                                    </Button>
                                    <Button onClick={onDrawerClose} type="ghost">
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    )}
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    ancestorSectors: getSectorsState(state),
    countriesSelectState: getCountriesSelectState(state),
    creates: getCreateSectorState(state),
    details: getSectorDetailsState(state),
    sectorsSelectState: getSectorsSelectState(state),
    updates: getUpdateSectorState(state),
});

const GammeAndServiceFormDrawer = Form.create<GammeAndServiceFormDrawerProps>()(GammeAndServiceForm);

export default connect(
    mapStateToProps,
    {
        create: SectorsActions.create,
        listSelectCountries: CountriesActions.listSelect,
        listSelectSectors: SectorsActions.listSelect,
        listAncestorSectors: SectorsActions.list,
        getDetails: SectorsActions.details,
        update: SectorsActions.update,
    },
)(GammeAndServiceFormDrawer);
