import { Reducer } from 'redux';

import * as reduxTypes from '../types/auth';
import { UserDetails } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';

export interface AuthState {
    data?: UserDetails;
    error?: any;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
    forgottenPasswordError?: RequestState['error'];
    forgottenPasswordSuccess: boolean;
}

const authInitialState: AuthState = {
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
    forgottenPasswordSuccess: false,
};

const auth: Reducer<AuthState> = (state = authInitialState, action) => {
    switch (action.type) {
        case reduxTypes.LOGIN:
            return {
                ...state,
                error: undefined,
                loading: true,
            };

        case reduxTypes.LOGIN_SUCCESS:
            return {
                data: action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
                loading: false,
                forgottenPasswordSuccess: false,
            };

        case reduxTypes.LOGIN_FAILED:
            return {
                error: action.data,
                hasCheckedLoginStatus: true,
                isConnected: false,
                loading: false,
                forgottenPasswordSuccess: false,
            };

        case reduxTypes.ME:
            return {
                ...state,
                error: undefined,
                loading: true,
            };

        case reduxTypes.ME_SUCCESS:
            return {
                ...state,
                error: undefined,
                data: action.data,
                loading: false,
            };

        case reduxTypes.ME_FAILED:
            return {
                ...state,
                error: action.data,
                loading: false,
            };

        case reduxTypes.CHECK_LOGIN_STATUS:
            return {
                ...state,
                hasCheckedLoginStatus: false,
                isConnected: false,
            };

        case reduxTypes.CHECK_LOGIN_STATUS_SUCCESS:
            return {
                ...state,
                data: action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
            };

        case reduxTypes.CHECK_LOGIN_STATUS_FAILED:
            return {
                ...state,
                hasCheckedLoginStatus: true,
                isConnected: false,
                error: undefined,
            };

        case reduxTypes.LOGOUT:
            return {
                ...state,
                loading: true,
            };

        case reduxTypes.LOGOUT_SUCCESS:
            return {
                hasCheckedLoginStatus: true,
                isConnected: false,
                loading: false,
                forgottenPasswordSuccess: false,
            };

        case reduxTypes.LOGOUT_FAILED:
            return {
                ...state,
                isConnected: false,
                loading: false,
            };

        case reduxTypes.FORGOTTEN_PASSWORD:
            return {
                ...state,
                forgottenPasswordError: undefined,
                forgottenPasswordSuccess: false,
                loading: true,
            };

        case reduxTypes.FORGOTTEN_PASSWORD_SUCCESS:
            return {
                ...state,
                forgottenPasswordSuccess: true,
                loading: false,
            };

        case reduxTypes.FORGOTTEN_PASSWORD_FAILED:
            return {
                ...state,
                forgottenPasswordError: action.data,
                loading: false,
            };

        default:
            return state;
    }
};

export default auth;

export const getAuthState = (state: MainReducerState) => state.auth;

export const getUser = (state: MainReducerState) => state.auth.data;
