import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Select, Icon, Radio, Row, Col, Card } from 'antd';
import { FormComponentProps, FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';
import { RadioChangeEvent } from 'antd/lib/radio';
// tslint:disable-next-line: no-submodule-imports
import cloneDeep from 'lodash/cloneDeep';

import * as ChaptersActions from '../../store/actions/chapters';
import * as SectorsActions from '../../store/actions/sectors';
import * as ThemesActions from '../../store/actions/themes';
import {
    ChaptersState, getChapterDetailsState, getUpdateChapterState, getCreateChapterState, getChaptersSelectState,
} from '../../store/reducers/chapters';
import { RequestState, MainReducerState } from '../../store/reducers';
import { ProductGroup, Sector, SectorListItem, ChapterFile, Chapter, FileSubType } from '../../store/api/apiTypes';
import { getThemesSelectState, ThemesState } from '../../store/reducers/themes';
import { SectorsState, getSectorsSelectState } from '../../store/reducers/sectors';
import { getLanguagesState, LanguagesState } from '../../store/reducers/languages';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import FileUpload from '../../components/FileUpload';

interface ChapterDrawerProps extends FormComponentProps {
    chaptersSelectState: ChaptersState['listSelect'];
    create: typeof ChaptersActions.create;
    creates: RequestState;
    details: ChaptersState['details'];
    getDetails: typeof ChaptersActions.details;
    id?: ProductGroup['id'];
    isVisible: boolean;
    languagesState: LanguagesState;
    listSelectChapters: typeof ChaptersActions.listSelect;
    listSelectSectors: typeof SectorsActions.listSelect;
    listSelectThemes: typeof ThemesActions.listSelect;
    onClose: () => void;
    onUpdateSuccess: () => void;
    parent?: Chapter;
    sectorsSelectState: SectorsState['listSelect'];
    themesSelectState: ThemesState['listSelect'];
    update: typeof ChaptersActions.update;
    updates: RequestState;
}

const ChapterDrawer: FC<ChapterDrawerProps> = ({
    chaptersSelectState, create, creates, details, getDetails, form, id, isVisible, languagesState,
    listSelectChapters, listSelectSectors, listSelectThemes, onClose, onUpdateSuccess, parent,
    sectorsSelectState, themesSelectState, update, updates,
}) => {
    const isEditing = id !== undefined;
    const isSubChapter = parent !== undefined;
    const [chapterFiles, setChapterFiles] = useState<Array<Partial<ChapterFile>>>(
        isEditing && details.data ?
            cloneDeep(details.data.chapterFiles) :
            [],
    );

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            setChapterFiles([]);
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isVisible) {
            const drawerBody = document.querySelector('.ant-drawer-wrapper-body');
            if (drawerBody) {
                drawerBody.scrollTo(0, 0);
            }

            listSelectSectors({ sectorType: ['sector', 'subSector'], limit: 20 });
            listSelectThemes({ limit: 20 });
            listSelectChapters({ limit: 20 });

            if (isEditing) {
                getDetails(id);
            }
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const editPopulateEffectDependency = details.data && details.data.id;
    useEffect(() => {
        if (isEditing && details.data && !details.loading) {
            setChapterFiles(cloneDeep(details.data.chapterFiles));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPopulateEffectDependency, setChapterFiles, details.loading]);

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSubmit: FormProps['onSubmit'] = (e) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            const payload = {
                ...val,
                chapterFiles,
            };

            if (isEditing) {
                update(id, {
                    ...details.data,
                    ...payload,
                });
            } else {
                create({
                    ...payload,
                    ...(parent ? { parentId: parent.id } : {}),
                });
            }
        });
    };

    const onThemeSearch: SelectProps['onSearch'] = (search) => {
        listSelectThemes({ limit: 20, search });
    };

    const onChapterSearch: SelectProps['onSearch'] = (search) => {
        listSelectChapters({ limit: 20, search });
    };

    const onSectorSearch: SelectProps['onSearch'] = (value) => {
        listSelectSectors({
            search: value,
            throttling: 300,
            limit: 20,
            sectorType: ['sector', 'subSector'],
        });
    };
    const onChangeSectorId = (index: number, sectorId: Sector['id']) => {
        const vals = [...chapterFiles];
        vals[index].sectorId = sectorId;
        setChapterFiles(vals);
    };

    const onChangeLanguage = (index: number, language: string) => {
        const vals = [...chapterFiles];
        vals[index].language = language;
        setChapterFiles(vals);
    };

    const onChangeType = (index: number, e: RadioChangeEvent) => {
        const vals = [...chapterFiles];
        vals[index].type = e.target.value;
        setChapterFiles(vals);
    };

    const onChangeFile = (
        index: number,
        chapterFileId: number | undefined,
        file: File | Blob | Array<File | Blob | undefined> | undefined,
    ) => {
        const vals = [...chapterFiles];
        vals[index].pdfToUpload = {
            chapterFileId,
            subType: FileSubType.chapter,
            file,
        };
        setChapterFiles(vals);
    };

    const addValue = () => {
        setChapterFiles([...cloneDeep(chapterFiles), { type: 'presentation' }]);
    };

    const removeValue = (valueIndex: number) => {
        const vals = cloneDeep(chapterFiles);
        vals.splice(valueIndex, 1);

        setChapterFiles(vals);
    };

    const { getFieldDecorator } = form;

    return (
        <Drawer
            title={isEditing ?
                `Edition de ${details.data ? t(details.data.name) : ''}` :
                `Création d'un ${isSubChapter ? 'sous-' : ''}chapitre`
            }
            width={580}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={details.loading}>
                {(!isEditing || (isEditing && !details.loading && details.data)) && (
                    <Form onSubmit={onSubmit} layout="vertical">
                        <MultiLangInput label="Nom" required>
                            {(lang) => getFieldDecorator(`name[${lang}]`, {
                                rules: [{
                                    required: lang === 'fr',
                                    message: 'champ requis',
                                }],
                                initialValue: isEditing && details.data && details.data.name ?
                                    details.data.name[lang] :
                                    undefined,
                            })(
                                <Input placeholder="Nom du chapitre" />,
                            )}
                        </MultiLangInput>
                        {isSubChapter && (
                            <Form.Item label="Chapitre parent">
                                {getFieldDecorator('parentId', {
                                    rules: [{
                                        required: true,
                                        message: 'Champ requis',
                                    }],
                                    initialValue: isEditing && details.data ?
                                        details.data.parentId :
                                        (parent ?
                                            parent.id :
                                            undefined
                                        ),
                                })((
                                    <Select
                                        filterOption={false}
                                        loading={chaptersSelectState.loading}
                                        onSearch={onChapterSearch}
                                        placeholder="Rechercher et choisir un chapitre"
                                        showSearch
                                    >
                                        {(isEditing && details.data && details.data.parent ?
                                            [
                                                ...chaptersSelectState.data.filter((chapter) =>
                                                    chapter.id !== details.data.parent.id,
                                                ),
                                                details.data.parent,
                                            ] :
                                            (parent ?
                                                [
                                                    ...chaptersSelectState.data.filter((chapter) =>
                                                        chapter.id !== parent.id,
                                                    ),
                                                    parent,
                                                ] :
                                                [...chaptersSelectState.data]
                                            )
                                        ).map((chapter) => (
                                            <Select.Option
                                                key={chapter.id}
                                                value={chapter.id}
                                            >
                                                {t(chapter.name)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ))}
                            </Form.Item>
                        )}
                        {!isSubChapter && (
                            <Form.Item label="Thème">
                                {getFieldDecorator('themeId', {
                                    initialValue: isEditing && details.data ?
                                        details.data.themeId :
                                        undefined,
                                })((
                                    <Select
                                        allowClear={true}
                                        filterOption={false}
                                        loading={themesSelectState.loading}
                                        onSearch={onThemeSearch}
                                        placeholder="Rechercher et choisir un thème"
                                        showSearch
                                    >
                                        {(details.data && details.data.theme ?
                                            [
                                                ...themesSelectState.data.filter((theme) =>
                                                    theme.id !== details.data.theme.id,
                                                ),
                                                details.data.theme,
                                            ] :
                                            themesSelectState.data
                                        ).map((theme) => (
                                            <Select.Option
                                                key={theme.id}
                                                value={theme.id}
                                            >
                                                {t(theme.name)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ))}
                            </Form.Item>
                        )}
                        <div className="ant-col ant-form-item-label">
                            <label title="Fichiers PDF">Fichiers PDF</label>
                        </div>
                        {chapterFiles.map((value, index: number) => (
                            <Card
                                actions={[(
                                    <Button
                                        key="remove"
                                        style={{
                                            color: '#e20714',
                                        }}
                                        icon="minus-circle-o"
                                        size="small"
                                        type="link"
                                        onClick={removeValue.bind(null, index)}
                                    >
                                        Supprimer
                                    </Button>
                                )]}
                                className="dynamic-item-wrapper"
                                key={index}
                            >
                                <Row gutter={16}>
                                    <Col xs={10}>
                                        <Form.Item label="Langue" required>
                                            <Select
                                                allowClear={true}
                                                defaultValue={isEditing ?
                                                    value.language :
                                                    undefined
                                                }
                                                value={value.language}
                                                loading={languagesState.loading}
                                                onChange={onChangeLanguage.bind(null, index)}
                                                placeholder="Rechercher et choisir une langue"
                                                showSearch
                                            >
                                                {languagesState.data.map((language) => (
                                                    <Select.Option
                                                        key={language}
                                                        value={language}
                                                    >
                                                        {language}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={14}>
                                        <Form.Item label="Type" required>
                                            <Radio.Group
                                                buttonStyle="solid"
                                                onChange={onChangeType.bind(null, index)}
                                                size="small"
                                                value={value.type || 'presentation'}
                                            >
                                                <Radio.Button value="presentation">Presentation</Radio.Button>
                                                <Radio.Button value="proposition">Proposition</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Secteur rattaché" extra="Si un secteur est choisi, ce fichier sera restreint à celui-ci">
                                    <Select
                                        allowClear={true}
                                        filterOption={false}
                                        defaultValue={value.sectorId !== null ?
                                            value.sectorId :
                                            undefined
                                        }
                                        value={value.sectorId || undefined}
                                        loading={sectorsSelectState.loading}
                                        onSearch={onSectorSearch}
                                        onChange={onChangeSectorId.bind(null, index)}
                                        placeholder="Rechercher et choisir un (sous-)secteur"
                                        showSearch
                                    >
                                        {(value && value.sector ?
                                            [value.sector].reduce((acc, sector) => {
                                                if (!acc.find((s: SectorListItem) => s.id === sector.id)) {
                                                    acc.unshift(sector as SectorListItem);
                                                }
                                                return acc;
                                            }, [...sectorsSelectState.data]) :
                                            sectorsSelectState.data
                                        ).map((sector: SectorListItem) => (
                                            <Select.Option
                                                key={sector.id}
                                                value={sector.id}
                                            >
                                                {t(sector.name)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <div className="ant-col ant-form-item-label">
                                    <label title="Fichier">Fichier</label>
                                </div>
                                <FileUpload
                                    fileType="application/pdf"
                                    hint="fichier pdf"
                                    initialValue={isEditing && value.pdf ?
                                        [{
                                            uid: '1',
                                            size: 0,
                                            name: value.pdf.filename,
                                            status: 'done',
                                            type: 'application/pdf',
                                            response: value.pdf,
                                            thumbUrl: value.pdf.thumbnail ? value.pdf.thumbnail.url : undefined,
                                            url: value.pdf.url,
                                        }] :
                                        undefined
                                    }
                                    isEditing={false}
                                    listType="picture-card"
                                    onChange={onChangeFile.bind(null, index, value.id)}
                                    onDeleteSuccess={onUpdateSuccess}
                                    onUploadSuccess={onUpdateSuccess}
                                />
                            </Card>
                        ))}
                        <Form.Item style={{ marginTop: 24 }}>
                            <Button type="dashed" size="small" onClick={addValue}>
                                <Icon type="plus" /> Ajouter un fichier
                            </Button>
                        </Form.Item>
                        <div className="form-actions">
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={updates.loading || creates.loading}
                            >
                                {isEditing ? 'Mettre à jour' : 'Créer'}
                            </Button>
                            <Button onClick={onDrawerClose} type="ghost">
                                Annuler
                            </Button>
                        </div>
                    </Form>
                )}
            </Spin>
        </Drawer>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: getCreateChapterState(state),
    details: getChapterDetailsState(state),
    languagesState: getLanguagesState(state),
    chaptersSelectState: getChaptersSelectState(state),
    sectorsSelectState: getSectorsSelectState(state),
    themesSelectState: getThemesSelectState(state),
    updates: getUpdateChapterState(state),
});

const ChapterFormDrawer = Form.create<ChapterDrawerProps>()(ChapterDrawer);

export default connect(
    mapStateToProps,
    {
        create: ChaptersActions.create,
        getDetails: ChaptersActions.details,
        listSelectChapters: ChaptersActions.listSelect,
        listSelectSectors: SectorsActions.listSelect,
        listSelectThemes: ThemesActions.listSelect,
        update: ChaptersActions.update,
    },
)(ChapterFormDrawer);
