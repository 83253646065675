import { Action, ActionCreator } from 'redux';

import { DataAction } from '../actions';
import * as reduxTypes from '../types/languages';
import { Language } from '../api/apiTypes';

export const list: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction<Language[]>> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});
