import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// tslint:disable-next-line: no-implicit-dependencies
import { useLocation, useHistory } from 'react-router';
import { Table, Input } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { DateTime } from 'luxon';

import { Customer } from '../../store/api/apiTypes';
import * as CustomersActions from '../../store/actions/customers';
import { MainReducerState } from '../../store/reducers';
import { getCustomersState, CustomersState } from '../../store/reducers/customers';
import { getFullName, constants, t, scrollToElement } from '../../utils';
import { InputProps, SearchProps } from 'antd/lib/input';
import ListTitle from '../../components/ListTitle';

export interface CustomersProps {
    customersState: CustomersState;
    list: typeof CustomersActions.list;
}

const rowKey = (item: Customer) => `${item.id}`;

const columns: Array<ColumnProps<Customer>> = [
    {
        key: 'name',
        title: 'Nom',
        render: (text, record) => getFullName(record.firstName, record.lastName),
    },
    {
        dataIndex: 'email',
        title: 'E-mail',
    },
    {
        dataIndex: 'company',
        title: 'Entreprise',
    },
    {
        dataIndex: 'sector.name',
        title: 'Secteur',
        render: t,
    },
    {
        dataIndex: 'createAt',
        title: 'Date de création',
        render: (text) => DateTime.fromISO(text).toLocaleString(),
    },
];

const Customers: FC<CustomersProps> = ({ customersState, list }) => {
    const history = useHistory();
    const location = useLocation();
    const [search, setSearch] = useState<string>();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;

    useEffect(() => {
        list({
            page: currentPage,
            search,
            limit: constants.PAGE_SIZE,
        });
    }, [list, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        list({
            page: currentPage,
            search,
            throttling: 300,
            limit: constants.PAGE_SIZE,
        });
    }, [location.pathname, search]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onTableChange: TableProps<Customer>['onChange'] = (pagination) => {
        const element = document.getElementById('main-content');

        scrollToElement(element, 100);
        urlParams.set('page', `${(pagination.current || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    return (
        <>
            <div className="list-header">
                <ListTitle total={customersState.total}>
                    Liste des clients
                </ListTitle>
            </div>
            <Input.Search
                className="table-search"
                placeholder="Rechercher"
                onChange={onSearchChange}
                onSearch={onSearch}
                size="small"
            />
            <Table<Customer>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={customersState.data}
                loading={customersState.loading}
                onChange={onTableChange}
                pagination={{
                    current: currentPage + 1,
                    pageSize: constants.PAGE_SIZE,
                    total: customersState.total,
                }}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    customersState: getCustomersState(state),
});

export default connect(
    mapStateToProps,
    {
        list: CustomersActions.list,
    },
)(Customers);
