import * as reduxTypes from '../types/products';
import { Product } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';

export interface ProductsState {
    readonly list: RequestState<Product[]>;
    readonly listSelect: RequestState<Product[]>;
    readonly details: RequestState<Product>;
    readonly update: RequestState<Partial<Product>>;
    readonly create: RequestState<Partial<Product>>;
    readonly delete: RequestState;
    readonly setEnabled: RequestState;
}

const initialState: Product[] = [];

export default combineReducers<ProductsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAILED,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
        RESET: reduxTypes.DETAILS_RESET,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
    setEnabled: requestReducer({
        START: reduxTypes.SET_ENABLED,
        SUCCESS: reduxTypes.SET_ENABLED_SUCCESS,
        FAILED: reduxTypes.SET_ENABLED_FAILED,
    }),
});

export const getProductsState = (state: MainReducerState) => state.products.list;
export const getProducts = (state: MainReducerState) => state.products.list.data;
export const getProductsSelectState = (state: MainReducerState) => state.products.listSelect;
export const getProductDetailsState = (state: MainReducerState) => state.products.details;
export const getUpdateProductState = (state: MainReducerState) => state.products.update;
export const getCreateProductState = (state: MainReducerState) => state.products.create;
export const getDeleteProductState = (state: MainReducerState) => state.products.delete;
export const getSetEnabledProductState = (state: MainReducerState) => state.products.setEnabled;
