import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Descriptions, Table, Card, List, Button, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';

import { MainReducerState } from '../../store/reducers';
import * as ChapterActions from '../../store/actions/chapters';
import { ChaptersState, getChapterDetailsState } from '../../store/reducers/chapters';

import { t } from '../../utils';
import { ChapterDetailsSubChapter, ChapterFile, Chapter } from '../../store/api/apiTypes';
import { Link } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import ListTitle from '../../components/ListTitle';
import { CardProps } from 'antd/lib/card';
import { ListProps } from 'antd/lib/list';
import { ChapterFormDrawer } from '.';
import { IconPlaceholder } from '../../components/icons';

interface MatchParams {
    id: string;
}

interface ChapterDetailsProps extends RouteChildrenProps<MatchParams> {
    getDetails: typeof ChapterActions.details;
    details: ChaptersState['details'];
}

const ChapterDetails: FC<ChapterDetailsProps> = ({ match, details, getDetails }) => {
    const [idToEdit, setIdToEdit] = useState<Chapter['id'] | undefined>();
    const [parentToCreate, setParentToCreate] = useState<Chapter | undefined>();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const id = match ? parseInt(match.params.id, 10) : null;
    const isSubChapter = details.data && details.data.parent !== null;
    const rowKey = (item: ChapterDetailsSubChapter) => `${item.id}`;
    const columns: Array<ColumnProps<ChapterDetailsSubChapter>> = [
        {
            key: 'name',
            title: 'Nom',
            render: (record) => (
                <Link to={getRoute(RoutePathName.chapter, { id: record.id })}>
                    {t(record.name)}
                </Link>
            ),
        },
    ];
    const onClickFile: CardProps['onClick'] = (e) => {
        window.open(e.currentTarget.dataset.url);
    };

    const onClickEdit = () => {
        setIdToEdit(details.data.id);
        setParentToCreate(details.data.parent ? details.data.parent : undefined);
        setIsFormVisible(true);
    };

    const onClickCreateSubChapter = () => {
        setIdToEdit(undefined);
        setParentToCreate(details.data);
        setIsFormVisible(true);
    };

    const renderFile: ListProps<ChapterFile>['renderItem'] = (chapterFile) => (
        <List.Item>
            <Card
                key={chapterFile.id}
                cover={chapterFile.pdf.thumbnail ?
                    <img alt="chapitre" src={chapterFile.pdf.thumbnail.url} /> :
                    <IconPlaceholder />
                }
                data-url={chapterFile.pdf.url}
                onClick={onClickFile}
                hoverable
            >
                <Card.Meta
                    description={(
                        <>
                            <Tag>{chapterFile.language}</Tag>
                            <Tag>{chapterFile.type}</Tag>
                            {chapterFile.sector && (
                                <Tag>{t(chapterFile.sector.name)}</Tag>
                            )}
                        </>
                    )}
                />
            </Card>
        </List.Item>
    );
    const onUpdateSuccess = () => {
        getDetails(id);
    };
    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    useEffect(() => {
        getDetails(id);
        window.scroll({ top: 0, behavior: 'smooth' });
    }, [getDetails, id]);

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                {details.data && (
                    <>
                        <Card bordered={false}>
                            <div className="list-header">
                                <ListTitle>
                                    {`Détails du ${isSubChapter ? 'sous-' : ''}chapitre`}
                                </ListTitle>
                                <Button
                                    onClick={onClickEdit}
                                    type="primary"
                                >
                                    Modifier
                                </Button>
                            </div>
                            <Descriptions bordered>
                                <Descriptions.Item label="Nom">
                                    {details.data ? t(details.data.name) : null}
                                </Descriptions.Item>
                                {!isSubChapter && (
                                    <Descriptions.Item label="Thème">
                                        {details.data && details.data.theme ? t(details.data.theme.name) : null}
                                    </Descriptions.Item>
                                )}
                                {isSubChapter && (
                                    <Descriptions.Item label="Chapitre parent">
                                        {details.data ? (
                                            <Link to={getRoute(RoutePathName.chapter, { id: details.data.parent.id })}>
                                                {t(details.data.parent.name)}
                                            </Link>
                                        ) : null}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                            <h4 style={{ marginTop: 24 }}>Fichiers</h4>
                            <List<ChapterFile>
                                grid={{ gutter: 16, column: 4 }}
                                dataSource={details.data.chapterFiles.filter((cF) => cF.pdf)}
                                renderItem={renderFile}
                            />
                        </Card>
                    </>
                )}
                {!isSubChapter && details.data && (
                    <div style={{ marginTop: 40 }}>
                        <div className="list-header">
                            <ListTitle
                                total={details.data.subChapters.length}
                            >
                                Liste des sous-chapitres
                            </ListTitle>
                            <Button
                                onClick={onClickCreateSubChapter}
                                type="primary"
                            >
                                Créer un sous-chapitre
                            </Button>
                        </div>
                        <Table<ChapterDetailsSubChapter>
                            rowKey={rowKey}
                            columns={columns}
                            dataSource={details.data.subChapters}
                        />
                    </div>
                )}
            </Spin>
            <ChapterFormDrawer
                id={idToEdit}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
                parent={parentToCreate}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    details: getChapterDetailsState(state),
});

export default connect(
    mapStateToProps,
    {
        getDetails: ChapterActions.details,
        updateChapter: ChapterActions.update,
    },
)(ChapterDetails);
