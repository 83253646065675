import { Action, ActionCreator } from 'redux';

import { DataAction, IdAction } from '../actions';
import * as reduxTypes from '../types/chapters';
import { Chapter, ChapterDetails } from '../api/apiTypes';
import { SearchPaginationQuery } from '../api';

export const list: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST,
    ...queryParams,
});

export const listSuccess: ActionCreator<DataAction<Chapter[]>> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const listSelect: ActionCreator<Action & SearchPaginationQuery> = (queryParams) => ({
    type: reduxTypes.LIST_SELECT,
    ...queryParams,
});

export const listSelectSuccess: ActionCreator<DataAction<Chapter[]>> = (data) => ({
    type: reduxTypes.LIST_SELECT_SUCCESS,
    data,
});

export const listSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_FAILED,
    data,
});

export const details: ActionCreator<IdAction<Chapter['id']>> = (id: Chapter['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction<ChapterDetails[]>> = (data) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

export const create: ActionCreator<DataAction<Partial<Chapter>>> = (data) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

export const update: ActionCreator<DataAction<Partial<Chapter>> & IdAction<Chapter['id']>> = (
    id: Chapter['id'],
    data: Partial<Chapter>,
) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});

export const del: ActionCreator<IdAction<Chapter>> = (id) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const delFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
});
