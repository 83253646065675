import api, { urlWithQuery } from './_client';
import { SearchPaginationQuery } from '.';
import { Chapter, ChapterDetails } from './apiTypes';

export const list = (queryParams: SearchPaginationQuery) =>
    api.get<Chapter[]>(urlWithQuery('/api/chapters', queryParams));

export const details = (id: Chapter['id']) =>
    api.get<ChapterDetails>(`/api/chapters/${id}`);

export const update = (id: Chapter['id'], body: Partial<Chapter>) =>
    api.put<Chapter>(`/api/chapters/${id}`, body);

export const create = (body: Partial<Chapter>) =>
    api.post<Chapter>('/api/chapters', body);

export const del = (id: Chapter['id']) =>
    api.delete(`/api/chapters/${id}`);
