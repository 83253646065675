import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Select, Input, InputNumber, Alert, Tabs, Popover, Skeleton } from 'antd';
import Form from 'antd/lib/form/Form';

import * as ArgumentsActions from '../../store/actions/arguments';
import {
    Text, ProductArgumentValue, PropertyValue, ProductPropertyValue, Property,
} from '../../store/api/apiTypes';
import { ProductsState } from '../../store/reducers/products';
import { SectorsState } from '../../store/reducers/sectors';

import { t } from '../../utils';
import MultiLangInput from '../../components/MultiLangInput';
import { PropertyValuesByPropertyId } from './ProductFormDrawer';

interface FilterFieldsProps {
    details: ProductsState['details'];
    isEditing: boolean;
    listSelectArguments: typeof ArgumentsActions.listSelect;
    onChangeArgumentRating: (index: number, argumentRating: number | undefined) => void;
    onChangeArgumentTextValue: (
        argumentIndex: number,
        index: number,
        lang: string,
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => void;
    propertyValuesByPropertyId: PropertyValuesByPropertyId;
    onChangePropertyValue: (propertyId: Property['id'], values: Array<ProductPropertyValue['id']>) => void;
    removeTextValue: (argumentIndex: number, textIndex: number) => void;
    addTextValue: (argumentIndex: number) => void;
    argumentValues: Array<Partial<ProductArgumentValue>>;
    sector: SectorsState['detailsWithFullProperties'];
}

const FilterFields: FC<FilterFieldsProps> = ({
    isEditing, details, onChangeArgumentRating, onChangeArgumentTextValue, argumentValues,
    listSelectArguments, removeTextValue, addTextValue, sector, propertyValuesByPropertyId, onChangePropertyValue,
}) => {
    useEffect(() => {
        listSelectArguments({ limit: 20 });
    }, [listSelectArguments]);

    if (sector.error) {
        return (
            <p className="login-error has-error">
                <span className="ant-form-explain">
                    {'Une erreur est survenue lors du chargement des filtres, veuillez réessayer plus tard ou contacter le support.'}
                </span>
            </p>
        );
    }

    if (sector.loading) {
        return (
            <Skeleton />
        );
    }

    if (!sector.data) {
        return (
            <Alert
                type="warning"
                style={{ marginBottom: 40 }}
                message={'Veuillez choisir un "élément de hiérarchie" depuis l\'onglet "Informations" pour pouvoir modifier les filtres'}
            />
        );
    }

    return (
        <Tabs size="small" className="form-tabs filter-fields-tabs">
            <Tabs.TabPane
                tab="Propriétés (besoins)"
                key="properties"
            >
                {(details.data ?
                    details.data.properties.reduce((acc, propertyValue) => {
                        const match = acc.find((p) => p.id === propertyValue.property.id);
                        if (!match) {
                            acc.unshift({
                                ...propertyValue.property,
                                values: [{
                                    id: propertyValue.id,
                                    color: propertyValue.color,
                                    name: propertyValue.name,
                                }],
                            });
                        } else if (
                            match.values &&
                            !match.values.find((propVal) => propVal.id === propertyValue.id)
                        ) {
                            match.values.push({
                                id: propertyValue.id,
                                color: propertyValue.color,
                                name: propertyValue.name,
                            });
                        }
                        return acc;
                    }, [...(sector.data ? sector.data.properties : [])]) :
                    sector.data ? sector.data.properties : []
                ).map((property) => ((
                    <Form.Item label={t(property.name)} key={property.id}>
                        <Select
                            allowClear={true}
                            mode="multiple"
                            placeholder="Rechercher et choisir une ou plusieurs propriétés"
                            onChange={onChangePropertyValue.bind(null, property.id)}
                            value={
                                propertyValuesByPropertyId[property.id] ?
                                    propertyValuesByPropertyId[property.id].map((value) => value.id!) :
                                    []
                            }
                            showSearch
                        >
                            {property.values && property.values.map((propertyValue: PropertyValue) => (
                                <Select.Option
                                    key={propertyValue.id}
                                    value={propertyValue.id}
                                >
                                    {t(propertyValue.name)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )))}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab="Motivations"
                key="arguments"
            >
                {argumentValues.map((argumentValue: Partial<ProductArgumentValue>, argumentIndex: number) => (
                    <div className="dynamic-item-wrapper" key={argumentIndex}>
                        <h4>{argumentValue.argument ? t(argumentValue.argument.name) : ''}</h4>
                        <Form.Item label="Note">
                            <InputNumber
                                max={argumentValue.argument ? argumentValue.argument.maxRating : 1}
                                min={1}
                                defaultValue={argumentValue.rating}
                                onChange={onChangeArgumentRating.bind(null, argumentIndex)}
                            />
                        </Form.Item>
                        <div className="ant-col ant-form-item-label">
                            <label title="Arguments">Arguments</label>
                            <Popover
                                placement="left"
                                content={<img src={`${process.env.PUBLIC_URL}/images/arguments.jpg`} alt="Exemple d'arguments produits" />}
                            >
                                <Icon type="question-circle" />
                            </Popover>
                        </div>
                        {argumentValue.texts && argumentValue.texts.map((textValue: Partial<Text>, textIndex: number) => (
                            <div className="dynamic-item-wrapper" key={textIndex}>
                                <MultiLangInput label="Nom" required>
                                    {(lang) => (
                                        <Input.TextArea
                                            defaultValue={
                                                isEditing && textValue && textValue.text && textValue.text[lang] ?
                                                    textValue.text[lang] :
                                                    undefined
                                            }
                                            value={textValue.text && textValue.text[lang] ?
                                                textValue.text[lang] :
                                                undefined
                                            }
                                            onChange={onChangeArgumentTextValue.bind(null, argumentIndex, textIndex, lang)}
                                            placeholder="Texte de l'argument"
                                        />
                                    )}
                                </MultiLangInput>
                                <Button
                                    style={{
                                        color: '#e20714',
                                    }}
                                    icon="minus-circle-o"
                                    type="link"
                                    onClick={removeTextValue.bind(null, argumentIndex, textIndex)}
                                    block
                                >
                                    Supprimer l'argument
                                </Button>
                            </div>
                        ))}
                        <Form.Item style={{ marginTop: 24 }}>
                            <Button type="dashed" size="small" onClick={addTextValue.bind(null, argumentIndex)}>
                                <Icon type="plus" /> Ajouter un argument
                        </Button>
                        </Form.Item>
                    </div>
                ))}
            </Tabs.TabPane>
        </Tabs>
    );
};

export default connect(
    undefined,
    {
        listSelectArguments: ArgumentsActions.listSelect,
    },
)(FilterFields);
